import React, { useState } from "react";
import { useAuth } from "../../context/auth";
import { Error } from "../../Components/AuthForms";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HttpAxios } from "../../Utilities/Http"

var jwt = require("jsonwebtoken");
const GlobalConfig = new window.globalConfig();

function Login(props) {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(
    "Username and password is required!"
  );
  const { setAuthTokens } = useAuth();

  function postLogin(props) {
    if (!userName || !password)
    {
      setErrorMessage("Username and password is required!");
      setIsError(true);
    } else {
      HttpAxios
        .post(GlobalConfig.REACT_APP_API_AUTH_URL + "/login", {
          userName,
          password,
        })
        .then((result) => {
          if (result.status === 200) {
            let avatar = result.data.data.avatar;
            if ( avatar && avatar != process.env.REACT_APP_DEFAULT_COMPANY_PHOTO && avatar != process.env.REACT_APP_DEFAULT_AVATAR_PHOTO)
            {
              avatar = GlobalConfig.REACT_APP_API_URL + avatar;
              result.data.data.avatar = avatar;
            }
            const token = jwt.sign(
              result.data.data,
              process.env.REACT_APP_TOKEN_SECRET
            );
            localStorage.clear();
            console.log("Cleared cache already.");
            localStorage.setItem("isActive", result.data.data.isActive);
            setAuthTokens(token);
            setLoggedIn(true);
          } else {
            setErrorMessage(
              "The username or password provided were incorrect!"
            );
            setIsError(true);
          }
        })
        .catch((e) => {
          setErrorMessage("The username or password provided were incorrect!");
          setIsError(true);
        });
    }
  }

  try {
    const referer = props.location.state.referer || "/";
    if (isLoggedIn) {
      return <Redirect to={referer} />;
    }
  } catch {
    return <Redirect to="/" />;
  }

  return (
    <div
      style={{
        backgroundImage:"url(/assets/images/PF-Fish-Background-login@2x.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Helmet>
        <title>{"Home | " + process.env.REACT_APP_SITE_TITLE}</title>
      </Helmet>
      <section className="body-sign">
        <div className="center-sign">
          <a href="/" className="logo pull-left">
            <img src="/assets/images/logo.png" height="54" alt="Porto Admin" />
          </a>

          <div className="panel panel-sign">
            <div className="panel-title-sign mt-xl text-right">
              <h2 className="title text-uppercase text-bold m-none">
                <i className="fa fa-user mr-xs"></i> Sign In
              </h2>
            </div>
            <div className="panel-body">
              {isError && (
                <Error className="form-group mb-lg">{errorMessage}</Error>
              )}
              <div className="form-group mb-lg">
                <label>Username</label>
                <div className="input-group input-group-icon">
                  <input
                    required
                    name="username"
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                    autoFocus
                    placeholder="E-mail"
                    type="text"
                    maxLength={100}
                    className="form-control input-lg"
                  />
                  <span className="input-group-addon">
                    <span className="icon icon-lg">
                      <i className="fa fa-user"></i>
                    </span>
                  </span>
                </div>
              </div>

              <div className="form-group mb-lg">
                <div className="clearfix">
                  <label className="pull-left">Password</label>
                  <a href="/recover-password" className="pull-right">
                    Lost Password?
                  </a>
                </div>
                <div className="input-group input-group-icon">
                  <input
                    required
                    name="password"
                    type="password"
                    maxLength={100}
                    className="form-control input-lg"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <span className="input-group-addon">
                    <span className="icon icon-lg">
                      <i className="fa fa-lock"></i>
                    </span>
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-8">
                  {/* <div className="checkbox-custom checkbox-default">
                  <input id="RememberMe" name="rememberme" type="checkbox" />
                  <label for="RememberMe">Remember Me</label>
                </div> */}
                </div>
                <div className="col-sm-4 text-right">
                  <button
                    type="button"
                    className="btn btn-primary hidden-xs"
                    onClick={postLogin}
                  >
                    Sign In
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-block btn-lg visible-xs mt-lg"
                    onClick={postLogin}
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          </div>
          <p className="text-center text-muted mt-md mb-md">
            <span style={{ color: "white" }}>
              &copy; ParityFactory All rights reserved |{" "}
              <a
                href="https://parityfactory.com/privacy-policy/"
                style={{ color: "white" }}
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </div>
      </section>
    </div>
  );
}
export default Login;
