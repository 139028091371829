import React, { useEffect } from "react";
import PropTypes from "prop-types";
import StateManager, { default as ReactSelect, components } from "react-select";
import makeAnimated from "react-select/animated";
import styled from 'styled-components';

const SelectExtend = styled(ReactSelect).attrs((props) => ({}))`
    && {
        [class*="MenuList"] {
            ::-webkit-scrollbar {
                width: 0em;
                height: ${props => props?.theme?.scrollHeight ?? 'initial'};
            }
        }
    }
`;

const customStyles = {
    menu: base => ({
        ...base,
        borderRadius: 0,
        marginTop: "1px"
    }),
    menuList: base => ({
        ...base,
        padding: 0,
    }),
    control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#33bbff" : base.borderColor,
        boxShadow: state.isFocused ? "0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 136, 204, 0.3)" : base.boxShadow,
        "&:hover": {
            borderColor: state.isFocused ? "#33bbff" : base.boxShadow,
            boxShadow: state.isFocused ? "0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 136, 204, 0.3)" : base.boxShadow,
        },
    })
};


const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <div class="checkbox-custom checkbox-default">
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null} />
                    <label>{props.label}</label>
                </div>
            </components.Option>
        </div>
    );
};

const allOption = {
    label: "Select all",
    value: "*"
};

const renderValueContainer = (values) => {

    let hasSelectAll = values.some(item => item.value === allOption.value);

    let valContain = values.map(item => {
        if (item.value !== allOption.value)
            return item.label;
    });
    if (hasSelectAll) {
        valContain = valContain.slice(1).join(", ");
    } else {
        valContain = valContain.join(", ");
    }

    return (
        <div key={new Date()}
            style={{
                width: "98%",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                color: "#333333"
            }}
        >
            {valContain}
        </div>);
}

const ValueContainer = ({ children, ...props }) => {
    const currentValues = props.getValue();
    const length = currentValues.length;
    let toBeRendered = children;

    if (currentValues && length > 0) {
        toBeRendered = null;
        toBeRendered = [renderValueContainer(currentValues), children[1]];
    }

    return (
        <components.ValueContainer {...props}>
            {toBeRendered}
        </components.ValueContainer>
    );
};

const animatedComponents = makeAnimated();

const MultiSelectComponent = props => {
    if (props.allowSelectAll) {
        const options = props.options ? [props.allOption, ...props.options] : [];
        return (
            <SelectExtend
                {...props}
                styles={customStyles}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                    Option,
                    //MultiValue,
                    ValueContainer,
                    animatedComponents
                }}
                options={options}
                onChange={(selected, event) => {
                    if (selected !== null && selected.length > 0) {
                        if (selected[selected.length - 1].value === props.allOption.value && event.option.value === props.allOption.value) {
                            return props.onChange(options, event);
                        }
                        let result = [];
                        if (selected.length === props.options.length) {
                            if (selected.includes(props.allOption)) {
                                result = selected.filter(
                                    option => option.value !== props.allOption.value
                                );
                            } else if (event.action === "select-option") {
                                result = options;
                            }
                            return props.onChange(result, event);
                        }
                    }

                    return props.onChange(selected, event);
                }}
            />
        );
    }

    return <SelectExtend styles={customStyles} {...props} />;
};

MultiSelectComponent.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};

MultiSelectComponent.defaultProps = {
    allOption: {
        label: "Select all",
        value: "*"
    }
};

MultiSelectComponent.ConvertToOptions = (options, selector) => {
    if (options && Array.isArray(options) && options.length > 0) {
        if (selector) {
            return options?.map(item => {
                return { value: item[selector.value], label: item[selector.label], raw: item };
            });
        } else {
            return options?.map(item => {
                return { value: item, label: item, raw: item };
            });
        }
    }
}

export default MultiSelectComponent;
