import React from "react";
import ListComponent from "../Base/ListComponentCustomState";
import ExportExcel from "../Utilities/ExportExcel";
import Moment from "moment";
import MultiSelectComponent from "../Base/MultiSelectComponent";
import ImageGalleryComponent from "../Base/ImageGalleryComponent";

import { HttpAxios } from "../../Utilities/Http"
import { Button, Modal } from "react-bootstrap";
import { DateTimeFormat, NumberFormat } from "../../global";
import { getLoggedUser } from "../../context/auth";
const GlobalConfig = new window.globalConfig();

//Region: Handle select filter change
const ReportName = "PSP_SEL_IC4020S_ICCurrentInventoryBalance";
const ListTag = ["Attr1", "Attr2", "Grand"];
const ProductTotalTag = "Product Total";
let loggedUser = null;

class App extends ListComponent {
  constructor(props) {
    super(props);
    loggedUser = getLoggedUser();
    this.state = {
      urlapi: GlobalConfig.REACT_APP_API_REPORT_INVENTORY_BALANCE_URL,
      urlapiFacility: GlobalConfig.REACT_APP_API_REPORT_INVENTORY_BALANCE_URL + "facilities",
      urlapiFileData: GlobalConfig.REACT_APP_API_COMMON_FILE_DATA,
      urlapiAtributeProduct: GlobalConfig.REACT_APP_API_REPORT_INVENTORY_BALANCE_URL + `productattributes/${loggedUser._id}`,
      urlApiCronJobSetting: GlobalConfig.REACT_APP_API_CRON_JOB_SETTING,
      title: "Product",
      pageTitle: "Current Inventory Balance",
      pluraltitle: "Products",
      searchFormBasic: true,
      hidePanelAction: true,
      paginationDisabled: true,
    }
  }

  //Region: Handle select filter change
  //Region: Get data filter
  //Region: Export handler
  //Region: Business

  getOptions(selected) {
    let options = selected?.map(item => {
      if (item.value !== "*")
        return item.value;
    })
    return options?.join();
  }

  doSomthingAfterSelectChange = () => {
    this.getItems();
  };
  //End region: Handle select filter change

  //Region: Get data filter
  doSomthingBeforeGetItems = async () => {
    await this.getFacilityItems();
    await this.getProductAttributeItems();
    await this.getFileDataInfo();
  };

  async getFacilityItems() {
    await HttpAxios.get(this.state.urlapiFacility).then(async ({ data }) => {
      const options = MultiSelectComponent.ConvertToOptions(data.data, { value: "FacilityID", label: "FacilityID" });
      this.setLocalState(state => {
        return {
          optionFacilities: options,
          facilitiesValue: options ? [MultiSelectComponent.defaultProps.allOption, ...options] : null,
          filterParams: options ? { ...state.filterParams, ...{ facilities: this.getOptions(options) } } : null,
        };
      });
    });
  }

  async getProductAttributeItems() {
    await HttpAxios.get(this.state.urlapiAtributeProduct).then(async ({ data }) => {
      if (!data.data) {
        return;
      }
      const productAttr1Options = MultiSelectComponent.ConvertToOptions(data.data.productAttr1, { value: "AttributeID", label: "AttributeDisplayID" });
      const productAttr2Options = MultiSelectComponent.ConvertToOptions(data.data.productAttr2, { value: "AttributeID", label: "AttributeDisplayID" });
      this.setLocalState(state => {
        const filter1 = productAttr1Options ? { productAttribute1: this.getOptions([productAttr1Options[0]]) } : null;
        const filter2 = productAttr2Options ? { productAttribute2: this.getOptions([productAttr2Options[0]]) } : null;
        return {
          filterParams: { ...state.filterParams, ...filter1, ...filter2, companyName: loggedUser.companyName },
          optionAttributeProducts1: productAttr1Options,
          productAttribute1Value: productAttr1Options ? productAttr1Options[0] : null,
          optionAttributeProducts2: productAttr2Options,
          productAttribute2Value: productAttr2Options ? productAttr2Options[0] : null,
        };
      });
    });
  }

  async getFileDataInfo(){
    await HttpAxios.get(this.state.urlapiFileData + "info").then(async ({ data }) => {
      this.setLocalState(state => {
        return {
          fileDataInfo: data.data
        };
      });
    });
  }

  filterInputHandler(e) {
    e.preventDefault();
    const key = e.target.name;
    const value = e.target.value;
    this.setLocalState(state => {
      return {
        filterParams: { ...state.filterParams, [key]: value }
      }
    });
  }

  filterHandler() {
    this.getItems();
  }

  handleShow = (row) => {
    let productID = row.ProductID;
    let lotID = row.LotID;

    HttpAxios.get(this.state.urlapiFileData + `?productID=${productID}&lotID=${lotID}`).then(async ({ data }) => {
      const gallery = data.data.map(item => item.contentBase64);
      this.setLocalState({ gallery: gallery });
      this.setState({show: true})
    });

  };

  handleClose = (e) => {
    this.setState({show: false})
  };

  //End region: Get data filter
  countRecords = () => {
    const items = this.getLocalState().items;
    if(!(items && Array.isArray(items))) return 0;
    const result = items.filter(item => item.Tag === ProductTotalTag);
    return result.length;
  }

  getTotalLabel = (row) => {
    const localState = this.getLocalState();
    let label = `${row.ProductAttribute} Total:`;
    switch (row.Tag) {
      case "Attr1":
        label = `${localState.productAttribute1Value.label} ${label}`
        break;
      case "Attr2":
        label = `${localState.productAttribute2Value.label} ${label}`
        break;
      default:
        break;
    };
    return label;
  }
  renderRowStyles = () => {
    return [
      {
        when: row => row.Tag === "Attr1",
        style: {
          backgroundColor: 'rgba(0,111,171)',
          color: 'white',
        }
      },
      {
        when: row => row.Tag === "Attr2",
        style: {
          backgroundColor: 'rgba(186,199,225)',
          // color: 'white',
        }
      }
      ,
      {
        when: row => row.Tag === "Grand",
        style: {
          backgroundColor: 'rgba(254,219,174)',
          // color: 'white',
        }
      }
    ];
  }

  setColumns = () => {
    return [
      {
        name: (
          <table>
            <tr style={{ width: "100%" }}>
              <td className="pb-md">&nbsp;</td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td className="pl-md pr-md pt-md pb-md text-center">Product ID</td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td className="input-group pb-md">
                <input style={{ zIndex: 0 }} onChange={this.filterInputHandler.bind(this)} defaultValue={this.getLocalState().filterParams ? this.getLocalState().filterParams.productID : null} name="productID" maxLength="50" placeholder="Filter..." type="text" className="form-control" />
                <span class="input-group-btn">
                  <a style={{ minWidth: "0" }} class="btn btn-info" onClick={this.filterHandler.bind(this)}><i class="fa fa-check" aria-hidden="true"></i></a>
                </span>
              </td>
            </tr>
          </table>
        ),
        selector: "ProductID",
        sortable: false,
        left: true,
        grow: 1,
      },
      {
        name: (
          <table>
            <tr style={{ width: "100%" }}>
              <td className="pb-md">&nbsp;</td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td className="pl-md pr-md pt-md pb-md text-center">Product Name</td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td className="input-group pb-md">
                <input style={{ zIndex: 0 }} onChange={this.filterInputHandler.bind(this)} defaultValue={this.getLocalState().filterParams ? this.getLocalState().filterParams.productName : null} name="productName" maxLength="50" placeholder="Filter..." type="text" className="form-control" />
                <span class="input-group-btn">
                  <a style={{ minWidth: "0" }} class="btn btn-info" onClick={this.filterHandler.bind(this)}><i class="fa fa-check" aria-hidden="true"></i></a>
                </span>
              </td>
            </tr>
          </table>
        ),
        selector: "ProductName01",
        sortable: false,
        left: true,
        grow: 1,
        cell: row => {
          if (!this.getLocalState().filterParams.showLots && (ListTag.includes(row.Tag))) {
            return (
              <div className="cell-total font-weight-bold">
                {row.Tag === "Grand" ? `Grand Total:` : this.getTotalLabel(row)}
              </div>
            )
          }
          return row.ProductName01;
        }
      },
      {
        name: (
          <table>
            <tr style={{ width: "100%" }}>
              <td className="pb-md">&nbsp;</td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td className="pl-md pr-md pt-md pb-md text-center">Lot ID</td>
            </tr>
            <tr style={{ width: "100%" }}>
              <td className="input-group pb-md">
                <input style={{ zIndex: 0 }} onChange={this.filterInputHandler.bind(this)} defaultValue={this.getLocalState().filterParams ? this.getLocalState().filterParams.lotID : null} name="lotID" maxLength="50" placeholder="Filter..." type="text" className="form-control" />
                <span class="input-group-btn">
                  <a style={{ minWidth: "0" }} class="btn btn-info" onClick={this.filterHandler.bind(this)}><i class="fa fa-check" aria-hidden="true"></i></a>
                </span>
              </td>
            </tr>
          </table>
        ),
        selector: "LotID",
        sortable: false,
        left: true,
        grow: 1,
        omit: !(this.getLocalState().filterParams ? this.getLocalState().filterParams.showLots : false),
        cell: row => {
          if (ListTag.includes(row.Tag)) {
            return (
              <div className="cell-total font-weight-bold">
                {row.Tag === "Grand" ? `Grand Total:` : this.getTotalLabel(row)}
              </div>
            )
          }
          return row.LotID;
        }
      },
      {
        name: this.renderCustomHeader("On Hand", "OnHand"),
        center: true,
        grow: 2,
        cell: row => {
          const style = { width: "25%", wordWrap: "normal" };
          return (
            <tr style={{ width: "100%" }}>
              <td style={style} className={ListTag.includes(row.Tag) ? "text-right font-weight-bold" : "text-right"}>
                {row.Units ? row.Units.toFixed(2).replace(NumberFormat.NcNNNdNN, '$&,') : 0}&nbsp;
              </td>
              <td style={style} className="text-left">{row.UnitsUoM || row.UnitsUoM}</td>
              <td className="pl-lg"></td>
              <td style={style} className={ListTag.includes(row.Tag) ? "text-right font-weight-bold" : "text-right"}>
                {row.Fill ? row.Fill.toFixed(2).replace(NumberFormat.NcNNNdNN, '$&,') : 0}&nbsp;
              </td>
              <td style={style} className="text-left">{row.FillUoM || row.FillUoM}</td>
            </tr>
          );
        }
      },
      {
        name: this.renderCustomHeader("Available", "Available"),
        center: true,
        grow: 2,
        cell: row => {
          const style = { width: "25%", wordWrap: "normal" };
          return (
            <tr style={{ width: "100%" }}>
              <td style={style} className={ListTag.includes(row.Tag) ? "text-right font-weight-bold" : "text-right"}>
                {row.UnitsAvailable ? row.UnitsAvailable.toFixed(2).replace(NumberFormat.NcNNNdNN, '$&,') : 0}&nbsp;
              </td>
              <td style={style} className="text-left">{row.UnitsUoMAvailable || row.UnitsUoM}</td>
              <td className="pl-lg"></td>
              <td style={style} className={ListTag.includes(row.Tag) ? "text-right font-weight-bold" : "text-right"}>
                {row.FillAvailable ? row.FillAvailable.toFixed(2).replace(NumberFormat.NcNNNdNN, '$&,') : 0}&nbsp;
              </td>
              <td style={style} className="text-left">{row.FillUoMAvailable || row.FillUoM}</td>
            </tr>
          );
        }
      },
      {
        name: "Attachments",
        center: true,
        grow: 0,
        cell: row => {
          const fileDataInfo = this.getLocalState().fileDataInfo;
          const isValid = fileDataInfo && fileDataInfo.some(item => {
            if(row.Tag === "Product Total" && row.ProductID === item.ProductID){
              return true;
            }else if(this.state.showLots && row.LotID === item.LotID){
              return true
            }
            return false;
          });
          if(isValid){
            return (<a onClick={() => this.handleShow(row)} className="btn btn-sm btn-default btn-icon text-primary mr-none ml-none"><span class="fa fa-file-image-o" aria-hidden="true"></span></a>)
          }
          return "";
        }
      },
    ];
  };

  renderCustomHeader(label, type) {
    const style = { width: "25%", wordWrap: "normal" };
    return (
      <table>
        <tr style={{ width: "100%" }}>
          <td className="pl-md pr-md pt-md pb-md text-center" colSpan="5">{label}</td>
        </tr>
        <tr style={{ width: "100%" }}>
          <td style={style} className="pb-md text-right">Units&nbsp;</td>
          <td style={style} className="pb-md">UoM</td>
          <td className="pl-lg"></td>
          <td style={style} className="pb-md text-right">Fill&nbsp;</td>
          <td style={style} className="pb-md">UoM</td>
        </tr>
      </table>
    );
  }

  //Region: Export handler
  getItemsForExport = () => {
    const parsedItems = [];
    if (this.getLocalState().items) {
      if (this.getLocalState().filterParams.showLots) {
        this.getLocalState().items.map((item) => {
          parsedItems.push({
            "Product ID": item.ProductID,
            "Product Name": item.ProductName01,
            "Lot ID": ListTag.includes(item.Tag) ? (item.Tag === "Grand" ? `Grand Total:` : this.getTotalLabel(item)): item.LotID,
            "On Hands/Units": item.Units,
            "UoM": item.UnitsUoM,
            "On Hands/Fill": item.Fill,
            "UoM ": item.FillUoM,
            "Available/Units": item.UnitsAvailable,
            "UoM  ": item.UnitsUoMAvailable,
            "Available/Fill": item.FillAvailable,
            "UoM   ": item.FillUoMAvailable
          });
        });
      } else {
        this.getLocalState().items.map((item) => {
          parsedItems.push({
            "Product ID": item.ProductID,
            "Product Name": ListTag.includes(item.Tag) ? (item.Tag === "Grand" ? `Grand Total:` : this.getTotalLabel(item)) : item.ProductName01,
            "On Hands/Units": item.Units,
            "UoM": item.UnitsUoM,
            "On Hands/Fill": item.Fill,
            "UoM ": item.FillUoM,
            "Available/Units": item.UnitsAvailable,
            "UoM  ": item.UnitsUoMAvailable,
            "Available/Fill": item.FillAvailable,
            "UoM    ": item.FillUoMAvailable
          });
        });
      }

    }
    return parsedItems;
  };

  extendButtons = () => {
    return (
      <ExportExcel
        csvData={this.getItemsForExport()}
        fileName={`Products-${Moment(new Date()).format(DateTimeFormat.format3)}`}
        buttonLabel="Export"
        buttonClassName="btn btn-warning"
      />
    );
  };

  //End Region: Export handler

  //Region: Business

  doSomthingAfterGetItems = async () => {
    await this.getCronJobSetting(ReportName, (data) => {
      this.setLocalState((state) => {
        return { lastUpdateLabel: `Last updated: ${Moment(data.lastCompleted).format(DateTimeFormat.format4)} (updated ${(data && data.typeCronJob) ? ` ${data.typeCronJob.toLowerCase()}` : ""})` }
      });
    }, true)
  }

  //End region: Business

  extendRender = () => {
    const localState = this.getLocalState();
    //const gallery = [url2, url, url, url, url, url, url, url, url, url, url, url, url, url ];
    return (
      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>Attachments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12">
              <ImageGalleryComponent
                gallery={localState.gallery}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={this.handleClose}>
            <span className="fa fa-times-circle fa-lg"></span> Close
            </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderSearchArea() {
    const localState = this.getLocalState();
    return (
      <>
        <div className="form-group">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <label className="col-sm-3 col-md-3 col-lg-3 control-label">Facility</label>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <MultiSelectComponent
                name="facilities"
                options={localState.optionFacilities}
                onChange={this.multiSelectHandleChange.bind(this)}
                allowSelectAll={true}
                isSearchable={false}
                value={localState.facilitiesValue} />
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-6">
            <label className="col-sm-3 col-md-3 col-lg-3 control-label">Show Lots?</label>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <MultiSelectComponent
                name="showLots"
                isSearchable={false}
                options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                onChange={this.multiSelectHandleChange.bind(this)}
                allowSelectAll={false}
                defaultValue={{ value: false, label: "No" }}
              />
            </div>
          </div>
          {
            loggedUser.role === "Clients" ?
              (
                <div className="col-sm-12 col-md-12 col-lg-6 mt-sm-medium mt-sm-small">
                  <label className="col-sm-3 col-md-3 col-lg-3 control-label">Attribute Group</label>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <MultiSelectComponent
                      name="productAttribute2"
                      isSearchable={false}
                      options={localState.optionAttributeProducts2}
                      onChange={this.multiSelectHandleChange.bind(this)}
                      allowSelectAll={false}
                      value={localState.productAttribute2Value}
                    />
                  </div>
                </div>
              ) :
              (
                <>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-sm-medium mt-sm-small">
                    <label className="col-sm-3 col-md-3 col-lg-3 control-label">Attribute Group 1</label>
                    <div className="col-sm-4 col-md-4 col-lg-4">
                      <MultiSelectComponent
                        isDisabled={loggedUser.role === "Clients"}
                        name="productAttribute1"
                        isSearchable={false}
                        options={localState.optionAttributeProducts1}
                        onChange={this.multiSelectHandleChange.bind(this)}
                        allowSelectAll={false}
                        value={localState.productAttribute1Value}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-sm-medium mt-sm-small">
                    <label className="col-sm-3 col-md-3 col-lg-3 control-label">Attribute Group 2</label>
                    <div className="col-sm-4 col-md-4 col-lg-4">
                      <MultiSelectComponent
                        name="productAttribute2"
                        isSearchable={false}
                        options={localState.optionAttributeProducts2}
                        onChange={this.multiSelectHandleChange.bind(this)}
                        allowSelectAll={false}
                        value={localState.productAttribute2Value}
                      />
                    </div>
                  </div>
                </>
              )
          }


        </div>
        <div className="form-group">
          <div className="col-sm-12">
            <div className="pull-right mb-sm-small">
              {this.extendButtons()}
            </div>
            <div className="col-md-3 p-none">
              {this.renderSearch()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default App;
