import jsPDF from "jspdf";
import "jspdf-autotable";
import logo64 from "./MeridianLogo";
import CommonUtilities from "../Utilities/Common";
var _ = require("lodash");
var DateFormat = require("dateformat");

export const handleExportPdf = async (
  cropYear,
  filterStartDate,
  filterEndDate,
  arrGrowers,
  data,
  action
) => {
  window.showAlert("Info", "Exporting...", "info");
  try {
    composePdfFile(
      cropYear,
      filterStartDate,
      filterEndDate,
      arrGrowers,
      data,
      action === "print",
      action === "preview"
    );
  } catch (err) {
    window.showAlert("Error", err.message, "error");
  }
};

export const composePdfFile = (
  cropYear,
  filterStartDate,
  filterEndDate,
  arrGrowers,
  data,
  isPrint,
  isPreview
) => {
  if (data) {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);

    const fileName = `meridian-grower-summary.pdf`;
    const tableFontSize = 7;
    const pageWidth = doc.internal.pageSize.width;
    let arrPageInfo = [];
    let arrAddedHeader = [];

    let firsStartY = -1;
    let startY = 0;
    //Totals whole growers
    let stainedTotal = 0;
    let inshTotal = 0;
    let edibleKernels = 0;
    let acpTotals = 0;

    for (let i = 0; i < arrGrowers.length; i++) {
      let pageNumber = -1;
      if (i === 0) {
        pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
      }
      const growerInfo = arrGrowers[i];
      if (i > 0) {
        doc.addPage();
        pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
      }
      const dataByGrower = data.filter((x) => {
        return x.AccountID === growerInfo.AccountID;
      });

      arrAddedHeader.push(doc.internal.getCurrentPageInfo().pageNumber);
      startY = composeHeader(doc, filterStartDate, filterEndDate, cropYear);
      startY = composeGrowerInfo(doc, startY, growerInfo);
      startY = startY + 20;
      if (firsStartY === -1) {
        firsStartY = startY;
      }

      let itemsByGrower = [];
      const groups = _(dataByGrower)
        .groupBy("AreaOfOriginID")
        .map((children, AreaOfOriginID) => {
          const items = children.map((x) => x);
          return {
            AreaOfOriginID,
            items,
          };
        })
        .value();

      //Loop each field (AreaOfOriginID)
      groups.map((x) => {
        addText(
          doc,
          5,
          startY,
          `Field: ${x.AreaOfOriginID ? x.AreaOfOriginID : ``}`,
          0
        );
        startY = startY + 10;
        itemsByGrower = itemsByGrower.concat(x.items);
        const items = [...x.items];
        const rowTotalByField = addRowTotal(items, x.AreaOfOriginID);
        const rowLastByField = addRowLast(items);
        items.push(rowTotalByField);
        items.push(rowLastByField);
        composeTable(items, doc, tableFontSize, startY, firsStartY, false);
        startY = doc.lastAutoTable.finalY + 20;

        stainedTotal +=
          _.sumBy(items, (k) => {
            return parseFloat(k["EdibleUnstainedTotal"]);
          }) +
          _.sumBy(items, (k) => {
            return parseFloat(k["EdibleLightstainedTotal"]);
          });
        inshTotal +=
          _.sumBy(items, (k) => {
            return parseFloat(k["InshellShellStk"]);
          }) +
          _.sumBy(items, (k) => {
            return parseFloat(k["SubEdiClosedInshelTotal"]);
          });
        edibleKernels += _.sumBy(items, (k) => {
          return parseFloat(k["SizeKernels"]);
        });
        acpTotals += _.sumBy(items, (k) => {
          return parseFloat(k["SizeAssessedWtTotal"]);
        });
      });

      console.log(JSON.stringify(itemsByGrower));
      const rowsTotalByGrower = [];
      const rowTotalByGrower = addRowTotal(
        itemsByGrower,
        growerInfo.AccountName ?? ``
      );
      const rowLastByGrower = addRowLast(itemsByGrower);
      rowsTotalByGrower.push(rowTotalByGrower);
      rowsTotalByGrower.push(rowLastByGrower);
      composeTable(
        rowsTotalByGrower,
        doc,
        tableFontSize,
        startY,
        firsStartY,
        true
      );

      if (pageNumber !== -1) {
        arrPageInfo.push({ pageNumber: pageNumber, grower: growerInfo });
      }
    }

    addRowFinal(
      doc,
      startY,
      pageWidth,
      stainedTotal.toFixed(0),
      inshTotal.toFixed(0),
      edibleKernels.toFixed(0),
      acpTotals.toFixed(0)
    );

    composeFooter(
      filterStartDate,
      filterEndDate,
      doc,
      cropYear,
      arrPageInfo,
      arrAddedHeader
    );

    //open popup print
    if (isPrint || isPreview) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        window.navigator.msSaveOrOpenBlob(doc.output("blob"), fileName);
      } else {
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        if (isPrint) {
          doc.autoPrint();
        }
        window.open(
          URL.createObjectURL(doc.output("blob")),
          "_blank",
          "height=" +
            window.innerHeight +
            ",width=" +
            window.innerWidth +
            ",scrollbars=yes,location=yes"
        );

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(() => {
          window.URL.revokeObjectURL(doc.output("bloburl"));
        }, 100);
      }
    } else {
      //export file
      doc.save(fileName);
    }
  }
};

function addRowTotal(items, label) {
  let rowTotal = {};
  rowTotal["LotCode"] = label && label !== `` ? `${label} Total:` : `Total:`;

  let greenGross = _.sumBy(items, (x) => {
    return x["GreenGross"];
  });
  if (greenGross === undefined) {
    console.log(`items=${JSON.stringify(items)}`);
  }
  rowTotal["GreenGross"] = CommonUtilities.numberWithCommas(
    greenGross.toFixed(0)
  );

  let inboundGradePrecleanerTrash = _.sumBy(items, (x) => {
    return x["Inbound Grade-Precleaner Trash"];
  });
  rowTotal["Inbound Grade-Precleaner Trash"] = CommonUtilities.numberWithCommas(
    inboundGradePrecleanerTrash.toFixed(0)
  );

  let netGreenWeight = _.sumBy(items, (x) => {
    return x["NetGreenWeight"];
  });
  rowTotal["NetGreenWeight"] = CommonUtilities.numberWithCommas(
    netGreenWeight.toFixed(0)
  );

  let netDryWeight = _.sumBy(items, (x) => {
    return x["NetDryWeight"];
  });
  rowTotal["NetDryWeight"] = CommonUtilities.numberWithCommas(
    netDryWeight.toFixed(0)
  );

  let edibleUnstainedTotal = _.sumBy(items, (x) => {
    return x["EdibleUnstainedTotal"];
  });
  rowTotal["EdibleUnstainedTotal"] = CommonUtilities.numberWithCommas(
    edibleUnstainedTotal.toFixed(0)
  );

  let edibleLightstainedTotal = _.sumBy(items, (x) => {
    return x["EdibleLightstainedTotal"];
  });
  rowTotal["EdibleLightstainedTotal"] = CommonUtilities.numberWithCommas(
    edibleLightstainedTotal.toFixed(0)
  );

  let inshellShellStk = _.sumBy(items, (x) => {
    return x["InshellShellStk"];
  });
  rowTotal["InshellShellStk"] = CommonUtilities.numberWithCommas(
    inshellShellStk.toFixed(0)
  );

  let subEdiClosedInshelTotal = _.sumBy(items, (x) => {
    return x["SubEdiClosedInshelTotal"];
  });
  rowTotal["SubEdiClosedInshelTotal"] = CommonUtilities.numberWithCommas(
    subEdiClosedInshelTotal.toFixed(0)
  );

  let sizeKernels = _.sumBy(items, (x) => {
    return x["SizeKernels"];
  });
  rowTotal["SizeKernels"] = CommonUtilities.numberWithCommas(
    sizeKernels.toFixed(0)
  );

  let totalEdiWeightTotal = _.sumBy(items, (x) => {
    return x["TotalEdiWeightTotal"];
  });
  rowTotal["TotalEdiWeightTotal"] = CommonUtilities.numberWithCommas(
    totalEdiWeightTotal.toFixed(0)
  );

  let sizeAssessedWtTotal = _.sumBy(items, (x) => {
    return x["SizeAssessedWtTotal"];
  });
  rowTotal["SizeAssessedWtTotal"] = CommonUtilities.numberWithCommas(
    sizeAssessedWtTotal.toFixed(0)
  );

  return rowTotal;
}

function addRowLast(items) {
  let rowTotal = {};

  let greenGross =
    _.sumBy(items, (x) => {
      return x["GreenGross"];
    }) / 100.0;
  rowTotal["GreenGross"] = `100.00%`;

  let inboundGradePrecleanerTrash = _.sumBy(items, (x) => {
    return x["Inbound Grade-Precleaner Trash"];
  });
  rowTotal[
    "Inbound Grade-Precleaner Trash"
  ] = `${CommonUtilities.numberWithCommas(
    (greenGross === 0 ? 0 : inboundGradePrecleanerTrash / greenGross).toFixed(2)
  )}%`;

  let netGreenWeight = _.sumBy(items, (x) => {
    return x["NetGreenWeight"];
  });
  rowTotal["NetGreenWeight"] = `${CommonUtilities.numberWithCommas(
    (greenGross === 0 ? 0 : netGreenWeight / greenGross).toFixed(2)
  )}%`;

  let netDryWeight = _.sumBy(items, (x) => {
    return x["NetDryWeight"];
  });
  rowTotal["NetDryWeight"] = `${CommonUtilities.numberWithCommas(
    (greenGross === 0 ? 0 : netDryWeight / greenGross).toFixed(2)
  )}%`;

  //tuong
  let edibleUnstainedTotal = _.sumBy(items, (x) => {
    return x["EdibleUnstainedTotal"];
  });
  let edibleLightstainedTotal = _.sumBy(items, (x) => {
    return x["EdibleLightstainedTotal"];
  });
  let inshellShellStk = _.sumBy(items, (x) => {
    return x["InshellShellStk"];
  });
  let subEdiClosedInshelTotal = _.sumBy(items, (x) => {
    return x["SubEdiClosedInshelTotal"];
  });
  let sizeKernels = _.sumBy(items, (x) => {
    return x["SizeKernels"];
  });

  let LMPQ =
    edibleUnstainedTotal +
    edibleLightstainedTotal +
    inshellShellStk +
    subEdiClosedInshelTotal;

  rowTotal["EdibleUnstainedTotal"] = `${(
    (LMPQ === 0 ? 0 : edibleUnstainedTotal / LMPQ) * 100.0
  ).toFixed(2)}%`;

  rowTotal["EdibleLightstainedTotal"] = `${(
    (LMPQ === 0 ? 0 : edibleLightstainedTotal / LMPQ) * 100.0
  ).toFixed(2)}%`;

  rowTotal["InshellShellStk"] = `${(
    (LMPQ === 0 ? 0 : inshellShellStk / LMPQ) * 100.0
  ).toFixed(2)}%`;

  rowTotal["SubEdiClosedInshelTotal"] = `${(
    (LMPQ === 0 ? 0 : subEdiClosedInshelTotal / LMPQ) * 100.0
  ).toFixed(2)}%`;

  rowTotal["SizeKernels"] = `${(
    (LMPQ === 0 ? 0 : sizeKernels / LMPQ) * 100.0
  ).toFixed(2)}%`;

  let totalEdiWeightTotal = _.sumBy(items, (x) => {
    return x["TotalEdiWeightTotal"];
  });
  rowTotal["TotalEdiWeightTotal"] = `${CommonUtilities.numberWithCommas(
    (greenGross === 0 ? 0 : totalEdiWeightTotal / greenGross).toFixed(2)
  )}%`;

  let sizeAssessedWtTotal = _.sumBy(items, (x) => {
    return x["SizeAssessedWtTotal"];
  });
  rowTotal["SizeAssessedWtTotal"] = `${CommonUtilities.numberWithCommas(
    (greenGross === 0 ? 0 : sizeAssessedWtTotal / greenGross).toFixed(2)
  )}%`;

  const length = items.length;

  rowTotal["TotalEdiSplitSample%"] = `${(
    _.sumBy(items, (x) => {
      return x["TotalEdiSplitSamplePercent"];
    }) / length
  ).toFixed(2)}%`;

  rowTotal["RejectFromSplit%"] = `${(
    _.sumBy(items, (x) => {
      return x["RejectFromSplitPercent"];
    }) / length
  ).toFixed(2)}%`;

  rowTotal["SubEdiClosedInshelTotal%"] = `${(
    _.sumBy(items, (x) => {
      return x["SubEdiClosedInshelTotalPercent"];
    }) / length
  ).toFixed(2)}%`;

  rowTotal["InsectsFromOpen%"] = `${(
    _.sumBy(items, (x) => {
      return x["InsectsFromOpenPercent"];
    }) / length
  ).toFixed(2)}%`;

  rowTotal["TotalEdiWeightTotal%"] = `${(
    _.sumBy(items, (x) => {
      return x["TotalEdiWeightTotalPercent"];
    }) / length
  ).toFixed(2)}%`;

  return rowTotal;
}

function addRowFinal(
  doc,
  startY,
  pageWidth,
  stainedTotal,
  inshTotal,
  edibleKernels,
  acpTotals
) {
  startY = doc.lastAutoTable.finalY + 10;
  addLine(doc, 5, startY, pageWidth - 5, startY);
  addLine(doc, 5, startY + 30, pageWidth - 5, startY + 30);

  startY += 15;
  addText(doc, 240, startY, `Unstained And`, 0, `right`);
  addText(doc, 400, startY, `Shell Stk And Closed`, 0, `right`);
  //Second line
  startY += 10;
  addText(doc, 240, startY, `Stained Total:`, 0, `right`);
  addText(
    doc,
    245,
    startY,
    ` ${CommonUtilities.numberWithCommas(stainedTotal)}`,
    0
  );
  addText(doc, 400, startY, `Insh Total:`, 0, `right`);
  addText(
    doc,
    405,
    startY,
    ` ${CommonUtilities.numberWithCommas(inshTotal)}`,
    0
  );
  addText(doc, 570, startY, `Edible Kernels:`, 0, `right`);
  addText(
    doc,
    575,
    startY,
    ` ${CommonUtilities.numberWithCommas(edibleKernels)}`,
    0
  );
  addText(doc, 720, startY, `ACP Totals:`, 0, `right`);
  addText(
    doc,
    725,
    startY,
    ` ${CommonUtilities.numberWithCommas(acpTotals)}`,
    0
  );

  return startY;
}

function composeHeader(doc, filterStartDate, filterEndDate, cropYear) {
  const gap = 15;
  let startX = 600;
  let startY = 40;
  doc.setFontSize(15);
  // doc.setFontType("bold");
  doc.text("Grower Detail Statement", startX, startY, "left");

  doc.setFontSize(9);
  startY += gap * 2;
  doc.text(`Crop Year: ${cropYear}`, startX, startY, "left");

  startY += gap;
  doc.text(
    `Received Date From: ${filterStartDate} and ${filterEndDate}`,
    startX,
    startY,
    "left"
  );

  doc.addImage(logo64, "png", 5, 20, 140, 60);

  startY = 50;
  let startXLeft = 175;
  //   doc.setFontType("bold");
  //   doc.setFontType("normal");
  startY = addText(
    doc,
    startXLeft,
    startY,
    `13559 Firebaugh Blvd Madera, CA 93637`, //data.AccountName,
    gap
  );

  return startY;
}

function composeGrowerInfo(doc, startY, data) {
  const gap = 15;
  let startXLeft = 5;
  startY = 90;
  startY = addText(doc, startXLeft, startY, `Grower `, 0);

  startXLeft = 38;
  startY = addText(doc, startXLeft, startY, `${data.AccountName ?? ``}`, gap);

  if (data.Addressline1 && data.Addressline1 !== ``) {
    startY = addText(
      doc,
      startXLeft,
      startY,
      `${data.Addressline1 ?? ``}`,
      gap
    );
  }
  if (data.Addressline2 && data.Addressline2 !== ``) {
    startY = addText(
      doc,
      startXLeft,
      startY,
      `${data.Addressline2 ?? ``}`,
      gap
    );
  }
  if (data.Addressline3 && data.Addressline3 !== ``) {
    startY = addText(
      doc,
      startXLeft,
      startY,
      `${data.Addressline3 ?? ``}`,
      gap
    );
  }

  return startY;
}

function addText(doc, x, y, text, gap, align = `left`) {
  doc.text(`${text ?? ""}`, x, y, align);
  // doc.setDrawColor(0, 0, 0);
  // doc.setLineWidth(0.1);
  return y + gap;
}

function composeTable(data, doc, tableFontSize, startY, top, isTotalGrower) {
  if (data === null || data === undefined || data.length <= 0) {
    return;
  }
  const columns = [
    { header: "Recv Date", dataKey: "ScheduledStartDateTimeFormatted" },
    { header: "Lot #", dataKey: "LotCode" },
    { header: "Manifest #", dataKey: "ReferenceID" },
    { header: "Green Gross", dataKey: "GreenGross" }, //NetGreenWeight + `Inbound Grade-Precleaner Trash`
    { header: "Trash", dataKey: "Inbound Grade-Precleaner Trash" },
    { header: "Net Green", dataKey: "NetGreenWeight" },
    { header: "Net Dry", dataKey: "NetDryWeight" },
    { header: "Split Unstained", dataKey: "EdibleUnstainedTotal" },
    { header: "Split Light Stained", dataKey: "EdibleLightstainedTotal" },
    { header: "Total Edible Split % Dry", dataKey: "TotalEdiSplitSample%" },
    { header: "Inshell Reject%", dataKey: "RejectFromSplit%" },
    { header: "Inshell Shell Stk", dataKey: "InshellShellStk" }, //TotalEdiKernTotal*2
    { header: "Closed Inshell", dataKey: "SubEdiClosedInshelTotal" },
    { header: "Closed Inshell %", dataKey: "SubEdiClosedInshelTotal%" },
    { header: "Edible Kernels", dataKey: "SizeKernels" },
    { header: "Inshell Insect %", dataKey: "InsectsFromOpen%" },
    { header: "Total Edible % Gross", dataKey: "TotalEdiWeightTotal" },
    { header: "Edible % Net Green", dataKey: "TotalEdiWeightTotal%" },
    { header: "ACP Totals", dataKey: "SizeAssessedWtTotal" },
  ];

  doc.autoTable({
    margin: { left: 5, right: 5, top: top },
    startY: startY, //doc.autoTableEndPosY() + 20,
    // tablelineWidth: 0.01,
    // tableLineColor: "red",
    // head: columns,
    columns: columns,
    body: data,
    headStyles: {
      // fillColor: [33, 150, 243],
      halign: "center",
      fontSize: tableFontSize,
      lineColor: "gray",
      lineWidth: 0.01,
    },
    showHead: isTotalGrower ? `never` : "firstPage",
    willDrawCell: function (data) {
      if (
        data.row.raw.LotCode === undefined ||
        data.row.raw.LotCode.indexOf("Total:") !== -1
      ) {
        // doc.setFontType("bold");
        // doc.setDrawColor("black");
        doc.setTextColor("black");
        doc.setFillColor(isTotalGrower ? `#ffff00` : "#AFEEEE");
      }
    },
    columnStyles: {
      0: {
        halign: "left",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
      },
      1: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        // cellWidth: 60,
      },
      2: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
      },
      3: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        // //cellWidth: 80,
      },
      4: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      5: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      6: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      7: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      8: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      9: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      10: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      11: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      12: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      13: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      14: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      15: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      16: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      17: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      18: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
      19: {
        halign: "center",
        fontSize: tableFontSize,
        lineColor: "gray",
        lineWidth: 0.01,
        //cellWidth: 80,
      },
    },
  });
}

function composeFooter(
  filterStartDate,
  filterEndDate,
  doc,
  cropYear,
  arrPageInfo,
  arrAddedHeader
) {
  const pages = doc.internal.getNumberOfPages();
  //Do not insert page number if there is only 1 page
  if (pages && pages > 1) {
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional

    let grower;
    for (let j = 1; j < pages + 1; j++) {
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      const pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
      const arrTemp = arrPageInfo.filter((x) => {
        return x.pageNumber === pageNumber;
      });
      if (arrTemp && arrTemp.length > 0) {
        grower = arrTemp[0].grower;
      }
      if (j > 1 && arrAddedHeader.indexOf(pageNumber) === -1) {
        let startY = composeHeader(
          doc,
          filterStartDate,
          filterEndDate,
          cropYear
        );
        composeGrowerInfo(doc, startY, grower);
      }
      doc.text(
        `Print Date ${DateFormat(new Date(), "mm/dd/yyyy hh:MM:ss TT")}`,
        5,
        verticalPos,
        {
          align: "left",
        }
      );

      doc.text(`Page ${j} of ${pages}`, pageWidth - 5, verticalPos, {
        align: "right",
      });
    }
  }
}

function addLine(doc, x1, y1, x2, y2) {
  doc.setDrawColor(`gray`);
  doc.setLineWidth(0.1);
  doc.line(x1, y1, x2, y2);
}
