import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";
// import FileBase64 from "react-file-base64";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";

const loggedUser = getLoggedUser();
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
    this.state = {
      listtitle: "Administrators",
      urllist: process.env.REACT_APP_URL_ADMINISTRATOR_LIST,
      urlapi: GlobalConfig.REACT_APP_API_ADMINISTRATOR_URL,
      // imageFieldName: "avatar",
      // imageFieldExtension: "avatarExtension",
      // imageMaxSize: parseInt(process.env.REACT_APP_MAX_SIZE_PROFILE_IMAGE),
      // defaultAvatar: process.env.REACT_APP_DEFAULT_AVATAR_PHOTO,
      data: {
        _id: props.match.params.id,
        role: "",
        name: "",
        email: "",
        status: "",
        password: "",
        avatar: "",
        avatarExtension: "",
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
      },
    };
  }

  // handleRemoveAvatar = () => {
  //   this.setState({
  //     data: Object.assign({}, this.state.data, {
  //       ["avatar"]: this.state.defaultAvatar,
  //     }),
  //   });
  // };

  // doSomethingAfterGetItemById = () => {
  //   if (
  //     this.state.data.avatar != null &&
  //     this.state.data.avatar != undefined &&
  //     this.state.data.avatar != "" &&
  //     this.state.data.avatar != this.state.defaultAvatar
  //   ) {
  //     const arrTemp = this.state.data.avatar.split(".");
  //     const fileExtension = arrTemp[arrTemp.length - 1];
  //     this.setState({
  //       data: Object.assign({}, this.state.data, {
  //         ["avatar"]: GlobalConfig.REACT_APP_API_URL + this.state.data.avatar,
  //         ["avatarExtension"]: fileExtension,
  //       }),
  //     });
  //   }
  // };

  getTitle = () => {
    return this.state.data.name;
  };

  render() {
    const title = "Edit - " + this.getTitle();
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>

              <div className="row">
                {/* <div className="col-md-4 col-lg-3">
                  <section className="panel">
                    <div className="panel-body">
                      <div className="thumb-info mb-md">
                        {this.state.data.avatar === null ||
                          this.state.data.avatar === "" ||
                          this.state.data.avatar === undefined ? (
                            <img
                              src={this.state.defaultAvatar}
                              title="avatar"
                              className="rounded img-responsive"
                              alt={this.state.data.name}
                            ></img>
                          ) : (
                            <img
                              src={this.state.data.avatar}
                              title="avatar"
                              className="rounded img-responsive"
                              alt={this.state.data.name}
                            ></img>
                          )}
                      </div>

                      <div className="widget-toggle-expand mb-md"></div>

                      <hr className="dotted short"></hr>

                      <div className="center">
                        <input
                          type="button"
                          className="btn btn-primary"
                          value="Remove Avatar (use default)"
                          onClick={this.handleRemoveAvatar}
                        ></input>
                      </div>
                    </div>
                  </section>
                </div> */}
                <div className="col-md-8 col-lg-6">
                  <div className="tabs">
                    <ul className="nav nav-tabs tabs-primary">
                      <li className="active">
                        <a href="#overview" data-toggle="tab">
                          Overview
                      </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div id="overview" className="tab-pane active">
                        <form
                          className="form-horizontal form-bordered"
                          onSubmit={this.handleSubmit}
                          id="form"
                        >
                          <h4 className="mb-xlg">Personal Information</h4>
                          <fieldset>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="name"
                              >
                                Full Name
                            </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="150"
                                  type="text"
                                  // ref="name"
                                  name="name"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.name}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="email"
                              >
                                Email
                            </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="50"
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.email}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            {/* <div className="form-group">
                              <label
                                className="col-xs-3 control-label mt-xs pt-none"
                                htmlFor="avatar"
                              >
                                Upload Profile Image
                            </label>
                              <div className="col-md-9">
                                <FileBase64
                                  className="form-control"
                                  name="avatar"
                                  multiple={false}
                                  onDone={this.handleImageChange}
                                />
                              </div>
                            </div> */}
                          </fieldset>
                          <hr className="dotted tall"></hr>
                          <h4 className="mb-xlg">Account</h4>
                          <fieldset className="mb-xl">
                            <div className="form-group">
                              <label
                                className="col-xs-3 control-label mt-xs pt-none"
                                htmlFor="status"
                              >
                                Status
                            </label>
                              <div className="col-md-8">
                                <select
                                  name="status"
                                  className="form-control"
                                  required
                                  value={this.state.data.status}
                                  onChange={this.handleChange}
                                >
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>
                          </fieldset>
                          <div className="panel-footer">
                            <div className="row">
                              <div className="col-md-9 col-md-offset-3">
                                <button type="submit" className="btn btn-success m-none">
                                  <i className="fa fa-save fa-lg"></i> Save
                              </button>
                                <a
                                  href={this.state.urllist}
                                  title="Back to list"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-backward fa-lg"></i> Back
                              </a>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3"></div>
              </div>
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                ></Alert>
              ) : (
                  ""
                )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
