import React from "react";
import AddComponent from "../Base/AddComponent";
import MultiSelectComponent from "../Base/MultiSelectComponent";
import Moment from "moment";

import { DatePickerIcon } from "../Base/DatePickerComponent";
import { DateTimeFormat } from "../../global";
import { HttpAxios } from "../../Utilities/Http";

const GlobalConfig = new window.globalConfig();

const typeCronJob = {
  Hourly: "Hourly",
  Daily: "Daily"
};
const typeCronJobOptions = [{
  value: typeCronJob.Hourly,
  label: typeCronJob.Hourly
},
{
  value: typeCronJob.Daily,
  label: typeCronJob.Daily
}];


class App extends AddComponent {
  constructor(props) {
    super();
    this.state = {
      title: "Scheduled Task",
      listtitle: "Scheduled Task",
      urllist: process.env.REACT_APP_URL_CRON_JOB_SETTING,
      urlapi: GlobalConfig.REACT_APP_API_CRON_JOB_SETTING,
      urlapiTaskNames: GlobalConfig.REACT_APP_API_CRON_JOB_SETTING + "tasknames/unregistered",
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      data: {
        _id: "",
        taskName: "",
        cronStyle: "",
        typeCronJob: typeCronJob.Hourly,
        startTime: "00:00",
        blocked: false,
        description: null,
        startDateTime: null,
        transactionDateFrom: null,
        transactionDateFromDisplay: null,
        transactionDateTo: null,
        // currentTime: true,
      },
      taskNameOptions: null,
      taskNameValue: null,
      typeCronJobValue: null,
      startInfo: null,
      submitDisabled: false,
    };
  }

  inititalComponent() {
    this.getTaskNameItems();
  }

  async getTaskNameItems() {
    const fetchData = await HttpAxios.get(this.state.urlapiTaskNames);
    const dataOptions = fetchData.data;
    const options = MultiSelectComponent.ConvertToOptions(dataOptions.data);

    this.setStartInfo(typeCronJob.Hourly, this.state.data.startTime);
    this.setState(state => {
      const data = { ...state.data, taskName: options ? options[0].value : "" };
      return {
        data: data,
        taskNameOptions: options,
        taskNameValue: options ? options[0] : null,
        alertshow: !options,
        alertmsg: "There are no unscheduled reports. Each report can only have one schedule.",
        alerttype: "error",
        submitDisabled: !options,
        linkToReport: "",
      };
    });
  }

  startTimeChangeHandler(e) {
    if (!e.target.value) {
      e.target.value = "00:00";
    }
    this.setStartInfo(this.state.data.typeCronJob, e.target.value);
    this.handleChange(e);
  }

  typeCronJobChangeHandler(selected, e) {
    this.setStartInfo(selected.value, this.state.data.startTime);
    this.multiSelectHandleChange(selected, e);
  }

  transDateChangeHandler(selected, name) {
    this.setState(state => {
      const keyDisplay = `${name}Display`;
      const data = { ...state.data, [name]: Moment(selected).format("YYYY-MM-DD"), [keyDisplay]: selected };
      return { data }
    });
  }

  setStartInfo(type, startAt) {
    let datetime = new Date(`${Moment(new Date()).format(DateTimeFormat.format5).toString()} ${startAt}:00`);
    const now = new Date();

    if (datetime < now && type === typeCronJob.Hourly) {
      datetime = new Date(datetime.getTime() + 86400000);
    } else if (type === typeCronJob.Daily) {
      datetime = new Date(datetime.getTime() + 86400000);
    }

    this.setState(state => {
      const data = { ...state.data, startDateTime: datetime.toString() };
      return {
        startInfo: Moment(datetime).format(DateTimeFormat.format4),
        data: data,
      }
    });
  }

  renderForm = () => {
    return (
      <div className="panel-body">

        <div className="form-group">
          <label className="col-sm-3 control-label">
            Report <span className="required">*</span>
          </label>
          <div className="col-sm-5">
            <MultiSelectComponent
              name="taskName"
              isSearchable={false}
              options={this.state.taskNameOptions}
              onChange={this.multiSelectHandleChange.bind(this)}
              allowSelectAll={false}
              value={this.state.taskNameValue}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-3 control-label">
            Type
          </label>
          <div className="col-sm-2">
            <MultiSelectComponent
              name="typeCronJob"
              isSearchable={false}
              options={typeCronJobOptions}
              onChange={this.typeCronJobChangeHandler.bind(this)}
              allowSelectAll={false}
              defaultValue={typeCronJobOptions[0]}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">Step Size <span className="required">*</span></label>
          <div className="col-md-2">
            <input 
              required
              min={0}
              max={100}
              name="stepSize"
              defaultValue={1}
              value={this.state.data.stepSize}
              onChange={this.handleChange.bind(this)}
              className="form-control" type="number"/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">Start At</label>
          <div className="col-md-2">
            <input
              name="startTime"
              value={this.state.data.startTime}
              onChange={this.startTimeChangeHandler.bind(this)}
              className="form-control" type="time" />
          </div>
          <label className="control-label pl-md"><span className="fa fa-info-circle"></span>&nbsp; Next run: {this.state.startInfo}</label>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">Link to Report</label>
          <div className="col-md-5">
            <input
              autoComplete={false}
              maxLength="200"
              name="linkToReport"
              value={this.state.data.linkToReport}
              onChange={this.handleChange.bind(this)}
              className="form-control" type="url" />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-3 control-label">
            Description
          </label>
          <div className="col-sm-5">
            <textarea
              maxLength="1000"
              name="description"
              rows="4"
              className="form-control"
              placeholder="Description"
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-3 control-label">
            Transaction Date From
          </label>
          <div className="col-sm-2">
            <DatePickerIcon
              selected={this.state.data.transactionDateFromDisplay ? this.state.data.transactionDateFromDisplay : null}
              onChange={(selectedDate) => { this.transDateChangeHandler(selectedDate, "transactionDateFrom") }}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              />
          </div>
        </div>
      </div>
    );
  };
}
export default App;
