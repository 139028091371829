import { connect } from 'react-redux';
import Profile from '../../Components/Administrator/profile';

const mapStateToProps = state => ({
    profile: state.profile
})

const mapDispatchToProps = dispatch => ({
    updateProfile: profile => dispatch(profile)
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)