import React from "react";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import { handleExportPdf } from "../ExportPdf/MeridianGrowerSummary";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { HttpAxios } from "../../Utilities/Http";
import { DateRangePicker } from "../Base/DatePickerComponent";
import MultiSelectComponent from "../Base/MultiSelectComponent";
var _ = require("lodash");
const GlobalConfig = new window.globalConfig();

let loggedUser = null;

class App extends ListComponent {
  constructor(props) {
    super(props);
    loggedUser = getLoggedUser();
    this.state = {
      urlapi: ``,
      title: "Meridian Grower Summary",
      pageTitle: "Meridian Grower Summary",
      pluraltitle: "Meridian Grower Summary",
      searchFormBasic: true,
      // hidePanelAction: true,
      // paginationDisabled: true,
      filterStartDate: undefined,
      filterEndDate: undefined,
      filterStartDateDisplay: ``,
      filterEndDateDisplay: ``,
      companyId: ``,
      companyName: ``,

      //paging
      // loading: false,
      // totalRows: 0,
      // currentPage: 1,
      // perPage: 20,
    };
    this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
  }

  async componentDidMount() {
    await this.doSomthingBeforeGetItems();
    // this.getItems(this.state.currentPage, this.state.perPage);
  }

  handleChangeSelectControl = (controlId, value, selectedText) => {
    this.setState({ [controlId]: value, clientName: selectedText });
  };

  defaultTable = () => {};

  defaultItemsLength = () => {
    if (this.state === undefined || this.state.items === undefined) {
      return 0;
    }
    return this.state.totalRows;
  };

  fitToColumn(data) {
    let widths = this.initialWidthColumns(data[0]);
    data.map((item) => {
      const arrObject = Object.values(item);
      arrObject.map((obj, i) => {
        const len = String(obj).length;
        widths[i] = len > widths[i] ? len : widths[i];
      });
    });

    return widths.map((width) => {
      return {
        wch: width,
      };
    });
  }

  initialWidthColumns(data) {
    const arrObject = Object.keys(data);
    return arrObject.map((item) => {
      return String(item).length;
    });
  }

  handleDatePickerChange = (selectedDate, key) => {
    this.setState({ [key]: selectedDate }, function callBack() {
      if (key === `filterStartDate`) {
        this.setState({
          filterStartDateDisplay: moment(selectedDate).format("YYYY-MM-DD"),
        });
      } else if (key === `filterEndDate`) {
        this.setState({
          filterEndDateDisplay: moment(selectedDate).format("YYYY-MM-DD"),
        });
      }
      this.setState({ currentPage: 1 }, () => {
        this.getItems(this.state.currentPage, this.state.perPage);
      });
    });
  };

  doSomthingBeforeGetItems = async () => {
    await this.getDefaultDateRange();
    await this.getCompanyItems();
  };

  getDefaultDateRange = async () => {
    let now = new Date();
    let filterEndDate = new Date(now);
    let filterStartDate = now;
    filterStartDate.setDate(1);
    filterStartDate.setMonth(0);

    this.setState({
      filterStartDate: filterStartDate,
      filterEndDate: filterEndDate,
      filterStartDateDisplay: moment(filterStartDate).format("YYYY-MM-DD"),
      filterEndDateDisplay: moment(filterEndDate).format("YYYY-MM-DD"),
    });
  };

  async getCompanyItems() {
    try {
      let urlApi =
        GlobalConfig.REACT_APP_API_CONFIG_URL +
        "queryclients/" +
        process.env.REACT_APP_ATTRIBUTE_REFLECT_CUSTOMER;
      if (loggedUser && loggedUser.role === "Clients") {
        urlApi += `/${loggedUser.companyId}`;
      }
      const response = await HttpAxios.get(urlApi);
      const resultObject = response.data;
      if (resultObject && resultObject.result === "OK" && resultObject.data) {
        const parsedItems = [];
        resultObject.data.map((item) => {
          parsedItems.push({
            value: item._id,
            label: item.name,
            _id: item._id,
            name: item.name,
          });
        });
        this.setState({
          companies: parsedItems,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  multiSelectCompanyHandleChange(selected, element) {
    const key = element.name;
    const keyValue = `${element.name}Value`;
    let valueOptions = selected ? selected.value : ``;
    let selectedText = ``;

    if (selected && Array.isArray(selected)) {
      const options = [...selected];
      const hasSelectAll = options.some((item) => {
        return (
          item.value === MultiSelectComponent.defaultProps.allOption.value &&
          item.label === MultiSelectComponent.defaultProps.allOption.label
        );
      });
      if (hasSelectAll) {
        options.shift();
      }
      valueOptions = options.map((item) => item.value).join();
      valueOptions = valueOptions ? valueOptions : "";
      selectedText = options.map((item) => item.label).join(`##`);
    }
    this.setState((state) => {
      return {
        [keyValue]: selected,
        [key]: valueOptions,
        companyName: selectedText,
      };
    });
  }

  renderSearchArea() {
    return (
      <>
        <div className="form-group">
          <div className="col-sm-4 col-md-4 col-lg-4">
            <label className="col-sm-3 col-md-3 col-lg-3 control-label">
              Date Range
            </label>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <DateRangePicker
                dateFormat={"MM/dd/yyyy"}
                // showMonthYearPicker={true}
                selectedStart={this.state.filterStartDate}
                selectedEnd={this.state.filterEndDate}
                startDate={this.state.filterStartDate}
                endDate={this.state.filterEndDate}
                onChangeStart={(selectedDate) => {
                  this.handleDatePickerChange(selectedDate, "filterStartDate");
                }}
                onChangeEnd={(selectedDate) => {
                  this.handleDatePickerChange(selectedDate, "filterEndDate");
                }}
                minStartDate={this.state.filterStartDate}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6">
            <label className="col-sm-2 col-md-2 col-lg-2 control-label">
              Grower
            </label>
            <div className="col-sm-7 col-md-7 col-lg-7">
              <MultiSelectComponent
                name="companyId"
                options={this.state.companies}
                onChange={this.multiSelectCompanyHandleChange.bind(this)}
                allowSelectAll={true}
                isSearchable={false}
                value={this.state.companyIdValue}
              />
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2">
              {this.extendButtons()}
            </div>
          </div>
        </div>
      </>
    );
  }

  queryDataExport = async () => {
    let isValidSearch = true;
    let selectedAccountId =
      this.state.companyId === undefined ||
      this.state.companyId === null ||
      this.state.companyId === ``
        ? `all`
        : this.state.companyId;
    let selectedAccountName =
      this.state.companyName === undefined ||
      this.state.companyName === null ||
      this.state.companyName === ``
        ? `all`
        : this.state.companyName;
    if (loggedUser && loggedUser.role === "Clients") {
      if (selectedAccountId === `all`) {
        selectedAccountId =
          this.state.companies && this.state.companies.length > 0
            ? this.state.companies
                .map((x) => {
                  return x._id;
                })
                .join(`,`)
            : ``;
      }
      if (selectedAccountName === `all`) {
        selectedAccountName =
          this.state.companies && this.state.companies.length > 0
            ? this.state.companies
                .map((x) => {
                  return x.name;
                })
                .join(`##`)
            : ``;
      }
      //Do not allow search all if login as client
      if (selectedAccountId === `` || selectedAccountName === ``) {
        isValidSearch = false;
      }
    }
    if (isValidSearch === false) {
      window.showAlert("Infor", "No data export!");
    } else {
      let filterStartDate = "all";
      let filterStartDateDisplay = ``;
      if (this.state.filterStartDate && this.state.filterStartDate !== ``) {
        filterStartDate = moment(this.state.filterStartDate).format(
          "YYYY-MM-DD"
        );
        filterStartDateDisplay = moment(this.state.filterStartDate).format(
          "MM/DD/YYYY"
        );
      }
      let filterEndDate = `all`;
      let filterEndDateDisplay = ``;
      if (this.state.filterEndDate && this.state.filterEndDate !== ``) {
        filterEndDate = moment(this.state.filterEndDate).format("YYYY-MM-DD");
        filterEndDateDisplay = moment(this.state.filterEndDate).format(
          "MM/DD/YYYY"
        );
      }
      let cropYear =
        this.state.filterEndDate && this.state.filterEndDate !== undefined
          ? moment(this.state.filterEndDate).format("YYYY")
          : ``;
      let urlApi = `${GlobalConfig.REACT_APP_API_REPORT_MERIDIAN_GROWER_SUMMARY}exportpdf`;
      const params = {
        filterAccountId: selectedAccountId,
        filterAccountName: selectedAccountName,
        filterStartDate: filterStartDate,
        filterEndDate: filterEndDate,
      };
      const response = await HttpAxios.post(urlApi, params);
      const items = response.data.data;

      if (items && items.length > 0) {
        const accounts = _(items)
          .filter("AccountID")
          .groupBy((x) => {
            return [x["AccountID"]]; //return [x['AccountID'], x['AccountName']];
          })
          .map((children, AccountID) => {
            const items = children.map((x) => x);
            return {
              AccountID,
              items,
            };
          })
          .value();
        if (accounts && accounts.length > 0) {
          const arrGrowers = [];
          accounts.map((x) => {
            let accountName = ``;
            let addressline1 = ``;
            let addressline2 = ``;
            let addressline3 = ``;
            if (x.items && x.items.length > 0) {
              accountName = x.items[0].AccountName;
              addressline1 = x.items[0].Addressline1;
              addressline2 = x.items[0].Addressline2;
              addressline3 = x.items[0].Addressline3;
            }
            arrGrowers.push({
              AccountID: x.AccountID,
              AccountName: accountName,
              Addressline1: addressline1,
              Addressline2: addressline2,
              Addressline3: addressline3,
            });
          });

          handleExportPdf(
            cropYear,
            filterStartDateDisplay,
            filterEndDateDisplay,
            arrGrowers,
            items,
            `export`
          );
        } else {
          window.showAlert("Infor", "No data export!");
        }
      } else {
        window.showAlert("Infor", "No data export!");
      }
    }
  };

  extendButtons = () => {
    return (
      <Button
        variant="warning"
        onClick={(e) => this.queryDataExport()}
        className="btn btn-warning"
      >
        <i className="fa fa-file-pdf-o fa-lg"></i> Export
      </Button>
    );
  };
}
export default App;
