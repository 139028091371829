import React from "react";
import ReactHtmlParser from "react-html-parser";

class App extends React.Component {
  constructor(props) {
    super();
    // console.log(props);
    this.state = {
      type: props.type,
      css: "alert alert-default",
      message: props.message,
      show: props.show,
    };
  }

  async componentDidMount() {
    this.setState({ css: this.findClassName() });
  }

  findClassName = () => {
    switch (this.state.type) {
      case "info": {
        return "alert alert-info";
      }
      case "error":
      case "delete": {
        return "alert alert-danger";
      }
      case "success": {
        return "alert alert-success";
      }
      default: {
        return "alert alert-default";
      }
    }
  };

  render() {
    return this.state.show === true ? (
      <div className={this.state.css}>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×{" "}
        </button>
        {ReactHtmlParser(this.state.message)}
      </div>
    ) : (
      ""
    );
  }
}

export default App;
