import React from "react";
import SummaryAccess from "./Report/SummaryAccess";
import SummaryAdmin from "./Report/SummaryAdmin";
import { getLoggedUser } from "../context/auth";

let loggedUser = null;

class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const isActive = localStorage.getItem("isActive");
    this.state = {
      isActive: isActive,
      view: "",
    };
    this.renderSwitch = this.renderSwitch.bind(this);
  }

  async componentDidMount() {
    this.setState({ view: this.renderSwitch() });
  }

  renderSwitch() {
    switch (loggedUser.role) {
      case "Administrators":
        return (
          <>
            <SummaryAdmin />
            <div className="row">
              <div className="col-md-12">
                <SummaryAccess />
              </div>
            </div>
          </>
        );
      case "Clients":
        return "";
      default:
        return "";
    }
  }

  render() {
    return (
      <section role="main" className="content-body">
        <header className="page-header">
          <h2>Dashboard</h2>
          <div className="right-wrapper pull-right">
            <ol className="breadcrumbs">
              <li>
                <a href="/">
                  <i className="fa fa-home"></i>
                </a>
              </li>
              <li>
                <span>Dashboard</span>
              </li>
            </ol>

            <a className="sidebar-right-toggle" data-open="sidebar-right">
              <i className="fa fa-chevron-left"></i>
            </a>
          </div>
        </header>
        {this.state.view == undefined ||
          this.state.view == null ||
          this.state.view == ""
          ? ""
          : this.state.view}
      </section>
    );
  }
}
export default App;
