import React from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import MultiSelectComponent from "./MultiSelectComponent";

import { HttpAxios } from "../../Utilities/Http";
import { Helmet } from "react-helmet";

let localState = {
  filterParams: {}
};
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      labeladd: "Add",
      searchFormBasic: false,
      urlapi: "",
      urlapidelete: "",
      urllist: "",
      urladd: "",
      urlApiCronJobSetting: "",
      title: "",
      pluraltitle: "",
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      items: [],
      originalItems: [],
      filterParams: {},
      panelAction: true,
      loading: true,
      hidePanelAction: false,
      hidePanelClose: false,
      paginationDisabled: false,
      render: false,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.doSomthingAfterGetItems = this.doSomthingAfterGetItems.bind(this);
  }

  async componentDidMount() {
    await this.doSomthingBeforeGetItems();
    this.getItems();
  }

  getLocalState() {
    return localState;
  }

  setLocalState(param) {
    if (typeof param === "function") {
      const val = param(localState);
      localState = { ...localState, ...val };
    } else if (typeof param === "object") {
      localState = { ...localState, ...param };
    }
  }
  getItems = async () => {
    await this.getItemsWithFilter(localState.filterParams);
  };

  getItemsWithFilter = async (filterParams) => {
    const stateTimeout = setTimeout(() => {
      this.setState({ loading: true });
    }, 10);
    await HttpAxios
      .get(this.state.urlapi, { params: filterParams })
      .then(async ({ data }) => {
        if (data.data) {
          this.setLocalState((state) => {
            return { items: data.data, originalItems: data.data }
          });
        }

        await this.doSomthingAfterGetItems();

        if (this.state.loading) {
          this.setState((state) => {
            return {
              loading: false,
              render: !state.render
            }
          })
          clearTimeout(stateTimeout);
        } else {
          clearTimeout(stateTimeout);
          this.setState((state) => {
            return { render: !state.render }
          })
        }
      });
  }

  multiSelectHandleChange(selected, element) {
    const key = element.name;
    const keyValue = `${element.name}Value`;
    let valueOptions = selected.value;

    if (selected && Array.isArray(selected)) {
      const options = [...selected];
      const hasSelectAll = options.some((item) => {
        return (
          item.value === MultiSelectComponent.defaultProps.allOption.value &&
          item.label === MultiSelectComponent.defaultProps.allOption.label
        );
      });
      if (hasSelectAll) {
        options.shift();
      }
      valueOptions = options.map((item) => item.value).join();
      valueOptions = valueOptions ? valueOptions : [];
    }
    this.setLocalState(state => {
      const data = { ...state.filterParams, [key]: valueOptions };
      return {
        filterParams: data,
        [keyValue]: selected,
      };
    })
    this.doSomthingAfterSelectChange();
  }

  handleDelete = (e) => {
    const removedId = e.currentTarget.dataset.id;
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete selected " + this.state.title + "?",
      buttons: [
        {
          className: "btn btn-warning",
          label: "Yes",
          onClick: async () => {
            window.showAlert("Info", "Processing...", "info");
            try {
              const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
              };
              const urlapiDelete = this.state.urlapidelete ? this.state.urlapidelete : this.state.urlapi;
              const response = await HttpAxios.delete(urlapiDelete + "/" + removedId,requestOptions);
              const resultObject = response.data;
              if (resultObject && resultObject.result === "OK") {
                //If updated successful
                this.setState({
                  items: this.getLocalState().items.filter(function (item) {
                    return item._id !== removedId;
                  }),
                });
                this.setState({
                  originalItems: this.state.originalItems.filter(function (
                    item
                  ) {
                    return item._id !== removedId;
                  }),
                });
                window.showAlert("Success", "Removed successful!", "");
              } else {
                //If failed, show error
                window.showAlert("Error", resultObject.message, "error");
              }
            } catch (err) {
              window.showAlert("Error", err.message, "error");
            }
          },
        },
        {
          label: "No",
          className: "btn btn-default",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  getCronJobSetting = async (name, callback, lastCompleted = false) => {
    let urlapi =
      `${this.state.urlApiCronJobSetting}tasknames/${name}${lastCompleted ? "?lastCompleted=true" : ""}`
    await HttpAxios.get(urlapi).then(({ data }) => {
      callback(data.data);
    });
  };

  setColumns = () => {
    return [];
  };

  extendRender = () => {
    return "";
  };

  extendBreadcrumb = () => {
    return "";
  };

  extendButtons = () => {
    return "";
  };

  handleSearch = (event) => {
    console.log("Search value is " + event.target.value);
    const key = event.target.name;
    const val = event.target.value;
    this.setLocalState(
      (state) => {
        const filter = { ...state.filterParams, [key]: val };
        return { filterParams: filter };
      }
    );
    this.getItems();
  };

  renderSearch = () => {
    return "";
    // <div style={{ display: 'flex', alignItems: 'center' }}>
    //   <input id="outlined-basic" label="Search" variant="outlined" size="small" style={{ margin: '5px' }} placeholder="Search" className="form-control" maxLength={100} onChange={this.handleSearch} />
    // </div>
  };

  renderSearchArea() { }

  RenderExpandableRowsComponent = (props) => { };

  rederTableStyle() { }

  doSomthingBeforeGetItems = async () => { };

  doSomthingAfterGetItems = async () => { };

  doSomthingAfterSelectChange = () => { };

  renderRowStyles = () => { };

  noDataComponent = () => {
    //default text is "There are no records to display"
  };

  countRecords = () => {
    const items = this.getLocalState().items;
    return items && Array.isArray(items) ? items.length : 0;
  }

  optimisticDataTable = React.memo((props) => {
    return (
      <DataTable
        id="emp"
        title=""
        noHeader={true}
        columns={props.columns}
        data={props.data}
        className="table table-bordered table-striped mb-none"
        pagination={!this.state.paginationDisabled}
        paginationPerPage={20}
        expandableRows={this.state.tableExpandableRows}
        expandableRowsComponent={
          <this.RenderExpandableRowsComponent />
        }
        highlightOnHover={this.state.tableHighlightOnHover}
        customStyles={props.customStyles}
        progressPending={props.loading}
        noDataComponent={this.noDataComponent()}
        persistTableHead={true}
        conditionalRowStyles={props.conditionalRowStyles}
      />
    );
  },(pre, next)=>{
    const preData = JSON.stringify(pre.data);
    const nextData = JSON.stringify(next.data);
    return preData === nextData && pre.loading === next.loading;
  });

  render() {
    const columns = this.setColumns();
    const title = this.state.pageTitle
      ? this.state.pageTitle
      : this.state.pluraltitle != ""
        ? this.state.pluraltitle
        : this.state.title + "s";
    const items = this.getLocalState().items;
    const lastUpdateLabel = this.getLocalState().lastUpdateLabel;
    const customStyles = this.rederTableStyle();
    const conditionalRowStyles = this.renderRowStyles();
    const countRecords = this.countRecords();
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={this.props.navRoute}></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>
                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    {this.extendBreadcrumb()}
                    <li>
                      <span>{title}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <section className="panel">
                <header className="panel-heading">
                  {this.state.hidePanelAction ? (
                    ""
                  ) : (
                      <div className="panel-actions">
                        <a href="#" className="fa fa-caret-down"></a>
                        {this.state.hidePanelClose ? (
                          ""
                        ) : (
                            <a href="#" className="fa fa-times"></a>
                          )}
                      </div>
                    )}
                  {lastUpdateLabel ? (
                    <div className="panel-actions">
                      <span> {lastUpdateLabel + "  "} 
                      <i className="fa fa-info-circle fa-lg text-primary cursor-pointer"
                         data-toggle="tooltip"
                         data-placement="bottom"
                         title="Latest Synced Date Time"></i>
                      </span>
                      {this.state.hidePanelAction ? (
                        ""
                      ) : (
                          <>
                            <a href="#" className="fa fa-caret-down"></a>
                            {this.state.hidePanelClose ? (
                              ""
                            ) : (
                                <a href="#" className="fa fa-times"></a>
                              )}
                          </>
                        )}
                    </div>
                  ) : (
                      ""
                    )}

                  <h2 className="panel-title">
                    {this.state === undefined || items === undefined || countRecords <= 0 ?
                      "Not found!" : countRecords == 1 ? countRecords + " " + this.state.title + " found" : countRecords + " " +
                        (this.state.pluraltitle === ""
                          ? this.state.title + "s"
                          : this.state.pluraltitle) +
                        " found"}
                  </h2>
                </header>
                <div className="panel-body">
                  {this.state.searchFormBasic ? (
                    <div className="form-horizontal">
                      {this.renderSearchArea()}
                    </div>
                  ) : (
                      <div
                        className="row"
                        style={{ paddingBottom: "10px", paddingLeft: "5px" }}
                      >
                        <div className="col-sm-12">
                          <div className="row form-inline">
                            <div className="form-group">
                              {this.state.urladd === null ||
                                this.state.urladd === undefined ||
                                this.state.urladd === "" ? (
                                  ""
                                ) : (
                                  <a
                                    href={this.state.urladd}
                                    className="btn btn-primary"
                                  >
                                    <i className="fa fa-plus"></i>{" "}
                                    {this.state.labeladd
                                      ? this.state.labeladd
                                      : "Add"}
                                  </a>
                                )}
                              {this.extendButtons()}
                            </div>
                            {this.renderSearch()}
                          </div>
                        </div>
                      </div>
                    )}
                  <div className="mt-sm"></div>
                  <this.optimisticDataTable
                    columns={columns}
                    data={items}
                    customStyles={customStyles}
                    conditionalRowStyles={conditionalRowStyles}
                    loading={this.state.loading}
                  />
                  {this.state.alertshow === true ? (
                    <Alert
                      message={this.state.alertmsg}
                      type={this.state.alerttype}
                      show={this.state.alertshow}
                    ></Alert>
                  ) : (
                      ""
                    )}
                </div>
              </section>
            </section>
          </div>
        </section>
        {this.extendRender()}
      </>
    );
  }
}
export default App;
