import React from "react";
import { HttpAxios } from "../../Utilities/Http";
import Select from "react-select";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      controlId: props.controlId,
      urlapilist: props.urlapilist,
      value: props.value,
      label: props.label,
      name: "",
      data: [],
      erroMessage: "",
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all nut types in module and render as checkbox list
    this.getItems();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  getItems = async () => {
    try {
      if (
        this.state.urlapilist == undefined ||
        this.state.urlapilist == null ||
        this.state.urlapilist == ""
      ) {
        return;
      }
      const fetchItem = await HttpAxios.get(this.state.urlapilist);
      const response = fetchItem.data;
      const defaultItem = { _id: "", name: this.state.label };

      if (response && response.result === "OK" && response.data) {
        const parsedItems = [];
        if (response.data) {
          response.data.map((item) => {
            parsedItems.push({
              _id: item._id,
              name: item.name,
              checked:
                this.state.value == null ||
                this.state.value == undefined ||
                this.state.value.indexOf(item._id) == -1
                  ? false
                  : true,
            });
          });
        }
        this.setState({
          data: [defaultItem].concat(parsedItems),
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  handleChangeSelect(event) {
    const valueItem = event;
    this.setState({ value: valueItem.value, label: valueItem.label });
    this.props.handleChangeSelectControl(this.state.controlId, valueItem.value);
  }

  onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "MyDropdown__option--is-selected"
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({
          block: "nearest",
        });
      }
    }, 15);
  };

  render() {
    return (
      <Select
        name={this.state.controlId}
        onChange={this.handleChangeSelect}
        value={{ value: this.state.value, label: this.state.label }}
        className={this.props.className}
        placeholder={this.props.placeholder}
        classNamePrefix={"MyDropdown"}
        onMenuOpen={this.onMenuOpen}
        options={
          this.state.data
            ? this.state.data.map((item, key) => {
                return {
                  value: item._id,
                  label: item.name,
                };
              })
            : ""
        }
      ></Select>
    );
  }
}
export default App;