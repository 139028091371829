import { useAuth, getLoggedUser } from "../context/auth";
const profile = (state, action) => {
    state = getLoggedUser();
    return {
        ...state,
        ...action
    }
}

export default profile
