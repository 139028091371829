import axios from "axios";
import jwt_decode from "jwt-decode";

const jwt = require("jsonwebtoken");
const globalConfig = new window.globalConfig();
const urlRefreshToken = globalConfig.REACT_APP_API_AUTH_URL + "/refreshToken";
const urlUpdateToken = globalConfig.REACT_APP_API_AUTH_URL + "/updateToken";

const buildHeader = () => {
    let headers = {};
    const tokens = localStorage.getItem("tokens");
    if (tokens) {
        const token = jwt_decode(tokens);
        const authToken = token.authToken;
        headers = {
            Authorization: `Bearer ${authToken}`
        }
    }
    return { headers };
}

const buildConfig = (config) => {
    let header = buildHeader();
    let configuration = config ? config : {};
    configuration = { ...configuration, ...header };
    return configuration;
}

const post = (url, data, configuration) => {
    return new Promise((resolve, reject) => {
        axios.post(url, data, configuration)
            .then(async response => {
                if (response.data && response.data.statusCode === 400 && response.data.message === "TokenExpiredError") {
                    await refreshToken();
                    let newConfig = buildConfig(configuration);
                    await post(url, data, newConfig).then(newResource => resolve(newResource)).catch(newErr => reject(newErr));
                }
                else if (response.data && response.data.statusCode === 400 && response.data.message === "TokenOutUpdateError") {
                    await updateToken();
                    let newConfig = buildConfig(configuration);
                    await post(url, data, newConfig).then(newResource => resolve(newResource)).catch(newErr => reject(newErr));
                }
                else if (response.data && response.data.statusCode === 400 && response.data.message === "JsonWebTokenError") {
                    localStorage.clear();
                    window.location.replace("/login");
                }
                else{
                    resolve(response);
                }
            })
            .catch(err => {
                reject(err);
            });
    });
}

const get = (url, configuration) => {
    return new Promise((resolve, reject) => {
        axios.get(url, configuration)
            .then(async response => {
                if (response.data && response.data.statusCode === 400 && response.data.message === "TokenExpiredError") {
                    await refreshToken();
                    let newConfig = buildConfig(configuration);
                    await get(url, newConfig).then(newResource => resolve(newResource)).catch(newErr => reject(newErr));
                }
                else if (response.data && response.data.statusCode === 400 && response.data.message === "TokenOutUpdateError") {
                    await updateToken();
                    let newConfig = buildConfig(configuration);
                    await get(url, newConfig).then(newResource => resolve(newResource)).catch(newErr => reject(newErr));
                }
                else if (response.data && response.data.statusCode === 400 && response.data.message === "JsonWebTokenError") {
                    localStorage.clear();
                    window.location.replace("/login");
                }
                else{
                    resolve(response);
                }
            })
            .catch(err => {
                reject(err);
            });
    });
}

const patch = (url, data, configuration) => {
    return new Promise((resolve, reject) => {
        axios.patch(url, data, configuration)
            .then(async response => {
                if (response.data && response.data.statusCode === 400 && response.data.message === "TokenExpiredError") {
                    await refreshToken();
                    let newConfig = buildConfig(configuration);
                    await patch(url, data, newConfig).then(newResource => resolve(newResource)).catch(newErr => reject(newErr));
                }
                else if (response.data && response.data.statusCode === 400 && response.data.message === "TokenOutUpdateError") {
                    await updateToken();
                    let newConfig = buildConfig(configuration);
                    await patch(url, data, newConfig).then(newResource => resolve(newResource)).catch(newErr => reject(newErr));
                }
                else if (response.data && response.data.statusCode === 400 && response.data.message === "JsonWebTokenError") {
                    localStorage.clear();
                    window.location.replace("/login");
                }
                else{
                    resolve(response);
                }
            })
            .catch(err => {
                reject(err);
            });
    });
};

const del = (url, configuration) => {
    return new Promise((resolve, reject) => {
        axios.delete(url, configuration)
            .then(async response => {
                if (response.data && response.data.statusCode === 400 && response.data.message === "TokenExpiredError") {
                    await refreshToken();
                    let newConfig = buildConfig(configuration);
                    await del(url, newConfig).then(newResource => resolve(newResource)).catch(newErr => reject(newErr));
                }
                else if (response.data && response.data.statusCode === 400 && response.data.message === "TokenOutUpdateError") {
                    await updateToken();
                    let newConfig = buildConfig(configuration);
                    await del(url, newConfig).then(newResource => resolve(newResource)).catch(newErr => reject(newErr));
                }
                else if (response.data && response.data.statusCode === 400 && response.data.message === "JsonWebTokenError") {
                    localStorage.clear();
                    window.location.replace("/login");
                }
                else{
                    resolve(response);
                }
            })
            .catch(err => {
                reject(err);
            });
    });
}

const refreshToken = () => {
    return new Promise(async (resolve, reject) => {
        const tokens = localStorage.getItem("tokens");
        const token = jwt_decode(tokens);
        const refreshToken = token.refreshToken;
        let configuration = buildConfig({
            params: { refreshToken: refreshToken }
        });
        const { data } = await HttpAxios.get(urlRefreshToken, configuration);
        const newToken = jwt.sign(data.data, process.env.REACT_APP_TOKEN_SECRET);
        localStorage.clear();
        localStorage.setItem("tokens", newToken);
        localStorage.setItem("isActive", true);
        resolve();
    })
}

const updateToken = () => {
    return new Promise(async (resolve, reject) => {
        const tokens = localStorage.getItem("tokens");
        const token = jwt_decode(tokens);
        const refreshToken = token.refreshToken;
        let configuration = buildConfig({
            params: { refreshToken: refreshToken }
        });
        debugger
        const { data } = await HttpAxios.get(urlUpdateToken, configuration);
        const newToken = jwt.sign(data.data, process.env.REACT_APP_TOKEN_SECRET);
        localStorage.clear();
        localStorage.setItem("tokens", newToken);
        localStorage.setItem("isActive", true);
        resolve();
    })
}

const HttpAxios = {
    post: (url, data, config) => {
        let configuration = buildConfig(config);
        return post(url, data, configuration);
    },
    get: (url, config) => {
        let configuration = buildConfig(config);
        return get(url, configuration);
    },
    patch: (url, data, config) => {
        let configuration = buildConfig(config);
        return patch(url, data, configuration);
    },
    delete: (url, config) => {
        let configuration = buildConfig(config);
        return del(url, configuration);
    }
};

export { HttpAxios };