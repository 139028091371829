import React from "react";
import DateFormat from "../Utilities/DateFormat";
import Common from "../Utilities/Common";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import Moment from "moment";
import { DateTimeFormat } from "../../global";
//This is variable
const loggedUser = getLoggedUser();
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      labeladd: "Invite",
      title: "Client",
      pluraltitle: "Clients",
      urlapi: GlobalConfig.REACT_APP_API_CLIENT_URL,
      urllist: process.env.REACT_APP_URL_CLIENT_LIST,
      urladd: process.env.REACT_APP_URL_CLIENT_ADD,
      hidePanelAction: true,
    };
  }

  setColumns = () => {
    return [
      {
        name: "Company Name",
        selector: "companyName",
        sortable: true,
        cell: (row) => {
          return loggedUser.referenceUserId === row._id ? (
            <a href={process.env.REACT_APP_URL_MY_PROFILE}>
              {row.companyName
                ? Common.replaceAll(row.companyName, `##`, `, `)
                : ``}
            </a>
          ) : (
            <a href={this.state.urllist + "/" + row._id}>
              {row.companyName
                ? Common.replaceAll(row.companyName, `##`, `, `)
                : ``}
            </a>
          );
        },
      },
      {
        name: "Contact Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
        left: true,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.status === "Active" ? (
            <span className="label label-success">{row.status}</span>
          ) : (
            <span className="label label-default">{row.status}</span>
          );
        },
      },
      {
        name: "Invited Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return Moment(new Date(row.createdDate)).format(
            DateTimeFormat.format2
          );
        },
      },
      {
        name: "",
        center: true,
        cell: (row) => (
          <div>
            {loggedUser.referenceUserId === row._id ? (
              <a
                href={process.env.REACT_APP_URL_MY_PROFILE}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
            ) : (
              <a
                href={this.state.urllist + "/" + row._id}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
            )}
            &nbsp;&nbsp;
            {loggedUser.referenceUserId === row._id ? (
              ""
            ) : (
              <a
                title="Click here to remove"
                href="#"
                className="on-default remove-row"
                data-id={row._id}
                onClick={this.handleDelete.bind(this)}
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
            )}
          </div>
        ),
      },
    ];
  };

  noDataComponent = () => {
    return "Not found! To Invite your Clients to view their Inventory";
  };
}
export default App;
