import React from "react";
import Button from "react-bootstrap/Button";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportExcel = ({
  csvData,
  fileName,
  buttonLabel,
  buttonClassName,  
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  function fitToColumn(data) {
    let widths = initialWidthColumns(data[0]);
    data.map(
      item => {
        const arrObject = Object.values(item);
        arrObject.map((obj, i) => {
          const len = String(obj).length;
          widths[i] = len > widths[i] ? len : widths[i];
        })
      }
    );
    
    return widths.map(width => {
      return {
        wch: width
      }
    });
  }

  function initialWidthColumns(data) {
    const arrObject = Object.keys(data);
    return arrObject.map((item) => {
      return String(item).length;
    })
  }

  const exportToCSV = (csvData, fileName) => {
    // if (csvData && csvData.length > 0) {
    const ws = XLSX.utils.json_to_sheet(csvData);
    ws['!cols'] = fitToColumn(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    // }
    //  else {
    //   console.log("a");
    //   return <Alert message="No data export!" type="info" show="true"></Alert>;
    // }
  };
  if (buttonLabel === "") {
    buttonLabel = "Export excel";
  }
  return csvData && csvData.length > 0 ? (
    <Button
      variant="warning"
      onClick={(e) => exportToCSV(csvData, fileName)}
      className={buttonClassName}
    >
      <i className="fa fa-file-excel-o fa-lg"></i> {buttonLabel}
    </Button>
  ) : (
      ""
    );
};

export default ExportExcel;
