import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser, isActive } from "../../context/auth";
import jwt_decode from "jwt-decode";
import Common from "../Utilities/Common";

var jwt = require("jsonwebtoken");
const GlobalConfig = new window.globalConfig();
const loggedUser = getLoggedUser();

class App extends EditComponent {
  constructor(props) {
    super();
    this.state = {
      sectionTitle: "",
      prefixTitle: "My Profile",
      listtitle: "",
      urllist: "",
      urlapi: GlobalConfig.REACT_APP_API_CLIENT_URL,
      urlsubmitapi: GlobalConfig.REACT_APP_API_CLIENT_URL + "profile/",
      isClientActive: false,
      data: {
        _id: loggedUser.referenceUserId,
        name: "",
        companyName: "",
        companyNameId: "",
        email: "",
        status: "",
        newPassword: "",
        currentPassword: "",
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
      },
    };
  }

  handleInvalid(event) {
    // const value =
    //   event.target.type === "checkbox"
    //     ? event.target.checked
    //     : event.target.value;
    // if (value == null || value == undefined || value.trim() == "") {
    //   event.target.setCustomValidity(
    //     "Please fill out this field. It is required data for creating tree nut tests."
    //   );
    // } else {
    //   event.target.setCustomValidity("");
    // }
  }

  handleInput(event) {
    event.target.setCustomValidity("");
  }

  doSomethingAfterGetItemById = () => {
    const isClientActive = isActive();
    this.setState({ isClientActive: isClientActive }, function callBack() {
      if (
        loggedUser &&
        loggedUser.role == "Clients" &&
        this.state.isClientActive == false
      ) {
        localStorage.setItem("isActive", true);
      }
    });
    if (this.state.data) {
      this.setState((state) => {
        return {
          sectionTitle: ``,
        };
      });
    }
  };

  doSomethingAfterSaved = (resultObject) => {
    //logout
    if (resultObject.isChangedPassword === true) {
      localStorage.clear();
      window.location.href = process.env.REACT_APP_URL_LOGIN;
    } else {
      const tokens = localStorage.getItem("tokens");
      if (tokens) {
        let existingTokens = jwt_decode(tokens);
        existingTokens.name = this.state.data.name;
        const token = jwt.sign(
          existingTokens,
          process.env.REACT_APP_TOKEN_SECRET
        );
        localStorage.setItem("tokens", JSON.stringify(token));
      }
    }
  };

  handleChange(event) {
    var id = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(
      (state) => {
        return { data: { ...state.data, [id]: value } };
      },
      () => {
        const profile = { ...this.props.profile, [id]: value };
        this.props.updateProfile(profile);
      }
    );
  }

  getTitle = () => {
    return "";
  };

  renderForm = () => {
    return (
      <div className="panel-body">
        <h4 className="mb-xlg">Contact Information</h4>
        <fieldset>
          <div className="form-group">
            <label className="col-md-3 control-label" htmlFor="name">
              Contact Name <span className="required">(*)</span>
            </label>
            <div className="col-md-5">
              <input
                autoFocus={true}
                maxLength="150"
                type="text"
                name="name"
                className="form-control"
                placeholder=""
                required
                value={this.state.data.name}
                onChange={this.handleChange}
                onInvalid={this.handleInvalid}
                onInput={this.handleInput}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-md-3 control-label" htmlFor="email">
              Email <span className="required">(*)</span>
            </label>
            <div className="col-md-5">
              <input
                maxLength="50"
                type="email"
                name="email"
                className="form-control"
                placeholder=""
                required
                value={this.state.data.email}
                onChange={this.handleChange}
                onInvalid={this.handleInvalid}
                onInput={this.handleInput}
              />
            </div>
          </div>
        </fieldset>
        <h4 className="mb-xlg">Change Password</h4>
        <fieldset className="mb-xl">
          <div className="form-group">
            <label className="col-md-3 control-label" htmlFor="currentPassword">
              Current Password
            </label>
            <div className="col-md-5">
              <input
                type="password"
                className="form-control"
                name="currentPassword"
                id="currentPassword"
                maxLength={100}
                value={this.state.data.currentPassword}
                onChange={this.handleChange}
              ></input>
            </div>
          </div>
          <div className="form-group">
            <label className="col-md-3 control-label" htmlFor="newPassword">
              New Password
            </label>
            <div className="col-md-5">
              <input
                type="password"
                className="form-control"
                id="newPassword"
                name="newPassword"
                maxLength={100}
                value={this.state.data.newPassword}
                onChange={this.handleChange}
              ></input>
            </div>
          </div>
          <div className="form-group">
            <label
              className="col-md-3 control-label"
              htmlFor="newPasswordRepeat"
            >
              Repeat New Password
            </label>
            <div className="col-md-5">
              <input
                type="password"
                className="form-control"
                id="newPasswordRepeat"
                name="newPasswordRepeat"
                maxLength={100}
                value={this.state.data.newPasswordRepeat}
                onChange={this.handleChange}
              ></input>
            </div>
          </div>
        </fieldset>
      </div>
    );
  };
}
export default App;
