const DateTimeFormat = {
    format1: "MM/DD/YYYY hh:mm:ss A",
    format2: "MM/DD/YYYY hh:mm:ss A",
    format3: "MMDDYYYY-HHmmss",
    format4: "MM/DD/YYYY hh:mm A",
    format5: "YYYY-MM-DD",
    format6: "MM/DD/YYYY",
    firstDateOfMonth1: "MM/01/YYYY",
}

const CronJobSettingStatus = {
    Scheduled: "Scheduled",
    Running: "Running",
    Stopped: "Stopped",
}

const NumberFormat = {
    NcNNNdNN: /\d(?=(\d{3})+\.)/g
}

const accessReportOptions = [
    { label: "Current Inventory Balance", value: "CURRENT_INVENTORY_BALANCE" },
    { label: "Inventory Rollover by Month", value: "INVENTORY_ROLLOVER_BY_MONTH" },
    { label: "Inventory Rollover by Date", value: "INVENTORY_ROLLOVER_BY_DATE" },
    // { label: "Meridian Growers RTT", value: "MERIDIAN_GROWERS_RTT" },
    // { label: "Meridian Grower Summary", value: "MERIDIAN_GROWER_SUMMARY" }
]

exports.CronJobSettingStatus = CronJobSettingStatus;
exports.DateTimeFormat = DateTimeFormat;
exports.NumberFormat = NumberFormat;
exports.AccessReportOptions = accessReportOptions;