import React from "react";
import { getLoggedUser } from "../../context/auth";
import AdminProfile from "../../Containers/Profiles/AdminProfile";
import ClientProfile from "../../Containers/Profiles/ClientProfile";

class App extends React.Component {
  constructor(props) {
    super();
  }

  renderSwitch() {
    const loggedUser = getLoggedUser();
    switch (loggedUser.role) {
      case "Administrators":
        return <AdminProfile></AdminProfile>;
      case "Clients":
        return <ClientProfile></ClientProfile>;
      default:
        return "";
    }
  }

  render() {
    return this.renderSwitch();
  }
}

export default App;
