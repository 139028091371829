import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class DateRangePicker extends React.Component {
    constructor(props) {
        super(props);
        this.onChangeStart = props.onChangeStart.bind(this);
        this.onChangeEnd = props.onChangeEnd.bind(this);
    }

    render = () => {
        return (
            <>
                <div className="h-date-range">
                    <span className="h-input-group-addon h-from"><i className="fa fa-calendar"></i></span>
                    <DatePicker
                        {...this.props}
                        selectsStart
                        className="form-control h-form-from"
                        selected={this.props.selectedStart}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        onChange={this.onChangeStart} />
                    <span className="h-input-group-addon h-to">to</span>
                    <DatePicker
                        {...this.props}
                        selectsEnd
                        className="form-control h-form-to"
                        selected={this.props.selectedEnd}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        minDate={this.props.minStartDate}
                        onChange={this.onChangeEnd} />
                </div>
            </>
        )
    }
}

class DatePickerIcon extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <>
                <div className="date-container">
                <span className="icon-date-picker-front"><i className="fa fa-calendar"></i></span>
                    <DatePicker className="form-control form-control-date-picker-front" {...this.props}/>
                </div>
            </>
        )
    }
}


class DatePickerIconBack extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <>
                <div className="d-flex">
                    <DatePicker className="form-control form-control-date-picker" {...this.props}/>
                    <span className="icon-date-picker"><i className="fa fa-calendar"></i></span>
                </div>
            </>
        )
    }
}

export { DateRangePicker, DatePickerIconBack, DatePickerIcon };