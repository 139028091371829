import React from "react";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import { handleExportPdf } from "../ExportPdf/MeridianGrowersRtt";
import Moment from "moment";
import DataTable from "react-data-table-component";
import { DateTimeFormat } from "../../global";
import Button from "react-bootstrap/Button";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { HttpAxios } from "../../Utilities/Http";
import MultiSelectComponent from "../Base/MultiSelectComponent";

const GlobalConfig = new window.globalConfig();

let loggedUser = null;
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

class App extends ListComponent {
  constructor(props) {
    super(props);
    loggedUser = getLoggedUser();
    this.state = {
      urlapi: ``,
      title: "Meridian Growers RTT",
      pageTitle: "Meridian Growers RTT",
      pluraltitle: "Meridian Growers RTT",
      searchFormBasic: true,
      // hidePanelAction: true,
      // paginationDisabled: true,
      companyId: ``,
      companyName: ``,

      //paging
      loading: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
    };
  }

  async componentDidMount() {
    await this.doSomthingBeforeGetItems();
    this.getItems(this.state.currentPage, this.state.perPage);
  }

  //Region: Get data filter
  doSomthingBeforeGetItems = async () => {
    await this.getCompanyItems();
  };

  async getCompanyItems() {
    try {
      let urlApi =
        GlobalConfig.REACT_APP_API_CONFIG_URL +
        "queryclients/" +
        process.env.REACT_APP_ATTRIBUTE_REFLECT_CUSTOMER;
      if (loggedUser && loggedUser.role === "Clients") {
        urlApi += `/${loggedUser.companyId}`;
      }
      const response = await HttpAxios.get(urlApi);
      const resultObject = response.data;
      if (resultObject && resultObject.result === "OK" && resultObject.data) {
        const parsedItems = [];
        resultObject.data.map((item) => {
          parsedItems.push({
            value: item._id,
            label: item.name,
            _id: item._id,
            name: item.name,
          });
        });
        this.setState({
          companies: parsedItems,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  multiSelectCompanyHandleChange(selected, element) {
    const key = element.name;
    const keyValue = `${element.name}Value`;
    let valueOptions = selected ? selected.value : ``;
    let selectedText = ``;

    if (selected && Array.isArray(selected)) {
      const options = [...selected];
      const hasSelectAll = options.some((item) => {
        return (
          item.value === MultiSelectComponent.defaultProps.allOption.value &&
          item.label === MultiSelectComponent.defaultProps.allOption.label
        );
      });
      if (hasSelectAll) {
        options.shift();
      }
      valueOptions = options.map((item) => item.value).join();
      valueOptions = valueOptions ? valueOptions : "";
      selectedText = options.map((item) => item.label).join(`##`);
    }
    this.setState((state) => {
      return {
        [keyValue]: selected,
        [key]: valueOptions,
        companyName: selectedText,
      };
    });
  }

  getItems = async (page, size = this.state.perPage) => {
    let isValidSearch = true;
    let selectedAccountId =
      this.state.companyId === undefined ||
        this.state.companyId === null ||
        this.state.companyId === ``
        ? `all`
        : this.state.companyId;
    let selectedAccountName =
      this.state.companyName === undefined ||
        this.state.companyName === null ||
        this.state.companyName === ``
        ? `all`
        : this.state.companyName;
    if (loggedUser && loggedUser.role === "Clients") {
      if (selectedAccountId === `all`) {
        selectedAccountId =
          this.state.companies && this.state.companies.length > 0
            ? this.state.companies
              .map((x) => {
                return x._id;
              })
              .join(`,`)
            : ``;
      }
      if (selectedAccountName === `all`) {
        selectedAccountName =
          this.state.companies && this.state.companies.length > 0
            ? this.state.companies
              .map((x) => {
                return x.name;
              })
              .join(`##`)
            : ``;
      }
      //Do not allow search all if login as client
      if (selectedAccountId === `` || selectedAccountName === ``) {
        isValidSearch = false;
      }
    }
    let items = [];
    let totalRows = 0;
    if (isValidSearch === true) {
      let urlApi = `${GlobalConfig.REACT_APP_API_REPORT_MERIDIAN_GROWERS_RTT}${page}/${size}`;
      const params = {
        filterAccountId: selectedAccountId,
        filterAccountName: selectedAccountName,
      };
      const response = await HttpAxios.post(urlApi, params);
      items = response.data.data;
      totalRows = response.data.total;
    }
    this.setState(
      {
        items: items,
        originalItems: items,
      },
      () => {
        this.doSomthingAfterGetItems(totalRows);
      }
    );
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.getItems(page, newPerPage);
    this.setState({ perPage: newPerPage });
  };

  handlePageChange = (page) => {
    this.getItems(page);
    this.setState({ currentPage: page });
  };

  doSomthingAfterGetItems = (totalRows) => {
    this.setState({ totalRows: totalRows, loading: false });
  };

  defaultTable = () => {
    const columns = this.setColumns();
    const customStyles = this.rederTableStyle();
    return (
      <DataTable
        id="emp"
        title=""
        noHeader={true}
        columns={columns}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        paginationServer
        paginationTotalRows={this.state.totalRows}
        paginationDefaultPage={this.state.currentPage}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        pagination={true}
        paginationPerPage={20}
        expandableRows={this.state.tableExpandableRows}
        expandableRowsComponent={<this.RenderExpandableRowsComponent />}
        highlightOnHover={this.state.tableHighlightOnHover}
        customStyles={customStyles}
        progressPending={this.state.loading}
        noDataComponent={this.noDataComponent()}
      />
    );
  };

  defaultItemsLength = () => {
    if (this.state === undefined || this.state.items === undefined) {
      return 0;
    }
    return this.state.totalRows;
  };

  fitToColumn(data) {
    let widths = this.initialWidthColumns(data[0]);
    data.map((item) => {
      const arrObject = Object.values(item);
      arrObject.map((obj, i) => {
        const len = String(obj).length;
        widths[i] = len > widths[i] ? len : widths[i];
      });
    });

    return widths.map((width) => {
      return {
        wch: width,
      };
    });
  }

  initialWidthColumns(data) {
    const arrObject = Object.keys(data);
    return arrObject.map((item) => {
      return String(item).length;
    });
  }

  renderSearchArea() {
    return (
      <>
        <div className="form-group">
          <div className="col-sm-6 col-md-6 col-lg-6">
            <label className="col-sm-2 col-md-2 col-lg-2 control-label">
              Grower
            </label>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <MultiSelectComponent
                name="companyId"
                options={this.state.companies}
                onChange={this.multiSelectCompanyHandleChange.bind(this)}
                allowSelectAll={true}
                isSearchable={false}
                value={this.state.companyIdValue}
              />
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              {this.extendButtons()}
            </div>
          </div>
        </div>
      </>
    );
  }

  exportToCSV = async () => {
    let isValidSearch = true;
    let selectedAccountId =
      this.state.companyId === undefined ||
        this.state.companyId === null ||
        this.state.companyId === ``
        ? `all`
        : this.state.companyId;
    let selectedAccountName =
      this.state.companyName === undefined ||
        this.state.companyName === null ||
        this.state.companyName === ``
        ? `all`
        : this.state.companyName;
    if (loggedUser && loggedUser.role === "Clients") {
      if (selectedAccountId === `all`) {
        selectedAccountId =
          this.state.companies && this.state.companies.length > 0
            ? this.state.companies
              .map((x) => {
                return x._id;
              })
              .join(`,`)
            : ``;
      }
      if (selectedAccountName === `all`) {
        selectedAccountName =
          this.state.companies && this.state.companies.length > 0
            ? this.state.companies
              .map((x) => {
                return x.name;
              })
              .join(`##`)
            : ``;
      }
      //Do not allow search all if login as client
      if (selectedAccountId === `` || selectedAccountName === ``) {
        isValidSearch = false;
      }
    }
    let items = [];
    if (isValidSearch === true) {
      let urlApi = `${GlobalConfig.REACT_APP_API_REPORT_MERIDIAN_GROWERS_RTT
        }${-1}/${0}`;
      const params = {
        filterAccountId: selectedAccountId,
        filterAccountName: selectedAccountName,
      };
      const response = await HttpAxios.post(urlApi, params);
      items = response.data.data;
    }
    if (items && items.length > 0) {
      const parsedItems = items;
      const fileName = `Meridian-Growers-${Moment(new Date()).format(
        DateTimeFormat.format3
      )}`;
      const ws = XLSX.utils.json_to_sheet(parsedItems);
      ws["!cols"] = this.fitToColumn(parsedItems);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      window.showAlert("Infor", "No data export!");
    }
  };

  extendButtons = () => {
    return (
      <>
        <Button
          variant="primary"
          onClick={(e) => this.getItems(1)}
          className="btn btn-primary"
        >
          <i className="fa fa-search fa-lg"></i> Search
        </Button>
        <Button
          variant="warning"
          onClick={(e) => this.exportToCSV()}
          className="btn btn-warning"
        >
          <i className="fa fa-file-excel-o fa-lg"></i> Export
        </Button>
      </>
    );
  };

  setColumns = () => {
    return [
      {
        name: "Status",
        selector: "Status",
        sortable: false,
        center: true,
      },
      {
        name: "Reference ID",
        selector: "ReferenceID",
        sortable: false,
        center: true,
      },
      {
        name: "Account ID",
        selector: "AccountID",
        sortable: false,
        center: true,
      },
      {
        name: "Account Name",
        selector: "AccountName",
        sortable: false,
        left: true,
      },
      {
        name: "Scheduled Start",
        selector: "ScheduledStartDateTime",
        sortable: false,
        center: true,
      },
      {
        name: "Scheduled End",
        selector: "ScheduledEndDateTime",
        sortable: false,
        center: true,
      },
      {
        name: "Lot Code",
        selector: "LotCode",
        sortable: false,
        center: true,
      },
      {
        name: "Product ID",
        selector: "ProductID",
        sortable: false,
        center: true,
      },
      {
        name: "Product Name",
        selector: "ProductName01",
        sortable: false,
        left: true,
      },
      {
        name: "",
        selector: "",
        sortable: false,
        center: true,
        cell: (row) => {
          return (
            <a
              href="#"
              variant="link"
              className="on-default remove-row"
              data-receiptid={row.ReceiptID}
              data-action="export"
              onClick={() => handleExportPdf(row, "export")}
              title="Export PDF"
            >
              Export
            </a>
          );
        },
      },
    ];
  };
}
export default App;
