import React from "react";
import Dashboard from "./Dashboard";
import Header from "../Containers/Profiles/Header";
import Navigation from "./Navigation";
import CurrentInventoryBalance from "./Report/CurrentInventoryBalance";
import InventoryRollover from "./Report/InventoryRolloverByMonth";
import { getLoggedUser } from "../context/auth";

function App(props) {
  const loggedUser = getLoggedUser();

  if(loggedUser.role == "Clients"){
    if(loggedUser.accessReport)
    {
      const accessReport = loggedUser.accessReport.split(",")[0];
      if(accessReport){
        switch(accessReport){
          case "CURRENT_INVENTORY_BALANCE":
            return (<CurrentInventoryBalance></CurrentInventoryBalance>)
          case "INVENTORY_ROLLOVER":
            return (<InventoryRollover></InventoryRollover>)
        }
      }
    }else{
      return (
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={props.navRoute}></Navigation>
          </div>
        </section>
    );
    }
  }
  return (
    <section className="body">
      <Header></Header>
      <div className="inner-wrapper">
        <Navigation navRoute={props.navRoute}></Navigation>
        <Dashboard></Dashboard>
      </div>
    </section>
);
  
}

export default App;
