import React from "react";
import { useAuth, getLoggedUser } from "../context/auth";

function App(props) {
  //This is function
  const { setAuthTokens } = useAuth();

  //This is variable
  const loggedUser = props.profile && props.profile.name && props.profile._id ? props.profile : getLoggedUser();

  function logOut() {
    setAuthTokens();
  }

  return (
    <header className="header">
      <div className="logo-container">
        <a href="../" className="logo">
          <img src="/assets/images/logo_black.png" height="35" alt="PF Grading Portal" />
        </a>
        <div
          className="visible-xs toggle-sidebar-left"
          data-toggle-class="sidebar-left-opened"
          data-target="html"
          data-fire-event="sidebar-left-opened"
        >
          <i className="fa fa-bars" aria-label="Toggle sidebar"></i>
        </div>
      </div>
      <div className="header-right">
        {/* <form action="pages-search-results.html" className="search nav-form">
          <div className="input-group input-search">
            <input
              type="text"
              className="form-control"
              name="q"
              id="q"
              placeholder="Search..."
            ></input>
          </div>
        </form>

        <span className="separator"></span>

        <ul className="notifications">
          <li>
            <a
              href="#"
              className="dropdown-toggle notification-icon"
              data-toggle="dropdown"
            >
              <i className="fa fa-tasks"></i>
              <span className="badge">3</span>
            </a>

            <div className="dropdown-menu notification-menu large">
              <div className="notification-title">
                <span className="pull-right label label-default">3</span>
                Tasks
              </div>

              <div className="content">
                <ul>
                  <li>
                    <p className="clearfix mb-xs">
                      <span className="message pull-left">
                        Generating Sales Report
                      </span>
                      <span className="message pull-right text-dark">60%</span>
                    </p>
                    <div className="progress progress-xs light"></div>
                  </li>

                  <li>
                    <p className="clearfix mb-xs">
                      <span className="message pull-left">
                        Importing Contacts
                      </span>
                      <span className="message pull-right text-dark">98%</span>
                    </p>
                    <div className="progress progress-xs light"></div>
                  </li>

                  <li>
                    <p className="clearfix mb-xs">
                      <span className="message pull-left">
                        Uploading something big
                      </span>
                      <span className="message pull-right text-dark">33%</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <a
              href="#"
              className="dropdown-toggle notification-icon"
              data-toggle="dropdown"
            >
              <i className="fa fa-envelope"></i>
              <span className="badge">4</span>
            </a>

            <div className="dropdown-menu notification-menu">
              <div className="notification-title">
                <span className="pull-right label label-default">230</span>
                Messages
              </div>

              <div className="content">
                <ul>
                  <li>
                    <a href="#" className="clearfix">
                      <figure className="image">
                        <img
                          src="/assets/images/!sample-user.jpg"
                          alt="Joseph Doe Junior"
                          className="img-circle"
                        />
                      </figure>
                      <span className="title">Joseph Doe</span>
                      <span className="message">Lorem ipsum dolor sit.</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="clearfix">
                      <figure className="image">
                        <img
                          src="/assets/images/!sample-user.jpg"
                          alt="Joseph Junior"
                          className="img-circle"
                        />
                      </figure>
                      <span className="title">Joseph Junior</span>
                      <span className="message truncate">
                        Truncated message. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Donec sit amet lacinia
                        orci. Proin vestibulum eget risus non luctus. Nunc
                        cursus lacinia lacinia. Nulla molestie malesuada est ac
                        tincidunt. Quisque eget convallis diam, nec venenatis
                        risus. Vestibulum blandit faucibus est et malesuada. Sed
                        interdum cursus dui nec venenatis. Pellentesque non nisi
                        lobortis, rutrum eros ut, convallis nisi. Sed tellus
                        turpis, dignissim sit amet tristique quis, pretium id
                        est. Sed aliquam diam diam, sit amet faucibus tellus
                        ultricies eu. Aliquam lacinia nibh a metus bibendum, eu
                        commodo eros commodo. Sed commodo molestie elit, a
                        molestie lacus porttitor id. Donec facilisis varius
                        sapien, ac fringilla velit porttitor et. Nam tincidunt
                        gravida dui, sed pharetra odio pharetra nec. Duis
                        consectetur venenatis pharetra. Vestibulum egestas nisi
                        quis elementum elementum.
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="clearfix">
                      <figure className="image">
                        <img
                          src="/assets/images/!sample-user.jpg"
                          alt="Joe Junior"
                          className="img-circle"
                        />
                      </figure>
                      <span className="title">Joe Junior</span>
                      <span className="message">Lorem ipsum dolor sit.</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="clearfix">
                      <figure className="image">
                        <img
                          src="/assets/images/!sample-user.jpg"
                          alt="Joseph Junior"
                          className="img-circle"
                        />
                      </figure>
                      <span className="title">Joseph Junior</span>
                      <span className="message">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec sit amet lacinia orci. Proin vestibulum eget risus
                        non luctus. Nunc cursus lacinia lacinia. Nulla molestie
                        malesuada est ac tincidunt. Quisque eget convallis diam.
                      </span>
                    </a>
                  </li>
                </ul>

                <hr />

                <div className="text-right">
                  <a href="#" className="view-more">
                    View All
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <a
              href="#"
              className="dropdown-toggle notification-icon"
              data-toggle="dropdown"
            >
              <i className="fa fa-bell"></i>
              <span className="badge">3</span>
            </a>

            <div className="dropdown-menu notification-menu">
              <div className="notification-title">
                <span className="pull-right label label-default">3</span>
                Alerts
              </div>

              <div className="content">
                <ul>
                  <li>
                    <a href="#" className="clearfix">
                      <div className="image">
                        <i className="fa fa-thumbs-down bg-danger"></i>
                      </div>
                      <span className="title">Server is Down!</span>
                      <span className="message">Just now</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="clearfix">
                      <div className="image">
                        <i className="fa fa-lock bg-warning"></i>
                      </div>
                      <span className="title">User Locked</span>
                      <span className="message">15 minutes ago</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="clearfix">
                      <div className="image">
                        <i className="fa fa-signal bg-success"></i>
                      </div>
                      <span className="title">Connection Restaured</span>
                      <span className="message">10/10/2014</span>
                    </a>
                  </li>
                </ul>

                <hr />

                <div className="text-right">
                  <a href="#" className="view-more">
                    View All
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul> */}

        <span className="separator"></span>

        <div id="userbox" className="userbox">
          <a href="#" data-toggle="dropdown">
            {loggedUser.avatar == null || loggedUser.avatar == undefined || loggedUser.avatar == "" ? "" :
              <figure className="profile-picture">
                <img
                  src={loggedUser.avatar}
                  alt={loggedUser.name}
                  className="img-circle"
                  data-lock-picture={loggedUser.avatar}
                />
              </figure>}
            <div
              className="profile-info"
              data-lock-name={loggedUser.name}
            >
              <span className="name">{loggedUser.name}</span>
              <span className="role">{loggedUser.role}</span>
              {/* <span className="role">Processor: {props.currentProcessorName}</span> */}
            </div>

            <i className="fa custom-caret"></i>
          </a>

          <div className="dropdown-menu">
            <ul className="list-unstyled">
              <li className="divider"></li>
              <li>
                <a role="menuitem" tabIndex="-1" href="/my-profile">
                  <i className="fa fa-user"></i> My Profile
                </a>
              </li>
              <li>
                <a
                  role="menuitem"
                  tabIndex="-1"
                  onClick={logOut}
                  href="#"
                >
                  <i className="fa fa-power-off"></i> Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
export default App;
