import React from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import FilterSelectControl from "../FormControl/FilterSelectControl";

import { getLoggedUser } from "../../context/auth";
import { DateTimeFormat } from "../../global";
import { HttpAxios } from "../../Utilities/Http";

const GlobalConfig = new window.globalConfig();
let loggedUser = null;

class App extends React.Component {
  constructor(props) {
    super();

    loggedUser = getLoggedUser();
    const now = new Date();
    const start = moment(new Date().setDate(now.getDate() - 30)).format(
      DateTimeFormat.format6
    );
    const end = moment(now).format(DateTimeFormat.format6);

    let clientId = "";
    if (loggedUser.role === "Lab Users" || loggedUser.role === "Clients") {
      clientId = loggedUser.clientId;
    }
    this.state = {
      urlApiClientFilter: GlobalConfig.REACT_APP_API_CLIENT_URL + "getall",
      startDate: start,
      endDate: end,
      filterClient: clientId,
      filterStartDate: start,
      filterEndDate: end,
      data: [],
    };
  }

  async componentDidMount() {
    this.getData();
  }

  getData = async () => {
    let parameters = "start=" + this.state.filterStartDate;
    parameters += "&end=" + this.state.filterEndDate;
    parameters += "&clientId=" + this.state.filterClient;
    const url =
      GlobalConfig.REACT_APP_API_DASHBOARD_URL + "summaryaccess?" + parameters;
    const response = await HttpAxios.get(url);
    const result = response.data;
    this.setState({ data: result.data });
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState(
      {
        [controlId]: value,
      },
      function callBack() {
        this.filterItems();
      }
    );
  };

  handleSearch = (event) => {
    if (event.target) {
      const id = event.target.name;
      console.log("id=" + id);
      console.log("value=" + event.target.value);
      this.setState(
        {
          [id]: event.target.value,
        },
        function callBack() {
          this.filterItems();
        }
      );
    }
  };

  filterItems = () => {
    this.getData();
  };

  getColumns() {
    return [
      {
        name: "Company Name",
        selector: "companyName",
        left: true,
      },
      {
        name: "Last Login",
        selector: "",
        center: true,
        cell: (row) => {
          return moment(row.lastLoginDate).format(DateTimeFormat.format4);
        },
      },
    ];
  }

  render() {
    const columns = this.getColumns();

    return (
      <section className="panel">
        <header className="panel-heading">
          <div className="panel-actions">
            <a href="#" className="fa fa-caret-down"></a>
          </div>
          <h2 className="panel-title">Clients Access Summary</h2>
        </header>
        <div className="panel-body">
          <div className="col-lg-12">
            <div className="row form-inline">
              <div className="col-md-6 col-lg-3">
                <div className="row">
                  {loggedUser.role == "Administrators" ? (
                    <FilterSelectControl
                      className="ml-md"
                      handleChangeSelectControl={this.handleChangeSelectControl}
                      onRef={(ref) => (this.clientControl = ref)}
                      placeholder="Client"
                      label="All Clients"
                      controlId="filterClient"
                      urlapilist={this.state.urlApiClientFilter}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-6 col-lg-6">
                <div className="row">
                  <div
                    className="input-daterange input-group ml-md mt-sm-small"
                    data-plugin-datepicker
                    //style={{ marginLeft: "10px" }}
                  >
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control datepicker-style"
                      name="filterStartDate"
                      defaultValue={this.state.filterStartDate}
                      placeholder="Date From"
                      onChange={this.handleSearch}
                      onFocus={this.handleSearch}
                    ></input>
                    <span className="input-group-addon">to</span>
                    <input
                      type="text"
                      className="form-control datepicker-style"
                      name="filterEndDate"
                      placeholder="Date To"
                      defaultValue={this.state.filterEndDate}
                      onChange={this.handleSearch}
                      onFocus={this.handleSearch}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <hr className="dotted short"></hr>
            <div className="table-responsive">
              <DataTable
                noHeader={true}
                columns={columns}
                data={this.state.data}
                className="table table-striped mb-none"
                pagination={true}
                paginationPerPage={10}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default App;