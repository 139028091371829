import React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";

class App extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div
        style={{
          backgroundImage:
            "url(/assets/images/PF-Grading-Background-login@2x.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Helmet>
          <title>{"Site is down for maintenance | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <div className="inner-wrapper">
            <section role="main" className="content-body">
              <section className="body-error error-inside">
                <div className="center-error">
                  <div className="row" style={{ color: "white" }}>
                    <div className="col-md-12">
                      <div className="main-error mb-xlg">
                        <h1 className="text-white text-left text-semibold">Site is temporarily unavailable</h1>
                        <p className="error-explanation text-left">Scheduled maintenance is currently in progress. Please check back soon.</p>
                        <p className="error-explanation text-left">We apologize for any inconvenience.</p>
                        <h3 className="text-left text-semibold m-none"><a style={{color: "white"}} href="mailto:dtuong@paritycorp.com">dtuong@paritycorp.com</a></h3>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </section>
      </div>
    );
  }
}
export default App;
