function numberWithCommas(n) {
  var parts = n.toString().split(".");
  const result =
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "");
  return result;
}

function numberWithCommasExt(n) {
  const value =
    n == null || n == undefined || n == isNaN(n) ? 0 : parseFloat(n).toFixed(1);
  var parts = value.toString().split(".");
  const result =
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "");
  return result;
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
function replaceAll(str, match, replacement) {
  return str.replace(new RegExp(escapeRegExp(match), "g"), () => replacement);
}

exports.numberWithCommas = numberWithCommas;
exports.numberWithCommasExt = numberWithCommasExt;
exports.replaceAll = replaceAll;
