import React from "react";
import EditComponent from "../Base/EditComponent";
import MultiSelectComponent from "../Base/MultiSelectComponent";
import Moment from "moment";
import DataTable from "react-data-table-component";
import { DatePickerIcon } from "../Base/DatePickerComponent";
import { Button, Modal } from "react-bootstrap";
import { DateTimeFormat } from "../../global";
import { HttpAxios } from "../../Utilities/Http";

const GlobalConfig = new window.globalConfig();
const typeCronJob = {
  Hourly: "Hourly",
  Daily: "Daily"
};
const typeCronJobOptions = [{
  value: typeCronJob.Hourly,
  label: typeCronJob.Hourly
},
{
  value: typeCronJob.Daily,
  label: typeCronJob.Daily
}];


class App extends EditComponent {
  constructor(props) {
    super();
    this.state = {
      listtitle: "Scheduled Task",
      formSummaryTitle: "Scheduled Task History",
      urllist: process.env.REACT_APP_URL_CRON_JOB_SETTING,
      urlapi: GlobalConfig.REACT_APP_API_CRON_JOB_SETTING,
      urlapiTaskNames: GlobalConfig.REACT_APP_API_CRON_JOB_SETTING + "tasknames/unregistered",
      urlSummaryApi: GlobalConfig.REACT_APP_API_CRON_JOB_HISTORY,
      data: {
        _id: props.match.params.id,
        taskName: "",
        cronStyle: "",
        typeCronJob: "",
        startAt: "",
        blocked: false,
        description: null,
        startDateTime: null,
        transactionDateFrom: "",
      },
      summaryFilterParams: {
        taskName: props.match.params.id
      },
      taskNameOptions: null,
      taskNameValue: null,
      typeCronJobValue: null,
      startInfo: null,
      linkToReport: "",

      //paging
      loading: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
    };
  }

  getTitle = () => {
    return this.state.data.taskName;
  };

  async getTaskNameItems() {

    const fetchData = await HttpAxios.get(this.state.urlapiTaskNames);
    const data = fetchData.data;
    const options = MultiSelectComponent.ConvertToOptions(data.data);
    this.setStartInfo(typeCronJob.Hourly, this.state.data.startTime);
    this.setState(state => {
      return {
        taskNameOptions: options,
        taskNameValue: { value: this.state.data.taskName, label: this.state.data.taskName },
      };
    });
  }

  doSomethingAfterGetItemById = async () => {
    await this.getTaskNameItems();
    this.setState(state => {
      const data = { ...state.data, transactionDateFromDisplay: state.data.transactionDateFrom };
      return {
        data: data,
        typeCronJobValue: { value: state.data.typeCronJob, label: state.data.typeCronJob }
      }
    });
  };

  startTimeChangeHandler(e) {
    if (!e.target.value) {
      e.target.value = "00:00";
    }
    this.setStartInfo(this.state.data.typeCronJob, e.target.value);
    this.handleChange(e);
  }

  typeCronJobChangeHandler(selected, e) {
    this.setStartInfo(selected.value, this.state.data.startTime);
    this.multiSelectHandleChange(selected, e);
  }
  setStartInfo(type, startAt) {
    let datetime = new Date(`${Moment(new Date()).format(DateTimeFormat.format5).toString()} ${startAt}:00`);
    const now = new Date();

    if (datetime < now && type === typeCronJob.Hourly) {
      datetime = new Date(datetime.getTime() + 86400000);
    } else if (type === typeCronJob.Daily) {
      datetime = new Date(datetime.getTime() + 86400000);
    }

    this.setState(state => ({
      startInfo: Moment(datetime).format(DateTimeFormat.format4),
      data: { ...state.data, startDateTime: datetime }
    }))
  }

  handleShow = (row) => {
    const message = JSON.parse(row.message);
    this.setState({
      show: true,
      historyDetail: message,
      titleModal: `${row.taskName} [${Moment(row.startedDate).format(DateTimeFormat.format4)}]`
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  columnTableDetail = [
    {
      name: "Store Procedure/Command",
      selector: "storeProcedure",
      wrap: true,
      grow: 2,
      sortable: false,
    },
    {
      name: "Status",
      selector: "status",
      grow: 0,
      center: true,
      sortable: false,
      cell: row => {
        return <><span style={{ fontSize: "0.85em" }} className={row.status === "Failed" ? "label label-danger" : "label label-success"}>{row.status}</span></>;
      },
    },
    {
      name: "Records",
      grow: 0,
      selector: "records",
      center: true,
      sortable: false,
    },
    {
      name: "Result",
      sortable: false,
      grow: 2,
      cell: row => {
        return typeof row.result === 'object' ? "" : row.result
      }
    },
  ];

  transDateChangeHandler(selected, name) {
    this.setState(state => {
      const data = { ...state.data, [name]: Moment(selected).format("YYYY-MM-01"), transactionDateFromDisplay: selected };
      return { data }
    });
  }

  extendRender = () => {
    return (
      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{this.state.titleModal ? this.state.titleModal : "Detail"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            title=""
            noHeader={true}
            columns={this.columnTableDetail}
            data={this.state.historyDetail}
            className="table table-striped mb-none mt-none"
            pagination={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={this.handleClose}>
            <span className="fa fa-times-circle fa-lg"></span> Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  getSummaryColumns = () => {
    return [
      {
        name: "Started Date",
        selector: "startedDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return <>
            <span>
              {Moment(row.startedDate).format(DateTimeFormat.format2)}
            </span>
          </>
        }
      },
      {
        name: "Completed Date",
        selector: "completedDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <>
            <span>{row.completedDate ? Moment(row.completedDate).format(DateTimeFormat.format2) : ""}</span>
          </>
        },
      },
      {
        name: "Result Status",
        selector: "status",
        sortable: false,
        center: true,
        grow: 1,
        cell: (row) => {
          return <><span style={{ fontSize: "0.85em" }} className={row.status === "Failed" ? "label label-danger" : "label label-success"}>{row.status}</span></>;
        }
      },
      {
        name: "",
        center: true,
        grow: 0,
        cell: row => {
          return (
            <a title="View result" onClick={e => { this.handleShow(row); }} className="btn btn-default" style={{ minWidth: "0", border: 0 }}><i className="fa fa-info-circle fa-lg text-primary"></i></a>
          );
        }
      },

    ];
  }

  renderForm = () => {
    const transactionDateFrom = this.state.data.transactionDateFromDisplay;
    return (
      <div className="panel-body">

        <div className="form-group">
          <label className="col-sm-3 control-label">
            Report
          </label>
          <div className="col-sm-5">
            <MultiSelectComponent
              isDisabled={true}
              name="taskName"
              isSearchable={false}
              options={this.state.taskNameOptions}
              onChange={this.multiSelectHandleChange.bind(this)}
              allowSelectAll={false}
              value={this.state.taskNameValue}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-3 control-label">
            Type
          </label>
          <div className="col-sm-2">
            <MultiSelectComponent
              name="typeCronJob"
              isSearchable={false}
              options={typeCronJobOptions}
              onChange={this.typeCronJobChangeHandler.bind(this)}
              allowSelectAll={false}
              value={this.state.typeCronJobValue}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">Step Size</label>
          <div className="col-md-2">
            <input
              min={0}
              max={100}
              name="stepSize"
              value={this.state.data.stepSize}
              onChange={this.handleChange.bind(this)}
              className="form-control" type="number" />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">Start At</label>
          <div className="col-md-2">
            <input
              name="startTime"
              value={this.state.data.startTime}
              onChange={this.startTimeChangeHandler.bind(this)}
              className="form-control" type="time" />
          </div>
          <label className="control-label pl-md"><span className="fa fa-info-circle"></span>&nbsp; Next run: {this.state.startInfo}</label>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">Link to Report</label>
          <div className="col-md-5">
            <input
              maxLength="200"
              name="linkToReport"
              value={this.state.data.linkToReport}
              onChange={this.handleChange.bind(this)}
              className="form-control" type="text" />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-3 control-label">
            Description
          </label>
          <div className="col-sm-5">
            <textarea
              rows={4}
              name="description"
              className="form-control"
              placeholder="Description"
              value={this.state.data.description || ""}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-3 control-label">
            Transaction Date From
          </label>
          <div className="col-sm-2">
            <DatePickerIcon
              selected={transactionDateFrom ? new Date(Moment.utc(transactionDateFrom).year(), Moment.utc(transactionDateFrom).month(), 1) : new Date()}
              onChange={(selectedDate) => { this.transDateChangeHandler(selectedDate, "transactionDateFrom") }}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </div>
        </div>

      </div>
    );
  };
}
export default App;
