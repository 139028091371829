import React from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

class App extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            showNavigation: true,
        }
    }

    getGallery = () => {
        const gallery = this.props.gallery ? this.props.gallery : [];
        let result = gallery.map(item => {
            return {
                original: item,
                thumbnail: item,
                size:{
                    height: "calc(70vh - 80px)"
                }
            }
        })
        
        return result;
    }


    render = () => {
        const gallery = this.getGallery();
        return (<>
            <ImageGallery 
                items={gallery}
                showFullscreenButton={false}
                showPlayButton={false}
                showNav={this.state.showNavigation}
            />
        </>);
    }
}

export default App;