const verifyPermission = (compareValue) => {
    return (accessReport) => {
        if (!accessReport) return false;
        const reports = accessReport.split(",");
        return reports.some(item => item === compareValue);
    }
}

const rules = {
    "Clients": {
        dynamic: {
            "current-inventory-balance": verifyPermission("CURRENT_INVENTORY_BALANCE"),
            "inventory-rollover": verifyPermission("INVENTORY_ROLLOVER"),
            "meridian-growers-rtt": verifyPermission("MERIDIAN_GROWERS_RTT"),
            "meridian-grower-summary": verifyPermission("MERIDIAN_GROWER_SUMMARY"),
        }
    },
    "Administrators": {
        static: [
            "clients",
            "current-inventory-balance",
            "inventory-rollover",
            "meridian-growers-rtt",
            "scheduled-tasks",
            "scheduled-task-history",
            "settings",
            "mail-templates",
            "administrators",
        ]
    }
};

export default rules;