import { createContext, useContext } from "react";
import jwt_decode from "jwt-decode";
import { components } from "react-select";

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

//Query user info from token
export const getLoggedUser = () => {
  //This field only has value if role in (processor, lab user)
  let processorId = "";
  let processorCertificateNo = "";
  let processorName = "";
  let processorAddress = "";
  let labUserAddress = "";
  let isInvalidProcessor = false;
  let referenceUserId = "";
  let currentUserId = "";
  let currentUserName = "";
  let currentUserRole = "";
  let currentUserAvatar = "";
  let isDisabled = false;
  let companyName = "";
  let companyId = "";
  let accessReport = "";
  const tokens = localStorage.getItem("tokens");
  if (
    tokens != undefined &&
    tokens.toString() != "undefined" &&
    tokens.toString() != ""
  ) {
    const token = jwt_decode(tokens);
    const existingTokens = token;
    if (existingTokens) {
      processorId = existingTokens.processorId;

      processorAddress = existingTokens.processorAddress;
      processorName = existingTokens.processorName;
      labUserAddress = existingTokens.labUserAddress;
      isInvalidProcessor = existingTokens.isInvalidProcessor;
      processorCertificateNo = existingTokens.processorCertificateNo;

      referenceUserId = existingTokens.referenceUserId;
      currentUserId = existingTokens._id;
      currentUserName = existingTokens.name;
      currentUserRole = existingTokens.role;
      currentUserAvatar = existingTokens.avatar;
      isDisabled = existingTokens.isDisabled;
      companyName = existingTokens.clientName;
      companyId = existingTokens.companyId;
      accessReport = existingTokens.accessReport;
    }
  }
  const a = {
    processorId: processorId,
    processorAddress: processorAddress,
    processorName: processorName,
    processorCertificateNo: processorCertificateNo,
    labUserAddress: labUserAddress,
    isInvalidProcessor: isInvalidProcessor,
    referenceUserId: referenceUserId,
    _id: currentUserId,
    name: currentUserName,
    role: currentUserRole,
    avatar: currentUserAvatar,
    isDisabled: isDisabled,
    companyName: companyName,
    companyId: companyId,
    accessReport: accessReport,
  };
  return a;
};

export const isActive = () => {
  const x = localStorage.getItem("isActive");
  if (x) {
    return x === true || x === "true";
  }
  return false;
};
