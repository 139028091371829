import React from "react";
import ListComponent from "../Base/ListComponent";
import Moment from "moment";
import { DateTimeFormat, CronJobSettingStatus } from "../../global";
import { HttpAxios } from "../../Utilities/Http";

const GlobalConfig = new window.globalConfig();

const RenderButton = (props) => {
  if (props.href) {
    return (<a
      {...props}
      title={props.title}
      href={props.href}
      style={{ minWidth: "0", border: 0 }}
      target={props.target}
      className="btn btn-sm btn-default on-default remove-row text-primary mr-none ml-none"
    >
      <i className={`fa ${props.icon} fa-lg`}></i>
    </a>);
  }
  return (<a
    {...props}
    title={props.title}
    onClick={props.onClick}
    style={{ minWidth: "0", border: 0 }}
    target={props.target}
    className="btn btn-sm btn-default on-default remove-row text-primary mr-none ml-none"
  >
    <i className={`fa ${props.icon} fa-lg`}></i>
  </a>);
}

class App extends ListComponent {
  constructor(props) {
    super();

    this.state = {
      urlapi: GlobalConfig.REACT_APP_API_CRON_JOB_SETTING,
      urllist: process.env.REACT_APP_URL_CRON_JOB_SETTING,
      urladd: process.env.REACT_APP_URL_CRON_JOB_SETTING + "/add",
      urlapidelete: GlobalConfig.REACT_APP_API_CRON_JOB_SETTING,
      title: "Scheduled Task",
      pageTitle: "Scheduled Tasks",
      pluraltitle: "Scheduled Tasks",
      labeladd: "Add",
      width: 0,
      height: 0,
      hidePanelAction: true,
    }
  }

  doSomthingBeforeGetItems = async () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  async blockHandler(id, status) {
    const urlapi = `${this.state.urlapi}block/${id}`;
    const data = { status: status };
    await this.updateScheduleHandler(urlapi, data);
  }

  async runHandler(id) {
    const urlapi = `${this.state.urlapi}run/${id}`;
    await this.updateScheduleHandler(urlapi);
  }

  async updateScheduleHandler(urlapi, data) {
    try {
      const response = await HttpAxios.patch(urlapi, data || {});
      const resultObject = response.data;

      if (resultObject && resultObject.result === "OK") {
        this.setState({
          alertshow: false,
        });
        window.showAlert("", "Save successful!", "");
        this.getItems();
      }
      //If failed, show error
      else {
        this.setState({
          alertshow: false,
        });
        this.setState({
          alertmsg: resultObject.message,
          alerttype: "error",
          alertshow: true,
        });
      }
    } catch (err) {
      this.setState({
        alertshow: false,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  }

  getNextTimeRun(setting) {
    let time = 60 * 60 * 1000;
    if (setting.typeCronJob === "Daily") {
      time = time * 24;
    }
    let from = new Date(setting.startDateTime);
    let to = new Date(from.setTime(from.getTime() + (time * setting.stepSize * setting.timeRun)));
    return to;
  }

  columnsFull = () => {
    return [
      {
        name: "Task",
        selector: "taskName",
        sortable: true,
        grow: 2,
        cell: (row) => (
          <a href={this.state.urllist + "/" + row._id}>
            {row.taskName}
          </a>
        ),
      },
      {
        name: "Report",
        selector: "description",
        grow: 1,
        cell: row => {
          return row.linkToReport ? (<a target="blank" href={row.linkToReport}><i className="fa fa-external-link" aria-hidden="true"></i> Show Report</a>) : "";
        },
      },
      {
        name: "Step Size",
        selector: "stepSize",
        sortable: false,
        right: true,
        grow: 0,
      },
      {
        name: "Type",
        selector: "typeCronJob",
        sortable: false,
        grow: 0,
      },
      {
        name: "Start At",
        selector: "cronStyle",
        sortable: false,
        center: true,
        wrap: true,
        cell: (row) => {
          const nextTime = this.getNextTimeRun(row);
          const next = new Date(nextTime);
          const startDateTime = new Date(row.startDateTime);
          let result = next;
          if (next < startDateTime)
            result = startDateTime

          return Moment(result).format(DateTimeFormat.format4);;
        },
      },
      {
        name: "Status",
        selector: "status",
        sortable: false,
        center: true,
        grow: 0,
        cell: (row) => {
          return (
            <><span style={{ fontSize: "0.85em" }} className={row.status === CronJobSettingStatus.Stopped ? "label label-danger" : "label label-success"}>{row.status}</span></>
          )
        },
      }

    ];
  }

  actionColumnSm = {
    name: "",
    allowOverflow: true,
    center: true,
    grow: 0,
    cell: (row) => (
      <div className="btn-group">
        <a className="btn btn-default dropdown-toggle" style={{ minWidth: "0", border: 0 }} data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></a>
        <ul className="dropdown-menu dropdown-menu-right" role="menu">
          <li>{row.status === CronJobSettingStatus.Stopped ? (
            <a name={row.status} onClick={() => { this.blockHandler(row._id, CronJobSettingStatus.Scheduled) }}>Start Task</a>
          ) : (
            <a name={row.status} onClick={() => { this.blockHandler(row._id, CronJobSettingStatus.Stopped) }}>Stop Task</a>
          )}</li>
          {
            row.status !== CronJobSettingStatus.Stopped ?
              (
                <li><a onClick={() => { this.runHandler(row._id) }}>Run Intermediately</a></li>
              ) : ""
          }

          <li><a href={`${process.env.REACT_APP_URL_CRON_JOB_HISTORY}/${row._id}`}>View History</a></li>
          <li><a href={this.state.urllist + "/" + row._id}>Edit</a></li>
          <li><a data-id={row._id} onClick={this.handleDelete.bind(this)}>Delete</a></li>
        </ul>
      </div>
    ),
  }

  actionColumnMd = {
    name: "",
    center: true,
    wrap: true,
    show: false,
    grow: 1,
    cell: (row) => (
      <div>
        {
          row.status === CronJobSettingStatus.Stopped ? (
            <RenderButton name="Running" title="Start" onClick={() => { this.blockHandler(row._id, CronJobSettingStatus.Scheduled) }} icon="fa-play" />
          ) : (
            <RenderButton name="Stopped" title="Stop" onClick={() => { this.blockHandler(row._id, CronJobSettingStatus.Stopped) }} icon="fa-stop" />
          )
        }
        {
          row.status !== CronJobSettingStatus.Stopped ?
            (
              <RenderButton title="Run immediately" data-id={row._id} onClick={() => { this.runHandler(row._id) }} icon="fa-refresh" />
            ) : ""
        }

        <RenderButton title="View history" href={`${process.env.REACT_APP_URL_CRON_JOB_HISTORY}/${row._id}`} icon="fa-history" />

        <RenderButton title="Edit" href={this.state.urllist + "/" + row._id} icon="fa-pencil-square-o" />
        <RenderButton data-id={row._id} title="Delete" onClick={this.handleDelete.bind(this)} icon="fa-trash-o" />
      </div>
    ),
  }



  // rederTableStyle() {
  //   return {
  //     rows: {
  //       style: {
  //         paddingTop: "0.4em",
  //         paddingBottom: "0.4em",
  //       },
  //     },
  //     table: {
  //       style: {
  //         paddingBottom: "3em",
  //       },
  //     }
  //   }
  // }

  setColumns = () => {
    let columns = this.columnsFull();
    if (this.state.width <= 0)
      columns.push(this.actionColumnSm);
    else
      columns.push(this.actionColumnMd);
    return columns;
  }
}
export default App;
