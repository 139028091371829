import React, { useEffect, useState } from "react";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import MultiSelectComponent from "../Base/MultiSelectComponent";

import { getLoggedUser } from "../../context/auth";
import { Helmet } from "react-helmet";
import { HttpAxios } from "../../Utilities/Http";

const loggedUser = getLoggedUser();

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      prefixTitle: "",
      sectionTitle: null,
      labelButtonSave: "Save",
      title: "",
      listtitle: "",
      urllist: "",
      urlapi: "",
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      imageFieldName: "",
      imageFieldExtension: "",
      imageMaxSize: 0,
      data: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  async componentDidMount() {
    this.inititalComponent();
  }

  getTitle = () => {
    return "";
  };

  handleChange(event) {
    var id = event.target.name;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    this.setState(state => {
      return { data: { ...state.data, [id]: value } }
    });
  }

  handleImageChange(files) {
    //files
    //   {
    //     "name": "logo_react.jpg",
    //     "type": "image/jpeg",
    //     "size": "57 kB",
    //     "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRg...",
    //     "file": {}
    //   }
    // ]
    if (files) {
      if (files.type == "image/jpeg" || files.type == "image/png") {
        if (parseInt(files.size.split(" ")[0]) > this.state.imageMaxSize) {
          window.showAlert(
            "Error",
            "Does not allow image over " + this.state.imageMaxSize + " KB. Please upload another.",
            "error"
          );
          return;
        }
        const arrTemp = files.name.split(".");
        const fileExtension = arrTemp[arrTemp.length - 1];
        this.setState({
          data: Object.assign({}, this.state.data, {
            [this.state.imageFieldName]: files.base64,
            [this.state.imageFieldExtension]: fileExtension,
          }),
        });
      } else {
        window.showAlert("Error", "Please choose PNG or JPG", "error");
      }
    } else {
      this.setState({
        data: Object.assign({}, this.state.data, {
          [this.state.imageFieldName]: "",
        }),
      });
    }
  }

  handResetForm = () => { };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.checkSomethingBeforeSave() == false) {
      return;
    }
    window.showAlert("Info", "Processing...", "Info");
    try {
      const response = await HttpAxios.post(this.state.urlapi, this.state.data);
      const resultObject = response.data;

      //If created successful
      if (resultObject && resultObject.result === "OK") {
        //reset form
        this.handResetForm();
        this.doSomethingAfterSaved(resultObject.data);
        window.showAlert("", "Save successful!", "");
      }
      //If failed, show error
      else {
        window.showAlert("Error", resultObject.message, "Error");
      }
    } catch (err) {
      window.showAlert("Error", err.message, "Error");
    }
  };

  checkSomethingBeforeSave = () => {
    return true;
  }

  multiSelectHandleChange(selected, element) {
    const key = element.name;
    const keyValue = `${element.name}Value`;
    let valueOptions = selected.value;

    if (selected && Array.isArray(selected)) {
      const options = [...selected];
      const hasSelectAll = options.some((item) => {
        return (
          item.value === MultiSelectComponent.defaultProps.allOption.value &&
          item.label === MultiSelectComponent.defaultProps.allOption.label
        );
      });
      if (hasSelectAll) {
        options.shift();
      }
      valueOptions = options.map((item) => item.value).join();
      valueOptions = valueOptions ? valueOptions : [];
    }
    this.setState(state => {
      const data = { ...state.data, [key]: valueOptions };
      return {
        data: data,
        [keyValue]: selected,
      };
    })
  }

  getPrefixTitle = () => {
    return this.state.prefixTitle == null || this.state.prefixTitle == undefined ? "Create new " : this.state.prefixTitle;
  };

  render() {
    const title = this.getPrefixTitle() + this.state.title;
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={this.props.navRoute}></Navigation>
            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{this.getPrefixTitle() + this.state.title}</h2>
                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                    {this.extendBreadcrumb()}
                    <li>
                      <span>{title}</span>
                    </li>
                  </ol>
                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <div className="row">
                <div className="col-md-12">
                  <form
                    onSubmit={this.handleSubmit}
                    id="form"
                    className="form-horizontal"
                  >
                    <section className="panel">
                      <header className="panel-heading">
                        <h2 className="panel-title">
                          {this.state.sectionTitle == undefined || this.state.sectionTitle == null
                            ? "Basic Information"
                            : this.state.sectionTitle}</h2>
                      </header>
                      {this.renderForm()}
                      <footer className="panel-footer">
                        <div className="row">
                          <div className="col-sm-9 col-sm-offset-3">
                            <button type="submit" className="btn btn-success" style={{ marginLeft: "0px" }} disabled={this.state.submitDisabled}>
                              <i className="fa fa-save fa-lg"></i> {this.state.labelButtonSave == null ? "Save" : this.state.labelButtonSave}
                            </button>
                            <a
                              href={this.state.urllist}
                              title="Back to list"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-backward fa-lg"></i> Back
                          </a>
                          </div>
                        </div>
                      </footer>
                    </section>
                  </form>
                  {this.state.alertshow === true ? (
                    <Alert
                      message={this.state.alertmsg}
                      type={this.state.alerttype}
                      show={this.state.alertshow}
                    ></Alert>
                  ) : (
                      ""
                    )}
                </div>
              </div>
            </section>
          </div>
        </section>
      </>
    );
  }

  renderForm = () => {
    return "";
  };

  extendBreadcrumb = () => {
    return "";
  };

  extendButtons = () => {
    return "";
  };

  setTitle = () => {
    return this.state.data.name;
  };

  doSomethingAfterSaved = (data) => { };

  inititalComponent() { };
}
export default App;
