import React from "react";

const ClassName = {
  "1": ["nav-active"],
  "2": ["nav-expanded", "nav-active"],
}
class App extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const activeRoute = this.props.navRoute;
    if (!activeRoute) return;
    let children = this.myRef.current.children;
    let arrChildren = Array.from(children)
    const className = ClassName[activeRoute.length];
    arrChildren.map(item => {
      activeRoute.map((route, i) => {
        if (item.getAttribute("name") === route) {
          item.classList.toggle(className[i]);
        }
      })
    });
  }

  navExpandHandler(e) {
    const element = e.currentTarget;
    element.classList.toggle("nav-expanded");
  }

  render() {
    return (
      <ul ref={this.myRef} className="nav nav-main">
        <li name="dashboard">
          <a title="Dashboard" href="/">
            <i className="fa fa-tachometer" aria-hidden="true"></i>
            <span>Dashboard</span>
          </a>
        </li>
        <li name="clients">
          <a title="Clients" href={process.env.REACT_APP_URL_CLIENT_LIST}>
            <i className="fa fa-users" aria-hidden="true"></i>
            <span>Clients</span>
          </a>
        </li>
        <li name="report" className="nav-parent" onClick={this.navExpandHandler}>
          <a title="Reports">
            <i className="fa fa-list" aria-hidden="true"></i>
            <span>Reports</span>
          </a>
          <ul className="nav nav-children">
            <li name="current-inventory-balance" className="nav-expanded">
              <a href={process.env.REACT_APP_URL_REPORT_INVENTORY_BALANCE_URL} title="Current Inventory Balance">
                <span>Current Inventory Balance</span>
              </a>
            </li>
            <li name="inventory-rollover" className="nav-expanded">
              <a href={process.env.REACT_APP_URL_REPORT_INVENTORY_ROLLOVER_BY_MONTH_URL} title="Inventory Rollover by Month">
                <span>Inventory Rollover by Month</span>
              </a>
            </li>
            <li name="inventory-rollover" className="nav-expanded">
              <a href={process.env.REACT_APP_URL_REPORT_INVENTORY_ROLLOVER_URL} title="Inventory Rollover by Date">
                <span>Inventory Rollover by Date</span>
              </a>
            </li>
            {/* <li name="meridian-growers-rtt" className="nav-expanded">
              <a href={process.env.REACT_APP_URL_REPORT_MERIDIAN_GROWERS_RTT} title="Meridian Growers RTT">
                <span>Meridian Growers RTT</span>
              </a>
            </li>
            <li name="meridian-grower-summary" className="nav-expanded">
              <a href={process.env.REACT_APP_URL_REPORT_MERIDIAN_GROWER_SUMMARY} title="Meridian Grower Summary">
                <span>Meridian Grower Summary</span>
              </a>
            </li> */}
          </ul>
        </li>
        <li name="cronjob-setting">
          <a title="Scheduled Tasks" href={process.env.REACT_APP_URL_CRON_JOB_SETTING}>
            <i className="fa fa-clock-o" aria-hidden="true"></i>
            <span>Scheduled Tasks</span>
          </a>
        </li>
        <li name="cronjob-history">
          <a title="Scheduled Task History" href={process.env.REACT_APP_URL_CRON_JOB_HISTORY}>
            <i className="fa fa-history" aria-hidden="true"></i>
            <span>Scheduled Task History</span>
          </a>
        </li>
        <li name="settings">
          <a title="Settings" href={process.env.REACT_APP_URL_CUSTOMER_SETTINGS}>
            <i className="fa fa-cog" aria-hidden="true"></i>
            <span>Settings</span>
          </a>
        </li>
        <li name="mail-template">
          <a title="Mail Templates" href={process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST}>
            <i className="fa fa-cogfa fa-envelope" aria-hidden="true"></i>
            <span>Mail Templates</span>
          </a>
        </li>
        <li name="administrators">
          <a title="Administrators" href={process.env.REACT_APP_URL_ADMINISTRATOR_LIST}>
            <i className="fa fa-users" aria-hidden="true"></i>
            <span>Administrators</span>
          </a>
        </li>
      </ul>
    );
  }

  // render() {
  //   return (<NavigationComponent setting={setting} />);
  // }
}
export default App;
