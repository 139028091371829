import React from "react";
import Header from "../../Containers/Profiles/Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import DataTable from "react-data-table-component";
import MultiSelectComponent from "../Base/MultiSelectComponent";
import { Helmet } from "react-helmet";
import { HttpAxios } from "../../Utilities/Http";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      prefixTitle: "",
      sectionTitle: null,
      title: "",
      listtitle: "",
      urllist: "",
      urlapi: "",
      urlsubmitapi: "",
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      imageFieldName: "",
      imageFieldExtension: "",
      imageMaxSize: 0,
      data: {},
      formSummaryTitle: "",
      summaryData: [],

      //paging
      loading: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  async componentDidMount() {
    await this.doSomethingBeforeGetItemById();
    this.getItemById(this.state.data._id);
    if (this.state.urlSummaryApi) {
      this.getSummaryItems(this.state.currentPage);
    }
  }

  getItemById = async (id) => {
    this.setState({
      alertmsg: "Query data...",
      alerttype: "info",
      alertshow: true,
    });
    try {
      const fetchItem = await HttpAxios.get(this.state.urlapi + id);
      const item = fetchItem.data;
      if (item && item.result === "OK" && item.data) {
        this.setState(
          {
            data: item.data,
            alertshow: false,
          },
          () => {
            this.doSomethingAfterGetItemById();
          }
        );
      } else {
        this.setState({
          alertshow: false,
        });
        this.setState({
          alertmsg: item.message,
          alerttype: "error",
          alertshow: true,
        });
      }
    } catch (err) {
      this.setState({
        alertshow: false,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  getSummaryItems = async (page, size = this.state.perPage) => {
    const stateTimeout = setTimeout(() => {
      this.setState({ loading: true });
    }, 200);
    await HttpAxios.get(this.state.urlSummaryApi + `${page}/${size}`, {
      params: this.state.summaryFilterParams ?? {},
    }).then(({ data }) => {
      this.setState(
        {
          summaryData: data.data,
        },
        () => {
          this.doSomthingAfterGetItems(data);
        }
      );
      if (this.state.loading) {
        this.setState({ loading: false });
      } else {
        clearTimeout(stateTimeout);
      }
    });
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.getSummaryItems(page, newPerPage);
    this.setState({ perPage: newPerPage });
  };

  handlePageChange = (page) => {
    //This help resolve issue click on last page.
    if (page * this.state.perPage > this.state.totalRows) {
      page = page - 1;
    }
    this.getSummaryItems(page);
    this.setState({ currentPage: page });
  };

  doSomthingAfterGetItems = (data) => {
    this.setState({ totalRows: data.total, loading: false });
  };

  handleChange(event) {
    var id = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState((state) => {
      return { data: { ...state.data, [id]: value } };
    });
  }

  handleImageChange(files) {
    //files
    //   {
    //     "name": "logo_react.jpg",
    //     "type": "image/jpeg",
    //     "size": "57 kB",
    //     "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRg...",
    //     "file": {}
    //   }
    // ]
    if (files) {
      if (files.type === "image/jpeg" || files.type === "image/png") {
        if (parseInt(files.size.split(" ")[0]) > this.state.imageMaxSize) {
          window.showAlert(
            "Error",
            "Does not allow image over 1MB. Please upload another.",
            "error"
          );
          return;
        }
        const arrTemp = files.name.split(".");
        const fileExtension = arrTemp[arrTemp.length - 1];
        this.setState({
          data: Object.assign({}, this.state.data, {
            [this.state.imageFieldName]: files.base64,
            [this.state.imageFieldExtension]: fileExtension,
          }),
        });
      } else {
        window.showAlert("Error", "Please choose PNG or JPG", "error");
      }
    } else {
      this.setState({
        data: Object.assign({}, this.state.data, {
          [this.state.imageFieldName]: "",
        }),
      });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.checkSomethingBeforeSave() == false) {
      return;
    }
    this.setState({
      alertmsg: "Processing...",
      alerttype: "info",
      alertshow: true,
    });

    try {
      const api =
        this.state.urlsubmitapi === null ||
        this.state.urlsubmitapi === undefined ||
        this.state.urlsubmitapi === ""
          ? this.state.urlapi
          : this.state.urlsubmitapi;
      console.log("api===" + api);
      const response = await HttpAxios.patch(
        api + this.state.data._id,
        this.state.data
      );

      const resultObject = response.data;

      //If updated successful
      if (resultObject && resultObject.result === "OK") {
        this.setState({
          alertshow: false,
        });
        window.showAlert("", "Save successful!", "");

        this.doSomethingAfterSaved(resultObject);
      }
      //If failed, show error
      else {
        this.setState({
          alertshow: false,
        });
        this.setState({
          alertmsg: resultObject.message,
          alerttype: "error",
          alertshow: true,
        });
      }
    } catch (err) {
      this.setState({
        alertshow: false,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  multiSelectHandleChange(selected, element) {
    const key = element.name;
    const keyValue = `${element.name}Value`;
    let valueOptions = selected ? selected.value : ``;

    if (selected && Array.isArray(selected)) {
      const options = [...selected];
      const hasSelectAll = options.some((item) => {
        return (
          item.value === MultiSelectComponent.defaultProps.allOption.value &&
          item.label === MultiSelectComponent.defaultProps.allOption.label
        );
      });
      if (hasSelectAll) {
        options.shift();
      }
      valueOptions = options.map((item) => item.value).join();
      valueOptions = valueOptions ? valueOptions : "";
    }
    this.setState((state) => {
      const data = { ...state.data, [key]: valueOptions };
      return {
        data: data,
        [keyValue]: selected,
      };
    });
  }

  checkSomethingBeforeSave = () => {
    return true;
  };

  getPrefixTitle = () => {
    return this.state.prefixTitle == null || this.state.prefixTitle == undefined
      ? "Edit - "
      : this.state.prefixTitle;
  };

  render() {
    const title = this.getPrefixTitle() + this.getTitle();
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={this.props.navRoute}></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>
                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    {this.state.urllist == "" || this.state.listtitle == "" ? (
                      ""
                    ) : (
                      <li>
                        <a href={this.state.urllist}>{this.state.listtitle}</a>
                      </li>
                    )}
                    {this.extendBreadcrumb()}
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>
                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <div className="row">
                <div className="col-md-12">
                  <form
                    onSubmit={this.handleSubmit}
                    id="form"
                    className="form-horizontal"
                  >
                    <section className="panel">
                      {this.state.sectionTitle === `` ? (
                        ``
                      ) : (
                        <header className="panel-heading">
                          <h2 className="panel-title">
                            {this.state.sectionTitle === undefined ||
                            this.state.sectionTitle == null
                              ? "Basic Information"
                              : this.state.sectionTitle}
                          </h2>
                        </header>
                      )}
                      {this.renderForm()}
                      <footer className="panel-footer">
                        <div className="row">
                          <div className="col-sm-9 col-sm-offset-3">
                            <button
                              type="submit"
                              className="btn btn-success"
                              style={{ marginLeft: "0px" }}
                            >
                              <i className="fa fa-save fa-lg"></i> Save
                            </button>
                            {this.state.urllist === null ||
                            this.state.urllist === undefined ||
                            this.state.urllist === "" ? (
                              ""
                            ) : (
                              <a
                                href={this.state.urllist}
                                title="Back to list"
                                className="btn btn-primary"
                              >
                                <i className="fa fa-backward fa-lg"></i> Back
                              </a>
                            )}
                            {this.extendButtons()}
                          </div>
                        </div>
                      </footer>
                    </section>
                  </form>
                  {this.state.alertshow === true ? (
                    <Alert
                      message={this.state.alertmsg}
                      type={this.state.alerttype}
                      show={this.state.alertshow}
                    ></Alert>
                  ) : (
                    ""
                  )}

                  {this.state.formSummaryTitle ? (
                    <section className="panel">
                      <header className="panel-heading">
                        <h2 className="panel-title">
                          {this.state.formSummaryTitle}
                        </h2>
                      </header>

                      {this.renderFormSummary()}
                    </section>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </section>
          </div>
        </section>
        {this.extendRender()}
      </>
    );
  }
  renderFormSummary = () => {
    const columns = this.getSummaryColumns();
    return (
      <div className="panel-body">
        <div className="row">
          <div className="col-md-12">
            <DataTable
              id="emp"
              title=""
              noHeader={true}
              columns={columns}
              data={this.state.summaryData}
              className="table table-bordered table-striped mb-none"
              progressPending={this.state.loading}
              persistTableHead={true}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              paginationDefaultPage={this.state.currentPage}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              pagination={true}
              paginationPerPage={20}
            />
          </div>
        </div>
      </div>
    );
  };

  getSummaryColumns = () => {};

  renderForm = () => {
    return "";
  };

  extendBreadcrumb = () => {
    return "";
  };

  extendButtons = () => {
    return "";
  };

  getTitle = () => {
    return "";
  };

  doSomethingBeforeGetItemById = async () => {};

  doSomethingAfterGetItemById = () => {};

  doSomethingAfterSaved = () => {};

  extendRender = () => {};
}
export default App;
