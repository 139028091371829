import React from "react";
import ListComponent from "../../Base/ListComponent";
import Moment from "moment";
import DataTable from "react-data-table-component";
import MultiSelectComponent from "../../Base/MultiSelectComponent";

import { HttpAxios } from "../../../Utilities/Http";
import { Button, Modal } from "react-bootstrap";
import { DateTimeFormat } from "../../../global";

const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    const statusOptions = [
      { value: "Successful", label: "Successful" },
      { value: "Failed", label: "Failed" },
    ];
    this.state = {
      urlapi: GlobalConfig.REACT_APP_API_CRON_JOB_HISTORY,
      urllist: process.env.REACT_APP_URL_CRON_JOB_HISTORY,
      urlParentDetail: process.env.REACT_APP_URL_CRON_JOB_SETTING,
      urlapiTaskNames:
        GlobalConfig.REACT_APP_API_CRON_JOB_SETTING + "tasknames",
      title: "Scheduled Task History",
      titleModal: "",
      pluraltitle: "Scheduled Task History",
      tableExpandableRows: false,
      show: false,
      historyDetail: null,
      runTimeLog: "",
      tableHighlightOnHover: false,
      taskNameOptions: null,
      taskNameValue: null,
      hidePanelAction: true,
      filterParams: {
        taskName: null,
        status: null,
      },
      statusOptions: statusOptions,
      statusValue: null,

      //paging
      loading: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
    };
  }

  renderSearch = () => {
    return (
      <>
        <div className="col-sm-4 pl-sm">
          <MultiSelectComponent
            name="taskName"
            placeholder={"Select Report..."}
            isSearchable={false}
            options={this.state.taskNameOptions}
            onChange={this.multiSelectHandleChange.bind(this)}
            allowSelectAll={true}
            value={this.state.taskNameValue}
          />
        </div>
        <div className="col-sm-2 pl-sm">
          <MultiSelectComponent
            name="status"
            placeholder={"Select Status..."}
            isSearchable={false}
            options={this.state.statusOptions}
            onChange={this.multiSelectHandleChange.bind(this)}
            allowSelectAll={true}
            value={this.state.statusValue}
          />
        </div>
      </>
    );
  };

  async getTaskNameItems() {
    const fetchData = await HttpAxios.get(this.state.urlapiTaskNames);
    const data = fetchData.data;
    const options = MultiSelectComponent.ConvertToOptions(data.data, {
      value: "taskId",
      label: "taskName",
    });
    let filterParams = null;
    if (options) {
      const params = this.props.match.params.id;
      let currentOptions = null;
      if (params) {
        const task = data.data.find((item) => params === item.taskId);
        if (task) {
          const option = { value: task.taskId, label: task.taskName };
          currentOptions =
            options.length === 1
              ? [MultiSelectComponent.defaultProps.allOption, option]
              : option;
          filterParams = { taskName: currentOptions.value };
        }
      }

      this.setState((state) => {
        const filter = { ...state.filterParams, ...filterParams };
        return {
          taskNameOptions: options,
          taskNameValue: currentOptions,
          filterParams: filter,
        };
      });
    }
  }

  doSomthingBeforeGetItems = async () => {
    await this.getTaskNameItems();
  };

  doSomthingAfterSelectChange = () => {
    this.getItems(this.state.currentPage, this.state.perPage);
  };

  columnsFull = () => {
    return [
      {
        name: "Task",
        selector: "taskName",
        sortable: true,
        grow: 2,
        cell: (row) => {
          return (
            <>
              <span>
                <a
                  href={this.state.urlParentDetail + "/" + row.taskId}
                  title={row.taskName}
                >
                  {row.taskName}
                </a>
              </span>
            </>
          );
        },
      },
      {
        name: "Started Date",
        selector: "startedDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return (
            <>
              <span>
                {Moment(row.startedDate).format(DateTimeFormat.format2)}
              </span>
            </>
          );
        },
      },
      {
        name: "Completed Date",
        selector: "completedDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return (
            <>
              <span>
                {row.completedDate
                  ? Moment(row.completedDate).format(DateTimeFormat.format2)
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        name: "Result Status",
        selector: "status",
        sortable: false,
        center: true,
        grow: 1,
        cell: (row) => {
          return (
            <>
              <span
                style={{ fontSize: "0.85em" }}
                className={
                  row.status === "Failed"
                    ? "label label-danger"
                    : "label label-success"
                }
              >
                {row.status}
              </span>
            </>
          );
        },
      },
      {
        name: "",
        center: true,
        grow: 0,
        cell: (row) => {
          return (
            <a
              title="View result"
              onClick={(e) => {
                this.handleShow(row);
              }}
              className="btn btn-default"
              style={{ minWidth: "0", border: 0 }}
            >
              <i className="fa fa-info-circle fa-lg text-primary"></i>
            </a>
          );
        },
      },
    ];
  };

  setColumns = () => {
    return this.columnsFull();
  };

  handleShow = (row) => {
    const message = JSON.parse(row.message);
    this.setState({
      show: true,
      historyDetail: message,
      runTimeLog: this.buildLog(row.runTimeLog),
      titleModal: `${row.taskName} [${Moment(row.startedDate).format(
        DateTimeFormat.format4
      )}]`,
    });
  };

  buildLog(log) {
    let result = "";
    if (log) {
      const logJson = JSON.parse(log);
      logJson.map((log) => {
        result = `${result}\n${log.content}`;
      });
    }
    return result;
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  columnTableDetail = [
    {
      name: "Store Procedure/Command",
      selector: "storeProcedure",
      wrap: true,
      grow: 2,
      sortable: false,
    },
    {
      name: "Status",
      selector: "status",
      grow: 0,
      center: true,
      sortable: false,
      cell: (row) => {
        return (
          <>
            <span
              style={{ fontSize: "0.85em" }}
              className={
                row.status === "Failed"
                  ? "label label-danger"
                  : "label label-success"
              }
            >
              {row.status}
            </span>
          </>
        );
      },
    },
    {
      name: "Records",
      grow: 0,
      selector: "records",
      center: true,
      sortable: false,
    },
    {
      name: "Result",
      sortable: false,
      grow: 2,
      cell: (row) => {
        return typeof row.result === "object" ? "" : row.result;
      },
    },
  ];

  extendRender = () => {
    return (
      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            {this.state.titleModal ? this.state.titleModal : "Detail"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            title=""
            noHeader={true}
            columns={this.columnTableDetail}
            data={this.state.historyDetail}
            className="table table-striped mb-none mt-none"
            pagination={false}
          />
          {this.state.runTimeLog ? (
            <>
              <hr class="dotted"></hr>
              <label className="text-dark">Log</label>
              <div className="form-group">
                <textarea
                  rows="10"
                  className="form-control"
                  value={this.state.runTimeLog}
                  readOnly={true}
                ></textarea>
              </div>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={this.handleClose}>
            <span className="fa fa-times-circle fa-lg"></span> Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  async componentDidMount() {
    await this.doSomthingBeforeGetItems();
    this.getItems(this.state.currentPage, this.state.perPage);
  }

  getItems = async (page, size = this.state.perPage) => {
    const stateTimeout = setTimeout(() => {
      this.setState({ loading: true });
    }, 200);
    await HttpAxios.get(this.state.urlapi + `${page}/${size}`, {
      params: this.state.filterParams ?? {},
    }).then(({ data }) => {
      this.setState(
        {
          items: data.data,
          originalItems: data.data,
        },
        () => {
          this.doSomthingAfterGetItems(data);
        }
      );
      if (this.state.loading) {
        this.setState({ loading: false });
        clearTimeout(stateTimeout);
      } else {
        clearTimeout(stateTimeout);
      }
    });
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.getItems(page, newPerPage);
    this.setState({ perPage: newPerPage });
  };

  handlePageChange = (page) => {
    //This help resolve issue click on last page.
    if (page * this.state.perPage > this.state.totalRows) {
      page = page - 1;
    }
    this.getItems(page);
    this.setState({ currentPage: page });
  };

  doSomthingAfterGetItems = (data) => {
    this.setState({ totalRows: data.total, loading: false });
  };

  defaultTable = () => {
    const columns = this.setColumns();
    const customStyles = this.rederTableStyle();
    return (
      <DataTable
        id="emp"
        title=""
        noHeader={true}
        columns={columns}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        paginationServer
        paginationTotalRows={this.state.totalRows}
        paginationDefaultPage={this.state.currentPage}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        pagination={true}
        paginationPerPage={20}
        expandableRows={this.state.tableExpandableRows}
        expandableRowsComponent={<this.RenderExpandableRowsComponent />}
        highlightOnHover={this.state.tableHighlightOnHover}
        customStyles={customStyles}
        progressPending={this.state.loading}
        noDataComponent={this.noDataComponent()}
      />
    );
  };
}
export default App;
