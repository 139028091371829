import React, { useEffect, useState } from "react";
import { getLoggedUser } from "../context/auth";
const ClassName = {
  "1": ["nav-active"],
  "2": ["nav-expanded", "nav-active"],
}
let loggedUser = null;
let accessReport = null;

class App extends React.Component {
  constructor(props) {
    super();
    this.myRef = React.createRef();
    loggedUser = getLoggedUser();
    accessReport = loggedUser.accessReport ? loggedUser.accessReport.split(",") : null;
  }

  navExpandHandler(e) {
    const element = e.currentTarget;
    element.classList.toggle("nav-expanded");
  }

  componentDidMount() {
    const activeRoute = this.props.navRoute;
    if (!activeRoute) return;
    let children = this.myRef.current.children;
    let arrChildren = Array.from(children)
    const className = ClassName[activeRoute.length];
    arrChildren.map(item => {
      activeRoute.map((route, i) => {
        if (item.getAttribute("name") === route) {
          item.classList.toggle(className[i]);
        }
      })
    });
  }


  render() {
    return (
      <ul ref={this.myRef} className="nav nav-main">
        <li name="dashboard">
          <a href="/" title="Dashboard">
            <i className="fa fa-tachometer" aria-hidden="true"></i>
            <span>Dashboard</span>
          </a>
        </li>
        {
          accessReport ? (
            <>
              <li name="report" className="nav-parent" onClick={this.navExpandHandler}>
                <a title="Reports">
                  <i className="fa fa-list" aria-hidden="true"></i>
                  <span>Reports</span>
                </a>
                <ul className="nav nav-children">
                  {
                    accessReport.map(report => {
                      switch (report) {
                        case "CURRENT_INVENTORY_BALANCE":
                          return (
                            <li name="current-inventory-balance">
                              <a href={process.env.REACT_APP_URL_REPORT_INVENTORY_BALANCE_URL} title="Current Inventory Balance">
                                <span>Current Inventory Balance</span>
                              </a>
                            </li>
                          );
                        case "INVENTORY_ROLLOVER_BY_MONTH":
                          return (
                            <li name="inventory-rollover-by-month" className="nav-expanded">
                              <a href={process.env.REACT_APP_URL_REPORT_INVENTORY_ROLLOVER_BY_MONTH_URL} title="Inventory Rollover by Month">
                                <span>Inventory Rollover by Month</span>
                              </a>
                            </li>
                          );
                        case "INVENTORY_ROLLOVER_BY_DATE":
                          return (
                            <li name="inventory-rollover-by-date" className="nav-expanded">
                              <a href={process.env.REACT_APP_URL_REPORT_INVENTORY_ROLLOVER_URL} title="Inventory Rollover by Date">
                                <span>Inventory Rollover by Date</span>
                              </a>
                            </li>
                          );

                        // case "MERIDIAN_GROWERS_RTT":
                        //   return (
                        //     <li name="meridian-growers-rtt" className="nav-expanded">
                        //       <a href={process.env.REACT_APP_URL_REPORT_MERIDIAN_GROWERS_RTT} title="Meridian Growers RTT">
                        //         <span>Meridian Growers RTT</span>
                        //       </a>
                        //     </li>
                        //   );

                        // case "MERIDIAN_GROWER_SUMMARY":
                        //   return (
                        //     <li name="meridian-growers-rtt" className="nav-expanded">
                        //       <a href={process.env.REACT_APP_URL_REPORT_MERIDIAN_GROWER_SUMMARY} title="Meridian Grower Summary">
                        //         <span>Meridian Grower Summary</span>
                        //       </a>
                        //     </li>
                        //   );
                      }
                    })
                  }

                </ul>
              </li>
            </>
          ) : ""
        }
      </ul>
    );
  }
}
export default App;
