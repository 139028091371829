import { connect } from 'react-redux'
import Header from '../../Components/Header'

const mapStateToProps = state => {
    return {
        profile: state.profile
    }
}

export default connect(mapStateToProps)(Header)
