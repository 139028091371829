import React from "react";
import ListComponent from "../Base/ListComponentCustomState";
import ExportExcel from "../Utilities/ExportExcel";
import moment from "moment";
import MultiSelectComponent from "../Base/MultiSelectComponent";

import { DateRangePicker } from "../Base/DatePickerComponent";
import { DateTimeFormat, NumberFormat } from "../../global";
import { getLoggedUser } from "../../context/auth";
import { HttpAxios } from "../../Utilities/Http"
const GlobalConfig = new window.globalConfig();

//Region: Handle select filter change
const ReportName = "PSP_SEL_IC4260S_ICInventoryRollover";
const ListTag = ["Attr1", "Attr2", "Grand"];
const ProductTotalTag = "Product Total";
let loggedUser = null;

class App extends ListComponent {
  constructor(props) {
    super(props);
    loggedUser = getLoggedUser();
    console.log(loggedUser);

    this.state = {
      urlapi: GlobalConfig.REACT_APP_API_REPORT_INVENTORY_ROLLOVER_URL,
      urlapiFacility: GlobalConfig.REACT_APP_API_COMMON_FACILITY,
      urlapiAtributeProduct: GlobalConfig.REACT_APP_API_COMMON_PRODUCT_ATTRIBUTE + `${loggedUser._id}`,
      urlapiAtributeProductValue: GlobalConfig.REACT_APP_API_COMMON_PRODUCT_ATTRIBUTE + "values/" + `${loggedUser._id}`,
      urlApiCronJobSetting: GlobalConfig.REACT_APP_API_CRON_JOB_SETTING,
      title: "Product",
      pageTitle: "Inventory Rollover by Date",
      pluraltitle: "Products",
      searchFormBasic: true,
      hidePanelAction: true,
      paginationDisabled: true,
      filterParams: {},
    }
  }

  //Region: Handle select filter change
  //Region: Get data filter
  //Region: Export handler
  //Region: Business

  getOptions(selected) {
    let options = selected?.map(item => {
      if (item.value !== "*")
        return item.value;
    })
    return options?.join();
  }

  doSomthingAfterSelectChange = () => {
    this.getItems();
  };


  attributeFilterChange(selected, element) {
    const key = element.name;
    const keyValue = `${element.name}Value`;
    let valueOptions = selected.value;

    const productAttr1ValueOptions = this.filterProductAttributeValues(valueOptions);
    
    this.setLocalState(state => {
      const data = { ...state.filterParams, [key]: valueOptions, productAttribute1V: this.getOptions([productAttr1ValueOptions[0]]) };
      return {
        filterParams: data,
        [keyValue]: selected,
        productAttr1ValueOptions,
        productAttribute1VValue: productAttr1ValueOptions ? productAttr1ValueOptions[0] : null
      };
    })
    this.getItems();
  }

  handleDatePickerChange = (selectedDate, key) => {
    this.setLocalState(state => {
      const filterParams = { ...state.filterParams, [key]: moment(selectedDate).format("YYYYMMDD") };
      return { filterParams, [key]: selectedDate }
    });
    this.setState({ isRerender: true }, this.getItems);
  };

  //End region: Handle select filter change

  //Region: Get data filter
  doSomthingBeforeGetItems = async () => {
    await this.getDefaultDateRange();
    await this.getFacilityItems();
    await this.getProductAttributeValueItems();
    await this.getProductAttributeItems();
  };

  getDefaultDateRange = async () => {
    let now = new Date();
    let filterEndDate = new Date(now);
    let filterStartDate = new Date(now).setDate(now.getDate() - 30);
    this.setLocalState(state => {
      const filterParams = { ...state.filterParams, filterStartDate: moment(filterStartDate).format("YYYYMMDD"), filterEndDate: moment(filterEndDate).format("YYYYMMDD") };
      return { filterParams, filterStartDate, filterEndDate }
    });
  }

  async getFacilityItems() {
    await HttpAxios.get(this.state.urlapiFacility).then(async ({ data }) => {
      const options = MultiSelectComponent.ConvertToOptions(data.data, { value: "ICFacilitySK", label: "FacilityID" });
      this.setLocalState(state => {
        return {
          optionFacilities: options,
          facilitiesValue: options ? [MultiSelectComponent.defaultProps.allOption, ...options] : null,
          filterParams: options ? { ...state.filterParams, ...{ facilities: this.getOptions(options) } } : null,
        };
      });
    });
  }

  async getProductAttributeValueItems() {
    await HttpAxios.get(this.state.urlapiAtributeProductValue).then(async ({ data }) => {
      if (!data.data) {
        return;
      }
      const proAttributeValueOriginal = data.data;
      this.setLocalState({ proAttributeValueOriginal });
    });
  }


  async getProductAttributeItems() {
    await HttpAxios.get(this.state.urlapiAtributeProduct).then(async ({ data }) => {
      if (!data.data) {
        return;
      }

      const productAttr1Options = MultiSelectComponent.ConvertToOptions(data.data.productAttr1, { value: "AttributeID", label: "AttributeDisplayID" });
      const productAttr2Options = MultiSelectComponent.ConvertToOptions(data.data.productAttr2, { value: "AttributeID", label: "AttributeDisplayID" });
      const productAttr1ValueOptions = this.filterProductAttributeValues(productAttr1Options[0].value);

      this.setLocalState(state => {
        const filter1 = productAttr1Options ? { productAttribute1: this.getOptions([productAttr1Options[0]]) } : null;
        const filter2 = productAttr2Options ? { productAttribute2: this.getOptions([productAttr2Options[0]]) } : null;
        const filter3 = productAttr1ValueOptions ? { productAttribute1V: this.getOptions([productAttr1ValueOptions[0]]) } : null;
        return {
          filterParams: { ...state.filterParams, ...filter1, ...filter2, ...filter3 },
          productAttr1ValueOptions: productAttr1ValueOptions,
          productAttribute1VValue: productAttr1ValueOptions ? productAttr1ValueOptions[0] : null,
          optionAttributeProduct1: productAttr1Options,
          productAttribute1Value: productAttr1Options ? productAttr1Options[0] : null,
          optionAttributeProducts2: productAttr2Options,
          productAttribute2Value: productAttr2Options ? productAttr2Options[0] : null,
        };
      });
    });
  }

  filterProductAttributeValues(clue) {
    const attributeValues = this.getLocalState().proAttributeValueOriginal;
    const options = attributeValues.filter(value => {
      return value.AttributeID === clue;
    });
    return MultiSelectComponent.ConvertToOptions(options, { value: "AttributeValueDisplayID", label: "AttributeValueDisplayID" });
  }

  filterInputHandler(e) {
    e.preventDefault();
    const key = e.target.name;
    const value = e.target.value;
    this.setLocalState(state => {
      return {
        filterParams: { ...state.filterParams, [key]: value }
      }
    });
  }

  filterHandler() {
    this.getItems();
  }

  //End region: Get data filter
  countRecords = () => {
    const items = this.getLocalState().items;
    if(!(items && Array.isArray(items))) return 0;
    const result = items.filter(item => item.Tag === ProductTotalTag);
    return result.length;
  }
  getTotalLabel = (row) => {
    const localState = this.getLocalState();
    let label = `${row.ProductAttribute} Total:`;
    switch (row.Tag) {
      case "Attr1":
        label = `${localState.productAttribute1Value.label} ${label}`
        break;
      case "Attr2":
        label = `${localState.productAttribute2Value.label} ${label}`
        break;
      default:
        break;
    };
    return label;
  }
  renderRowStyles = () => {
    return [
      {
        when: row => row.Tag === "Attr1",
        style: {
          backgroundColor: 'rgba(0,111,171)',
          color: 'white',
        }
      },
      {
        when: row => row.Tag === "Attr2",
        style: {
          backgroundColor: 'rgba(186,199,225)',
          // color: 'white',
        }
      }
      ,
      {
        when: row => row.Tag === "Grand",
        style: {
          backgroundColor: 'rgba(254,219,174)',
          // color: 'white',
        }
      }
    ];
  }

  setColumns = () => {
    return [
      {
        name: (
          <table>
            <tbody>
              <tr style={{ width: "100%" }}>
                <td className="pb-md">&nbsp;</td>
              </tr>
              <tr style={{ width: "100%" }}>
                <td className="pl-md pr-md pt-md pb-md text-center">Product ID</td>
              </tr>
              <tr style={{ width: "100%" }}>
                <td className="input-group pb-md">
                  <input style={{ zIndex: 0 }} onChange={this.filterInputHandler.bind(this)} defaultValue={this.getLocalState().filterParams ? this.getLocalState().filterParams.productID : null} name="productID" maxLength="50" placeholder="Filter..." type="text" className="form-control" />
                  <span className="input-group-btn">
                    <a style={{ minWidth: "0" }} className="btn btn-info" onClick={this.filterHandler.bind(this)}><i className="fa fa-check" aria-hidden="true"></i></a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        ),
        grow: 1,
        selector: "ProductID",
        sortable: false,
        left: true,
      },
      {
        name: (
          <table>
            <tbody>
              <tr style={{ width: "100%" }}>
                <td className="pb-md">&nbsp;</td>
              </tr>
              <tr style={{ width: "100%" }}>
                <td className="pl-md pr-md pt-md pb-md text-center">Product Name</td>
              </tr>
              <tr style={{ width: "100%" }}>
                <td className="input-group pb-md">
                  <input style={{ zIndex: 0 }} onChange={this.filterInputHandler.bind(this)} defaultValue={this.getLocalState().filterParams ? this.getLocalState().filterParams.productName : null} name="productName" maxLength="50" placeholder="Filter..." type="text" className="form-control" />
                  <span className="input-group-btn">
                    <a style={{ minWidth: "0" }} className="btn btn-info" onClick={this.filterHandler.bind(this)}><i className="fa fa-check" aria-hidden="true"></i></a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        ),
        selector: "ProductName01",
        sortable: false,
        left: true,
        grow: 1,
        cell: row => {
          if (this.getLocalState().filterParams && !this.getLocalState().filterParams.showLots && (ListTag.includes(row.Tag))) {
            return (
              <div className="cell-total font-weight-bold">
                {row.Tag === "Grand" ? `Grand Total:` : this.getTotalLabel(row)}
              </div>
            )
          }
          return row.ProductName01;
        }
      },
      {
        name: (
          <table>
            <tbody>
              <tr style={{ width: "100%" }}>
                <td className="pb-md">&nbsp;</td>
              </tr>
              <tr style={{ width: "100%" }}>
                <td className="pl-md pr-md pt-md pb-md text-center">Lot ID</td>
              </tr>
              <tr style={{ width: "100%" }}>
                <td className="input-group pb-md">
                  <input style={{ zIndex: 0 }} onChange={this.filterInputHandler.bind(this)} defaultValue={this.getLocalState().filterParams ? this.getLocalState().filterParams.lotID : null} name="lotID" maxLength="50" placeholder="Filter..." type="text" className="form-control" />
                  <span className="input-group-btn">
                    <a style={{ minWidth: "0" }} className="btn btn-info" onClick={this.filterHandler.bind(this)}><i className="fa fa-check" aria-hidden="true"></i></a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        ),
        selector: "LotID",
        sortable: false,
        left: true,
        omit: !(this.getLocalState().filterParams && this.getLocalState().filterParams.showLots),
        grow: 1,
        cell: row => {
          if (ListTag.includes(row.Tag)) {
            return (
              <div className="cell-total font-weight-bold">
                {row.Tag === "Grand" ? `Grand Total:` : this.getTotalLabel(row)}
              </div>
            )
          }
          return row.LotID;
        }
      },

      {
        name: this.renderCustomHeader("Beginning", "begin"),
        center: true,
        grow: 1,
        cell: row => {
          const style = { width: "50%", wordWrap: "normal" };
          
          const fillValue = row.BeginningFill ? row.BeginningFill.toFixed(2).replace(NumberFormat.NcNNNdNN, '$&,') : 0;
          const unitsValue = row.BeginningUnits ? row.BeginningUnits.toFixed(2).replace(NumberFormat.NcNNNdNN, '$&,') : 0;
          return (
            <table style={{ width: "100%" }}>
              <tbody>
                <tr style={{ width: "100%" }}>
                  <td style={style} className={ListTag.includes(row.Tag) ? "text-right font-weight-bold" : "text-right"}>
                    {row.BeginningUnits < 0 ? (<span className="text-danger">{unitsValue}</span>) : unitsValue}&nbsp;
              </td>
                  <td style={style} className={ListTag.includes(row.Tag) ? "text-right font-weight-bold" : "text-right"}>
                    {row.BeginningFill < 0 ? (<span className="text-danger">{fillValue}</span>) : fillValue}&nbsp;
              </td>
                </tr>
              </tbody>
            </table>

          );
        }
      },
      {
        name: this.renderBasicHeader("Receipt"),
        center: true,
        grow: 0,
        cell: row => {
          return this.bindValueInGrid(row, "Receipt")
        }
      },
      {
        name: this.renderBasicHeader("Issue"),
        center: true,
        grow: 0,
        cell: row => {
          return this.bindValueInGrid(row, "Issue")
        }
      },
      {
        name: this.renderBasicHeader("Production"),
        center: true,
        grow: 0,
        cell: row => {
          return this.bindValueInGrid(row, "Production")
        }
      },
      {
        name: this.renderBasicHeader("Shipment"),
        center: true,
        grow: 0,
        cell: row => {
          return this.bindValueInGrid(row, "Shipment")
        }
      },
      {
        name: this.renderBasicHeader("Other"),
        center: true,
        grow: 0,
        cell: row => {
          return this.bindValueInGrid(row, "Other")
        }
      },
      {
        name: this.renderCustomHeader("Ending"),
        center: true,
        grow: 1,
        cell: row => {
          const style = { width: "50%", wordWrap: "normal" };
          const fillValue = row.EndingFill ? row.EndingFill.toFixed(2).replace(NumberFormat.NcNNNdNN, '$&,') : 0;
          const unitsValue = row.EndingUnits ? row.EndingUnits.toFixed(2).replace(NumberFormat.NcNNNdNN, '$&,') : 0;
          return (
            <table style={{ width: "100%" }}>
              <tbody>
                <tr style={{ width: "100%" }}>
                  <td style={style} className={ListTag.includes(row.Tag) ? "text-right font-weight-bold" : "text-right"}>
                    {row.EndingUnits < 0 ? (<span className="text-danger">{unitsValue}</span>) : unitsValue}&nbsp;
                  </td>
                  <td style={style} className={ListTag.includes(row.Tag) ? "text-right font-weight-bold" : "text-right"}>
                    {row.EndingFill < 0 ? (<span className="text-danger">{fillValue}</span>) : fillValue}&nbsp;
                  </td>
                </tr>
              </tbody>
            </table>
          );
        }
      },
    ];
  };

  bindValueInGrid = (row, key) => {
    let value = null;
    if (this.getLocalState().filterParams.unitsOrFill) {
      value = row[`${key}Units`];
    } else {
      value = row[`${key}Fill`];
    }
    const valueFormat = value ? value.toFixed(2).replace(NumberFormat.NcNNNdNN, '$&,') : 0;
    return value < 0 ? (<span className="text-danger">{valueFormat}</span>) : valueFormat;
  }

  renderBasicHeader(label) {
    const localState = this.getLocalState();
    const style = { width: "100%", wordWrap: "normal" };    
    return (
      <table style={{ width: "100%" }}>
        <tbody>
          <tr style={{ width: "100%" }}>
            <td className="pl-md pr-md pt-md pb-md text-center" colSpan="2">{label}</td>
          </tr>
          <tr style={{ width: "100%" }}>
            <td style={style} className="pb-md text-center">{(this.getLocalState().filterParams && localState.filterParams.unitsOrFill) ? "Units" : "Fill"}</td>
          </tr>
        </tbody>
      </table>
    );
  }



  renderCustomHeader(label, type) {
    const localState = this.getLocalState();
    let date = null;
    if(type === "begin"){
      date = moment(localState.filterStartDate).format("MM/DD/YYYY");
    }else{
      date = moment(localState.filterEndDate).format("MM/DD/YYYY");
    }

    const style = { width: "50%", wordWrap: "normal" };
    return (
      <table style={{ width: "100%" }}>
        <tbody>
          <tr style={{ width: "100%" }}>
            <td className="pl-md pr-md pt-md pb-md text-right" colSpan="2">{`${label} (${date})`}</td>
          </tr>
          <tr style={{ width: "100%" }}>
            <td style={style} className="pb-md text-right">Units</td>
            <td style={style} className="pb-md text-right">Fill</td>
          </tr>
        </tbody>
      </table>
    );
  }

  //Region: Export handler
  getItemsForExport = () => {
    const parsedItems = [];
    const localState = this.getLocalState();
    if (localState.items) {
      if (localState.filterParams.showLots) {
        localState.items.map((item) => {
          parsedItems.push({
            "Product ID": item.ProductID,
            "Product Name": item.ProductName01,
            "Lot ID": ListTag.includes(item.Tag) ? (item.Tag === "Grand" ? `Grand Total:` : this.getTotalLabel(item)) : item.LotID,
            "Beginning/Units": item.BeginningUnits,
            "Beginning/Fill": item.BeginningFill,
            [`Receipt ${localState.filterParams.unitsOrFill ? "Units" : "Fill"}`]: localState.filterParams.unitsOrFill ? item.ReceiptUnits : item.ReceiptFill,
            [`Issue ${localState.filterParams.unitsOrFill ? "Units" : "Fill"}`]: localState.filterParams.unitsOrFill ? item.IssueUnits : item.IssueFill,
            [`Production ${localState.filterParams.unitsOrFill ? "Units" : "Fill"}`]: localState.filterParams.unitsOrFill ? item.ProductionUnits : item.ProductionFill,
            [`Shipment ${localState.filterParams.unitsOrFill ? "Units" : "Fill"}`]: localState.filterParams.unitsOrFill ? item.ShipmentUnits : item.ShipmentFill,
            [`Other ${localState.filterParams.unitsOrFill ? "Units" : "Fill"}`]: localState.filterParams.unitsOrFill ? item.OtherUnits : item.OtherFill,
            "Ending/Units": item.EndingUnits,
            "Ending/Fill": item.EndingFill,
          });
        });
      } else {
        localState.items.map((item) => {
          parsedItems.push({
            "Product ID": item.ProductID,
            "Product Name": ListTag.includes(item.Tag) ? (item.Tag === "Grand" ? `Grand Total:` : this.getTotalLabel(item)) : item.ProductName01,
            "Beginning/Units": item.BeginningUnits,
            "Beginning/Fill": item.BeginningFill,
            [`Receipt ${localState.filterParams.unitsOrFill ? "Units" : "Fill"}`]: localState.filterParams.unitsOrFill ? item.ReceiptUnits : item.ReceiptFill,
            [`Issue ${localState.filterParams.unitsOrFill ? "Units" : "Fill"}`]: localState.filterParams.unitsOrFill ? item.IssueUnits : item.IssueFill,
            [`Production ${localState.filterParams.unitsOrFill ? "Units" : "Fill"}`]: localState.filterParams.unitsOrFill ? item.ProductionUnits : item.ProductionFill,
            [`Shipment ${localState.filterParams.unitsOrFill ? "Units" : "Fill"}`]: localState.filterParams.unitsOrFill ? item.ShipmentUnits : item.ShipmentFill,
            [`Other ${localState.filterParams.unitsOrFill ? "Units" : "Fill"}`]: localState.filterParams.unitsOrFill ? item.OtherUnits : item.OtherFill,
            "Ending/Units": item.EndingUnits,
            "Ending/Fill": item.EndingFill,
          });
        });
      }

    }
    return parsedItems;
  };

  extendButtons = () => {
    return (
      <ExportExcel
        csvData={this.getItemsForExport()}
        fileName={`Products-${moment(new Date()).format(DateTimeFormat.format3)}`}
        buttonLabel="Export"
        buttonClassName="btn btn-warning"
      />
    );
  };

  //End Region: Export handler

  //Region: Business

  doSomthingAfterGetItems = async () => {
    const items = this.getLocalState().items;
    if (items && items.length > 0) {
      const createdDate = items[0].createdDate;
      await this.getCronJobSetting(ReportName, (data) => {
        this.setLocalState((state) => {
          return { lastUpdateLabel: `Stored From: ${moment(data.transactionDateFrom).format("MM/YYYY")} - Last updated: ${moment(createdDate).format(DateTimeFormat.format4)} (updated ${(data && data.typeCronJob) ? ` ${data.typeCronJob.toLowerCase()}` : ""})` }
        });
      })
    }
  }

  //End region: Business

  renderSearchArea() {
    const localState = this.getLocalState();
    return (
      <>
        <div className="form-group">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <label className="col-sm-3 col-md-3 col-lg-3 control-label">Date Range</label>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <DateRangePicker
                dateFormat={"MM/dd/yyyy"}
                // showMonthYearPicker={true}
                selectedStart={this.getLocalState().filterStartDate}
                selectedEnd={this.getLocalState().filterEndDate}
                startDate={this.getLocalState().filterStartDate}
                endDate={this.getLocalState().filterEndDate}
                onChangeStart={(selectedDate) => { this.handleDatePickerChange(selectedDate, "filterStartDate") }}
                onChangeEnd={(selectedDate) => { this.handleDatePickerChange(selectedDate, "filterEndDate") }}
                minStartDate={this.getLocalState().filterStartDate}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-6">
            <label className="col-sm-3 col-md-3 col-lg-3 control-label">Facility</label>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <MultiSelectComponent
                name="facilities"
                options={localState.optionFacilities}
                onChange={this.multiSelectHandleChange.bind(this)}
                allowSelectAll={true}
                isSearchable={false}
                value={localState.facilitiesValue} />
            </div>
          </div>
          {
            loggedUser.role === "Clients" ? "":
            (
              <>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-sm-medium mt-sm-small">
                    <label className="col-sm-3 col-md-3 col-lg-3 control-label">Attribute Filter</label>
                    <div className="col-sm-8 col-md-8 col-lg-8">
                      <MultiSelectComponent
                        name="productAttribute1"
                        isSearchable={false}
                        options={localState.optionAttributeProduct1}
                        onChange={this.attributeFilterChange.bind(this)}
                        allowSelectAll={false}
                        value={localState.productAttribute1Value}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-sm-medium mt-sm-small">
                    <label className="col-sm-3 col-md-3 col-lg-3 control-label">Attribute Filter Value</label>
                    <div className="col-sm-8 col-md-8 col-lg-8">
                      <MultiSelectComponent
                        name="productAttribute1V"
                        isSearchable={false}
                        options={localState.productAttr1ValueOptions}
                        onChange={this.multiSelectHandleChange.bind(this)}
                        allowSelectAll={false}
                        value={localState.productAttribute1VValue}
                      />
                    </div>
                  </div>
              </>
            )
          }
          

          <div className="col-sm-12 col-md-12 col-lg-6 mt-sm-medium mt-sm-small">
            <label className="col-sm-3 col-md-3 col-lg-3 control-label">Attribute Group 2</label>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <MultiSelectComponent
                name="productAttribute2"
                isSearchable={false}
                options={localState.optionAttributeProducts2}
                onChange={this.multiSelectHandleChange.bind(this)}
                allowSelectAll={false}
                value={localState.productAttribute2Value}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-6 mt-sm-medium mt-sm-small">
            <label className="col-sm-3 col-md-3 col-lg-3 control-label">Units or Fill?</label>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <MultiSelectComponent
                name="unitsOrFill"
                isSearchable={false}
                options={[{ value: true, label: "Units" }, { value: false, label: "Fill" }]}
                onChange={this.multiSelectHandleChange.bind(this)}
                allowSelectAll={false}
                defaultValue={{ value: false, label: "Fill" }}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 mt-sm-medium mt-sm-small">
            <label className="col-sm-3 col-md-3 col-lg-3 control-label">Show Lots?</label>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <MultiSelectComponent
                name="showLots"
                isSearchable={false}
                options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                onChange={this.multiSelectHandleChange.bind(this)}
                allowSelectAll={false}
                defaultValue={{ value: false, label: "No" }}
              />
            </div>
          </div>

        </div>
        <div className="form-group">
          <div className="col-sm-12">
            <div className="pull-right mb-sm-small">
              {this.extendButtons()}
            </div>
            <div className="col-md-3 p-none">
              {this.renderSearch()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default App;
