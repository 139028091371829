import React, { useState } from "react";
import Alert from "../Utilities/Alert";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { HttpAxios } from "../../Utilities/Http"
import { Error } from "../../Components/AuthForms";

const GlobalConfig = new window.globalConfig();

function App(props) {
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [errorMessage, setErrorMessage] = useState("Email is required!");

  //USING FOR ALERT
  const [alertshow, setAlertShow] = useState(false);
  const [alerttype, setAlertType] = useState("success");
  const [alertmsg, setAlertMsg] = useState("");

  function handleServer(props) {
    if (userName === undefined || userName === "" || userName === "undefined") {
      setErrorMessage("Email is required!");
      setIsError(true);
    } else {
      HttpAxios
        .post(GlobalConfig.REACT_APP_API_AUTH_URL + "/recovery", {
          userName,
          baseUrl: GlobalConfig.REACT_APP_BASE_URL,
          fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
        })
        .then((result) => {
          if (result.status === 200) {
            if (result.data.result === "OK") {
              setAlertMsg(
                "Recovery password is sent your e-mail. Please check inbox."
              );
              setAlertShow(true);
              //reset form
              setUserName("");

              setTimeout(() => {
                setAlertShow(false);
              }, 2000);

              setIsError(false);
            } else {
              setErrorMessage(result.data.message);
              setIsError(true);
            }
          } else {
            setErrorMessage("The email provided was incorrect!");
            setIsError(true);
          }
        })
        .catch((e) => {
          setErrorMessage("The email provided was incorrect!");
          setIsError(true);
        });
    }
  }

  return (
    <div
      style={{
        backgroundImage:
          "url(/assets/images/PF-Grading-Background-login@2x.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Helmet>
        <title>
          {"Recover Password | " + process.env.REACT_APP_SITE_TITLE}
        </title>
      </Helmet>
      <div>
        <section className="body-sign">
          <div className="center-sign">
            <a href="/" className="logo pull-left">
              <img
                src="/assets/images/logo.png"
                height="54"
                alt="Porto Admin"
              />
            </a>

            <div className="panel panel-sign">
              <div className="panel-title-sign mt-xl text-right">
                <h2 className="title text-uppercase text-bold m-none">
                  <i className="fa fa-user mr-xs"></i> Recover Password
                </h2>
              </div>
              <div className="panel-body">
                {alertshow === true ? (
                  <Alert
                    message={alertmsg}
                    type={alerttype}
                    show={alertshow}
                  ></Alert>
                ) : (
                    ""
                  )}
                {isError && (
                  <Error className="form-group mb-lg">
                    {ReactHtmlParser(errorMessage)}
                  </Error>
                )}
                <div className="alert alert-info">
                  <p className="m-none text-semibold h6">
                    Enter your e-mail below and we will send you reset
                    instructions!
                  </p>
                </div>
                <div className="form-group mb-none">
                  <div className="input-group">
                    <input
                      required
                      name="username"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                      autoFocus
                      type="email"
                      placeholder="E-mail"
                      className="form-control input-lg"
                      maxLength={100}
                    />
                    <span className="input-group-btn">
                      <button
                        className="btn btn-primary btn-lg"
                        type="button"
                        onClick={handleServer}
                      >
                        Reset!
                      </button>
                    </span>
                  </div>
                </div>
                <p className="text-center mt-lg">
                  Remembered? <a href="/login">Sign In!</a>
                </p>
              </div>
            </div>

            <p className="text-center text-muted mt-md mb-md">
              <span style={{ color: "white" }}>
                &copy; Copyright {new Date().getFullYear()}. All rights
                reserved. Developed by{" "}
                <a href="https://parityfactory.com/" style={{ color: "white" }}>
                  Parity Factory
                </a>
                .
              </span>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
}
export default App;
