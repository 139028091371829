// https://www.npmjs.com/package/react-moment
// npm install --save moment react-moment
import React from "react";
import Moment from "react-moment";

export default class DateFormat extends React.Component {
  render() {
    //12h format
    if(this.props.format === "24"){
      return <Moment format="YYYY-MM-DD HH:mm:ss">{this.props.date}</Moment>;
    }else{
      return <Moment format="YYYY-MM-DD hh:mm:ss A">{this.props.date}</Moment>;
    }
  }
}
