import React from "react";
import AddComponent from "../Base/AddComponent";
import MultiSelectComponent from "../Base/MultiSelectComponent";
import MultiSelectRequiredComponent from "../Base/MultiSelectRequiredComponent";

import { getLoggedUser } from "../../context/auth";
import { HttpAxios } from "../../Utilities/Http";
import { AccessReportOptions } from "../../global";

const loggedUser = getLoggedUser();
const GlobalConfig = new window.globalConfig();
const accessReportOptions = AccessReportOptions;

class App extends AddComponent {
  constructor(props) {
    super();
    this.state = {
      prefixTitle: "Invite ",
      labelButtonSave: "Invite",
      sectionTitle: "",
      title: "Client",
      listtitle: "Clients",
      urllist: process.env.REACT_APP_URL_CLIENT_LIST,
      urlapi: GlobalConfig.REACT_APP_API_CLIENT_URL,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      data: {
        _id: "",
        name: "",
        email: "",
        companyName: "",
        companyId: "",
        status: "Active",
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
        baseUrl: GlobalConfig.REACT_APP_BASE_URL,
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
        accessReport: null,
      },
      accessReportOptions: null,
    };
  }

  inititalComponent() {
    this.getCompanyItems();
  }

  async getCompanyItems() {
    try {
      let urlApi =
        GlobalConfig.REACT_APP_API_CONFIG_URL +
        "queryclients/" +
        process.env.REACT_APP_ATTRIBUTE_REFLECT_CUSTOMER;
      if (loggedUser && loggedUser.role === "Clients") {
        urlApi += `/${loggedUser.companyId}`;
      }
      const response = await HttpAxios.get(urlApi);
      const resultObject = response.data;
      if (resultObject && resultObject.result === "OK" && resultObject.data) {
        const parsedItems = [];
        resultObject.data.map((item) => {
          parsedItems.push({
            value: item._id,
            label: item.name,
            _id: item._id,
            name: item.name,
          });
        });
        this.setState({
          companies: parsedItems,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  multiSelectCompanyHandleChange(selected, element) {
    const key = element.name;
    const keyValue = `${element.name}Value`;
    let valueOptions = selected ? selected.value : ``;
    let selectedText = ``;

    if (selected && Array.isArray(selected)) {
      const options = [...selected];
      const hasSelectAll = options.some((item) => {
        return (
          item.value === MultiSelectComponent.defaultProps.allOption.value &&
          item.label === MultiSelectComponent.defaultProps.allOption.label
        );
      });
      if (hasSelectAll) {
        options.shift();
      }
      valueOptions = options.map((item) => item.value).join();
      valueOptions = valueOptions ? valueOptions : "";
      selectedText = options.map((item) => item.label).join(`##`);
    }
    this.setState((state) => {
      const data = {
        ...state.data,
        [key]: valueOptions,
        companyName: selectedText,
      };
      return {
        data: data,
        [keyValue]: selected,
        companyName: selectedText,
      };
    });
  }

  getTitle = () => {
    return this.state.title;
  };

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        name: "",
        email: "",
        companyName: "",
        companyId: "",
        status: "Active",
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
        baseUrl: GlobalConfig.REACT_APP_BASE_URL,
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
        accessReport: null,
      },
      accessReportValue: null,
      companyIdValue: null,
    });
  };

  renderForm = () => {
    return (
      <div className="panel-body">
        <h4 className="mb-xlg">Client Information</h4>
        <fieldset>
          <div className="form-group">
            <label className="col-md-3 control-label" htmlFor="companyId">
              Company Name <span className="required">(*)</span>
            </label>
            <div className="col-md-5">
              <MultiSelectRequiredComponent
                name="companyId"
                options={this.state.companies}
                onChange={this.multiSelectCompanyHandleChange.bind(this)}
                allowSelectAll={true}
                isSearchable={false}
                value={this.state.companyIdValue}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-md-3 control-label" htmlFor="name">
              Contact Name <span className="required">(*)</span>
            </label>
            <div className="col-md-5">
              <input
                maxLength="150"
                type="text"
                name="name"
                className="form-control"
                placeholder=""
                required
                value={this.state.data.name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-md-3 control-label" htmlFor="email">
              Email <span className="required">(*)</span>
            </label>
            <div className="col-md-5">
              <input
                maxLength="50"
                type="email"
                name="email"
                className="form-control"
                placeholder=""
                required
                value={this.state.data.email}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-md-3 control-label" htmlFor="email">
              Access Report
            </label>
            <div className="col-md-5">
              <MultiSelectComponent
                name="accessReport"
                options={accessReportOptions}
                onChange={this.multiSelectHandleChange.bind(this)}
                allowSelectAll={true}
                isSearchable={false}
                value={this.state.accessReportValue}
              />
            </div>
          </div>
        </fieldset>
        <hr className="dotted tall"></hr>
        <h4 className="mb-xlg">Access to Report Portal</h4>
        <fieldset className="mb-xl">
          <div className="form-group">
            <label
              className="col-xs-3 control-label mt-xs pt-none"
              htmlFor="status"
            >
              Status
            </label>
            <div className="col-md-5">
              <select
                name="status"
                className="form-control"
                required
                value={this.state.data.status}
                onChange={this.handleChange}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
          </div>
        </fieldset>
      </div>
    );
  };
}
export default App;
