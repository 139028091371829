import jsPDF from "jspdf";
import logo64 from "./MeridianLogo";
import CommonUtilities from "../Utilities/Common";

var DateFormat = require("dateformat");
const headerFontSize = 7;
const elementFontSize = 5;
const gapElement = 10;
const gapLine = 2;

export const handleExportPdf = async (data, action) => {
  window.showAlert("Info", "Exporting...", "info");
  try {
    composePdfFile(data, action === "print", action === "preview");
  } catch (err) {
    window.showAlert("Error", err.message, "error");
  }
};

export const composePdfFile = (data, isPrint, isPreview) => {
  if (data) {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);

    const fileName = `meridian-growers-rtt-${data.ReceiptID}.pdf`;

    let startY = composeHeader(data, doc);
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    doc.setDrawColor(50, 24, 168);
    doc.setLineWidth(0.5);
    doc.line(
      pageWidth / 2 + 30,
      startY + 22,
      pageWidth / 2 + 30,
      pageHeight - 48
    );

    startY = composeLoadAnalysis(doc, startY, pageWidth);
    let startYRight = startY;
    startY += gapElement * 2;

    //Print elements on left side
    startY = composeGrossWeight(data, doc, startY, pageWidth);
    startY = composeSplitInShell(data, doc, startY, pageWidth);
    startY = composeShellingStock(data, doc, startY, pageWidth);
    startY = composeClosedShell(data, doc, startY, pageWidth);
    startY = composeTotal(data, doc, startY, pageWidth);

    //Print elements on right side
    startYRight = composeSizeTest(data, doc, startYRight, pageWidth);
    startYRight = composeSplitInShellRight(data, doc, startYRight, pageWidth);
    startYRight = composeShellingStockRight(data, doc, startYRight, pageWidth);
    startYRight = composeClosedShellRight(data, doc, startYRight, pageWidth);
    startYRight = composeTotalRight(data, doc, startYRight, pageWidth);

    composeFooter(doc, pageWidth, pageHeight);

    //open popup print
    if (isPrint || isPreview) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        window.navigator.msSaveOrOpenBlob(doc.output("blob"), fileName);
      } else {
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        if (isPrint) {
          doc.autoPrint();
        }
        window.open(
          URL.createObjectURL(doc.output("blob")),
          "_blank",
          "height=" +
          window.innerHeight +
          ",width=" +
          window.innerWidth +
          ",scrollbars=yes,location=yes"
        );

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(() => {
          window.URL.revokeObjectURL(doc.output("bloburl"));
        }, 100);
      }
    } else {
      //export file
      doc.save(fileName);
    }
  }
};

function printFieldsWithLine(
  data,
  doc,
  pageWidth,
  startY,
  fieldName1,
  fieldName2,
  fieldName3,
  unit
) {
  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  if (unit) {
    doc.text(`${unit}`, pageWidth - 200, startY, "right");
  }
  if (fieldName3) {
    doc.text(`${data[fieldName3] ?? ""}`, pageWidth - 140, startY, "right");
  }
  doc.text(`${data[fieldName1] ?? ""}`, pageWidth - 70, startY, "right");
  doc.text(`${data[fieldName2] ?? ""}`, pageWidth, startY, "right");

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  if (fieldName3 || fieldName3 === "") {
    doc.line(pageWidth, startY, pageWidth - 190, startY);
  } else {
    doc.line(pageWidth, startY, pageWidth - 140, startY);
  }

  return startY;
}

function printGroupLabel(doc, label, x, startY) {
  startY += gapElement;
  //   doc.setFontType("bold");
  doc.setTextColor(0, 24, 168);
  doc.setFontSize(elementFontSize);
  doc.text(label, x, startY);

  return startY;
}

function printGroupLabelLevel2(doc, label, x, startY) {
  startY += gapElement;
  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(elementFontSize);
  doc.text(label, x, startY);

  return startY;
}

function printGroupLabelLevel3(doc, label, x, startY) {
  startY += gapElement;
  //   doc.setFontType("bold");
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(elementFontSize);
  doc.text(label, x, startY);

  return startY;
}

function composeHeader(data, doc) {
  const gap = 10;
  const gapX = 2;
  let startX = 420;
  let startY = 20;
  let startYLeft = 0;
  doc.setFontSize(10);
  doc.setTextColor(50, 24, 168);
  doc.text(`Processor RTT: ${data.ReceiptID}`, startX - 100, startY);

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(headerFontSize);
  startY += gap * 2;
  //   doc.setFontType("bold");
  doc.text("Grower Manifest: ", startX, startY, "right");
  //   doc.setFontType("normal");
  addTextAndLine(doc, startX + gapX, startY, data.ReferenceID, 0);

  startY += gap;
  startYLeft = startY;
  //   doc.setFontType("bold");
  doc.text("Grower Field: ", startX, startY, "right");
  addTextAndLine(doc, startX + gapX, startY, data.AreaOfOriginID, 0);
  doc.text("Variety: ", startX + 100, startY, "left");

  startY += gap;
  //   doc.setFontType("bold");
  doc.text("Trucker/Carrier: ", startX, startY, "right");
  addTextAndLine(doc, startX + gapX, startY, data.CarrierID, 0);
  doc.text(`${data.Userfield03 ?? ""}`, startX + 100, startY, "left");

  startY += gap;
  //   doc.setFontType("bold");
  doc.text("Trailer Plate: ", startX, startY, "right");
  //   doc.setFontType("normal");
  if (
    (data.TrailerLicenseNumberOne && data.TrailerLicenseNumberOne !== "") ||
    (data.TrailerLicenseNumberTwo && data.TrailerLicenseNumberTwo !== "")
  ) {
    doc.text(
      `${data.TrailerLicenseNumberOne ?? ""} , ${data.TrailerLicenseNumberTwo ?? ""
      }`,
      startX + gapX,
      startY
    );
  }
  //doc.setFontType("bold");
  doc.text(`Temp:`, startX + 100, startY);
  addLine(doc, startX + gapX, startY, 0);

  startY += gap;
  //   doc.setFontType("bold");
  doc.text("Date Recv.: ", startX, startY, "right");
  //   doc.setFontType("normal");
  doc.text(`${data.ScheduledStartDateTime ?? ""}`, startX + gapX, startY);
  //   doc.setFontType("bold");
  doc.text(`${data.UserField01 ?? ""}`, startX + 100, startY);

  addLine(doc, startX + gapX, startY, 0);

  startY += gap;
  //   doc.setFontType("bold");
  doc.text("Date Processed: ", startX, startY, "right");
  //   doc.setFontType("normal");
  doc.text(`${data.ScheduledEndDateTime ?? ""}`, startX + gapX, startY);
  //   doc.setFontType("bold");
  doc.text(`${data.UserField02 ?? ""}`, startX + 100, startY);

  addLine(doc, startX + gapX, startY, 0);

  doc.addImage(logo64, "png", 30, 20, 140, 60);

  let startXLeft = 175;
  //   doc.setFontType("bold");
  //   doc.setFontType("normal");
  startYLeft = addTextAndLine(
    doc,
    startXLeft,
    startYLeft,
    data.AccountName,
    gap
  );
  startYLeft = addTextAndLine(
    doc,
    startXLeft,
    startYLeft,
    data.AddressLine1,
    gap
  );
  startYLeft = addTextAndLine(
    doc,
    startXLeft,
    startYLeft,
    data.Addressline2,
    gap
  );
  startYLeft = addTextAndLine(
    doc,
    startXLeft,
    startYLeft,
    data.Addressline3,
    gap
  );

  return startY;
}

function addTextAndLine(doc, x, y, text, gap) {
  doc.text(`${text ?? ""}`, x, y, "left");
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.1);
  doc.line(x, y + 2, x + 165, y + 2);
  return y + gap;
}

function addLine(doc, x, y, gap) {
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.1);
  doc.line(x, y + 2, x + 165, y + 2);
  return y + gap;
}

function composeFooter(doc, pageWidth, pageHeight) {
  const pages = doc.internal.getNumberOfPages();

  doc.setFontSize(elementFontSize + 2);

  // let horizontalPos = pageWidth / 2; //Can be fixed number
  let verticalPos = pageHeight - 25; //Can be fixed number
  doc.setPage(1);
  doc.text(
    `Page 1 of ${pages} Print Date ${DateFormat(
      new Date(),
      "mm/dd/yyyy hh:MM:ss TT"
    )}`,
    pageWidth - 15,
    verticalPos,
    {
      align: "right",
    }
  );

  //-------------------------------------------------------
  verticalPos += gapLine * 2;
  doc.setDrawColor(50, 24, 168);
  doc.setLineWidth(1.0);
  doc.line(10, verticalPos, pageWidth - 10, verticalPos);
}

function composeLoadAnalysis(doc, startY, pageWidth) {
  const leftX0 = 10;
  startY += 7;
  doc.setTextColor(50, 24, 168);
  doc.setDrawColor(50, 24, 168);
  doc.setLineWidth(0.5);
  doc.line(leftX0, startY, pageWidth - 10, startY);
  doc.setFontSize(10);
  doc.text(`LOAD ANALYSIS`, 20, startY + 10);
  startY += 14;
  doc.line(leftX0, startY, pageWidth - 10, startY);
  return startY;
}

function composeGrossWeight(data, doc, startY, pageWidth) {
  const pageWidthOriginal = pageWidth;
  pageWidth = pageWidth / 2 + 20;
  const rightX = pageWidth;
  const leftX0 = 20;
  const leftX1 = 25;
  const leftX2 = 175;
  startY += gapElement;

  //-------------------------------------------------------
  //   doc.setFontType("bold");
  doc.setTextColor(leftX0, 24, 168);
  doc.setFontSize(elementFontSize);
  doc.text(`GROSS WEIGHT`, leftX0, startY);

  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  doc.text(`${data.ScaleGross}`, pageWidth - 70, startY, "right");

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth - 70, startY, pageWidth - 140, startY);

  startY += gapElement;
  doc.text(`Less Tare Wght From Truck`, leftX1, startY);
  doc.text(`# of Bins:`, leftX2 - 70, startY);
  doc.text(`${0}`, leftX2 + 50 - 70, startY);
  doc.text(`${data.ScaleTare}`, rightX - 70, startY, "right");

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth - 70, startY, pageWidth - 165, startY);
  //-------------------------------------------------------
  startY += gapElement;
  //   doc.setFontType("bold");
  doc.setTextColor(leftX0, 24, 168);
  doc.setFontSize(elementFontSize);
  doc.text(`NET WEIGHT`, leftX0, startY);

  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  doc.text(`${data.NetWeight2}`, pageWidth - 70, startY, "right");
  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth - 70, startY, pageWidth - 140, startY);

  startY += gapElement;
  doc.text(`Less Precleaner Trash`, leftX1, startY);
  doc.text(
    `${data["Inbound Grade-Precleaner Trash"] ?? ""}`,
    pageWidth - 70,
    startY,
    "right"
  );

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth - 70, startY, pageWidth - 140, startY);
  //-------------------------------------------------------
  startY += gapElement;
  //   doc.setFontType("bold");
  doc.setTextColor(leftX0, 24, 168);
  doc.setFontSize(elementFontSize);
  doc.text(`NET GREEN WEIGHT`, leftX0, startY);

  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  doc.text(`${data.NetGreenWeight ?? ""}`, pageWidth - 70, startY, "right");
  doc.text(`${data["NetGreenWeight%"] ?? ""}`, pageWidth, startY, "right");
  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth, startY, pageWidth - 140, startY);

  startY += gapElement;
  doc.text(`Weight after Hulling`, leftX1, startY);
  doc.text(`${data.WeightAfterHull ?? ""}`, pageWidth - 70, startY, "right");
  doc.text(`${data["WeightAfterHull%"] ?? ""}`, pageWidth, startY, "right");
  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth, startY, pageWidth - 140, startY);
  //-------------------------------------------------------
  startY += gapElement;
  doc.text(`Weight after Drying to`, leftX1, startY);
  doc.text(
    `${data["Inbound Grade-Moisture Percent"] ?? ""}`,
    leftX2 - 25,
    startY,
    "right"
  );
  doc.text(`Moisture`, leftX2 - 20, startY);
  doc.text(`${data.WeightAfterDry ?? ""}`, pageWidth - 70, startY, "right");
  doc.text(`${data["WeightAfterDry%"] ?? ""}`, pageWidth, startY, "right");

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth, startY, pageWidth - 140, startY);
  doc.line(leftX2 - 55, startY, leftX2 - 25, startY);
  //-------------------------------------------------------

  startY += gapElement;
  doc.text(`Weight after Adjusting to`, leftX1, startY);
  doc.text(`${"5.00%"}`, leftX2 - 25, startY, "right");
  doc.text(`Moisture`, leftX2 - 20, startY);
  doc.text(`${data.WeightAfterAdjTo ?? ""}`, pageWidth - 70, startY, "right");
  doc.text(`${data["WeightAfterAdjTo%"] ?? ""}`, pageWidth, startY, "right");

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth, startY, pageWidth - 140, startY);
  doc.line(leftX2 - 55, startY, leftX2 - 25, startY);
  //-------------------------------------------------------
  startY += gapElement;
  doc.text(`Less Undersized & F.M`, leftX1, startY);
  doc.text(
    `${data["UndersizedFMTotal"] ?? ""}`,
    pageWidth - 70,
    startY,
    "right"
  );

  doc.text(
    `${data["Undersized and FM Percent"] ?? ""}`,
    pageWidth,
    startY,
    "right"
  );
  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth, startY, pageWidth - 140, startY);
  //-------------------------------------------------------
  startY += gapElement;
  //   doc.setFontType("bold");
  doc.setTextColor(leftX0, 24, 168);
  doc.setFontSize(elementFontSize);
  doc.text(`NET DRY WEIGHT`, leftX0, startY);

  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  doc.text(`${data.NetDryWeight ?? ""}`, pageWidth - 70, startY, "right");
  doc.text(`${data["NetDryWeight%"] ?? ""}`, pageWidth, startY, "right");
  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth, startY, pageWidth - 140, startY);
  //-------------------------------------------------------
  startY += gapLine * 2;
  doc.setDrawColor(50, 24, 168);
  doc.setLineWidth(1.0);
  doc.line(10, startY, pageWidthOriginal - 10, startY);

  return startY;
}

function composeSplitInShell(data, doc, startY, pageWidth) {
  const pageWidthOriginal = pageWidth;
  pageWidth = pageWidth / 2 + 20;
  const leftX0 = 20;
  const leftX1 = 25;
  const leftX3 = 50;

  //-------------------------------------------------------
  startY = printGroupLabel(doc, `SPLIT IN SHELL`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "SplitInshellTotal",
    "SplitInshellTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabel(doc, `UNSTAINED`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "UnstainedTotal",
    "UnstainedTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Insect Damage`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "UnstainedInsectTotal",
    "UnstainedInsectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Defects`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "UnstainedDefectTotal",
    "UnstainedDefectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel3(doc, `Edible Unstained`, leftX3, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "EdibleUnstainedTotal",
    "EdibleUnstainedTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabel(doc, `LIGHT STAINED`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "LightStainedTotal",
    "LightStainedTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Insect Damage`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "LightStainedInsectTotal",
    "LightStainedInsectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Defects`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "LightStainedDefectTotal",
    "LightStainedDefectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel3(doc, `Edible Light Stained`, leftX3, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "EdibleLightstainedTotal",
    "EdibleLightstainedTotal%",
    ""
  );
  //-------------------------------------------------------
  startY = printGroupLabel(doc, `TOTAL EDIBLE SPLIT INSHELL`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "TotalEdiSplitTotal",
    "TotalEdiSplitTotal%"
  );
  //-------------------------------------------------------

  startY += gapElement;
  //   doc.setFontType("bold");
  doc.setTextColor(255, 0, 0);
  doc.setFontSize(elementFontSize);
  doc.text(`Reject lbs`, pageWidth - 190, startY, "right");
  doc.text(`Reject %`, pageWidth - 140, startY, "right");

  startY += gapElement;
  //   doc.setFontType("bold");
  doc.setFontSize(elementFontSize);
  doc.text(`Rejects From Split Inshel`, leftX0, startY);

  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  doc.text(
    `${data["RejectFromSplitLB"] ?? ""}`,
    pageWidth - 190,
    startY,
    "right"
  );
  doc.text(
    `${data["RejectFromSplit%"] ?? ""}`,
    pageWidth - 140,
    startY,
    "right"
  );

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth - 220, startY, pageWidth - 140, startY);
  //-------------------------------------------------------

  startY += gapLine * 2;
  doc.setDrawColor(50, 24, 168);
  doc.setLineWidth(1.0);
  doc.line(10, startY, pageWidthOriginal - 10, startY);

  return startY;
}

function composeShellingStock(data, doc, startY, pageWidth) {
  const pageWidthOriginal = pageWidth;
  pageWidth = pageWidth / 2 + 20;
  const leftX0 = 20;
  const leftX1 = 25;
  const leftX3 = 50;

  //-------------------------------------------------------
  startY = printGroupLabel(doc, `SHELLING STOCK (SPLIT)`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "ShellingStockTotal",
    "ShellingStockTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabel(doc, `ADHERING HULL`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "AdHullTotal",
    "AdHullTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Insect Damage`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "AdHullInsectTotal",
    "AdHullInsectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Defects`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "AdHullDefectTotal",
    "AdHullDefectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel3(doc, `Edible Kernels`, leftX3, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "AdHullEdibleKernTotal",
    "AdHullEdibleKernTotal%",
    "AdHullEdibleKernTotal",
    `Shell`
  );
  //-------------------------------------------------------
  startY = printGroupLabel(doc, `DARK STAINED`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "DarkStainTotal",
    "DarkStainTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Insect Damage`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "DarkStainInsectTotal",
    "DarkStainInsectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Defects`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "DarkStainDefectTotal",
    "DarkStainDefectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel3(doc, `Edible Kernels`, leftX3, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "DarkStainEdibleKernTotal",
    "DarkStainEdibleKernTotal%",
    "DarkStainEdibleKernTotal",
    `Shell`
  );

  //-------------------------------------------------------
  startY = printGroupLabel(doc, `SHELL DAMAGE & OTHER`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "ShellDmgTotal",
    "ShellDmgTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Insect Damage`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "ShellDmgInsectTotal",
    "ShellDmgInsectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Defects`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "ShellDmgDefectTotal",
    "ShellDmgDefectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel3(doc, `Edible Kernels`, leftX3, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "ShellDmgEdibleKernTotal",
    "ShellDmgEdibleKernTotal%",
    "ShellDmgEdibleKernTotal",
    `Shell`
  );
  //-------------------------------------------------------
  startY = printGroupLabel(doc, `UNDER 30/64`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "UnderTotal",
    "UnderTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Insect Damage`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "UnderInsectTotal",
    "UnderInsectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Defects`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "UnderDefectTotal",
    "UnderDefectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel3(doc, `Edible Kernels`, leftX3, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "UnderEdibleKernTotal",
    "UnderEdibleKernTotal%",
    "UnderEdibleKernTotal",
    `Shell`
  );
  //-------------------------------------------------------
  startY = printGroupLabel(doc, `LOOSE SHELLS & KERNELS`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "LooseShellKernTotal",
    "LooseShellKernTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Shell`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "LooseShellTotal",
    "LooseShellTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Edible Loose Kernels`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "EdibleLooseKernTotal",
    "EdibleLooseKernTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabel(
    doc,
    `TOTAL EDIBLE KERNELS FROM SHELLING STOCK`,
    leftX0,
    startY
  );
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "TotalEdiKernTotal",
    "TotalEdiKernTotal%"
  );
  //-------------------------------------------------------

  startY += gapElement;
  //   doc.setFontType("bold");
  doc.setTextColor(255, 0, 0);
  doc.setFontSize(elementFontSize);
  doc.text(`Insect lbs`, pageWidth - 190, startY, "right");
  doc.text(`Insect %`, pageWidth - 140, startY, "right");

  startY += gapElement;
  //   doc.setFontType("bold");
  doc.setFontSize(elementFontSize);
  doc.text(`Insect from Open Shell`, leftX0, startY);

  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  doc.text(
    `${data["InsectsFromOpenLB"] ?? ""}`,
    pageWidth - 190,
    startY,
    "right"
  );
  doc.text(
    `${data["InsectsFromOpen%"] ?? ""}`,
    pageWidth - 140,
    startY,
    "right"
  );

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth - 220, startY, pageWidth - 140, startY);
  //-------------------------------------------------------

  startY += gapLine * 2;
  doc.setDrawColor(50, 24, 168);
  doc.setLineWidth(1.0);
  doc.line(10, startY, pageWidthOriginal - 10, startY);

  return startY;
}

function composeClosedShell(data, doc, startY, pageWidth) {
  const pageWidthOriginal = pageWidth;
  pageWidth = pageWidth / 2 + 20;
  const leftX0 = 20;
  const leftX1 = 25;

  //-------------------------------------------------------
  startY = printGroupLabel(doc, `CLOSED SHELL`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "ClosedShellTotal",
    "ClosedShellTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Insect Damage`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "ClosedInsectTotal",
    "ClosedInsectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Defects`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "ClosedDefectTotal",
    "ClosdDefectTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Blanks`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "ClosedBlankTotal",
    "ClosedBlankTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(
    doc,
    `Subtotal Edible Closed Inshel`,
    leftX1,
    startY
  );
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "SubEdiClosedInshelTotal",
    "SubEdiClosedInshelTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabelLevel2(doc, `Less Shell`, leftX1, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "ClosedLessShellTotal",
    "ClosedLessShellTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabel(
    doc,
    `NET DRY WEIGHT TOTAL EDIBLE KERNELS FROM CLOSE`,
    leftX0,
    startY
  );
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "NetDryEdiKernTotal",
    "NetDryEdiKernTotal%"
  );
  //-------------------------------------------------------
  startY += gapLine * 2;
  doc.setDrawColor(50, 24, 168);
  doc.setLineWidth(1.0);
  doc.line(10, startY, pageWidthOriginal - 10, startY);

  return startY;
}

function composeTotal(data, doc, startY, pageWidth) {
  pageWidth = pageWidth / 2 + 20;
  const leftX0 = 20;

  //-------------------------------------------------------
  startY = printGroupLabel(doc, `TOTAL EDIBLE WEIGHT`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "TotalEdiWeightTotal",
    "TotalEdiWeightTotal%"
  );
  //-------------------------------------------------------
  startY = printGroupLabel(doc, `TOTAL ACP ASSESSED WEIGHT`, leftX0, startY);
  startY = printFieldsWithLine(
    data,
    doc,
    pageWidth,
    startY,
    "SizeAssessedWtTotal",
    "SizeAssessedWtTotal%"
  );

  return startY;
}

//////////////////////RIGTH

function print5FieldsWithLine(
  data,
  doc,
  pageWidth,
  pageWidthOriginal,
  startY,
  label,
  fieldName1,
  fieldName2,
  value3,
  fieldName4,
  fieldName5
) {
  startY += gapElement;
  doc.text(label, pageWidth + 15, startY);

  doc.text(`${data[fieldName1] ?? ""}`, pageWidth + 75, startY, "right");
  doc.text(`${data[fieldName2] ?? ""}`, pageWidth + 115, startY, "right");
  doc.text(`${value3}`, pageWidth + 155, startY, "right");
  doc.text(`${data[fieldName4] ?? ""}`, pageWidth + 195, startY, "right");
  doc.text(`${data[fieldName5] ?? ""}`, pageWidth + 235, startY, "right");

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth + 45, startY, pageWidthOriginal - 15, startY);

  return startY;
}

function composeSizeTest(data, doc, startY, pageWidth) {
  const pageWidthOriginal = pageWidth;
  pageWidth = pageWidth / 2 + 35;
  //-------------------------------------------------------
  startY += gapElement;
  //   doc.setFontType("bold");
  doc.setTextColor(0, 24, 168);
  doc.setFontSize(elementFontSize);
  doc.text(`SIZE TEST`, pageWidth, startY);

  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  doc.text(`Pounds`, pageWidth + 50, startY);
  doc.text(`Percent`, pageWidth + 90, startY);
  doc.text(`Size`, pageWidth + 130, startY);
  doc.text(`Pound`, pageWidth + 170, startY);
  doc.text(`Percent`, pageWidth + 210, startY);
  //-------------------------------------------------------
  startY = print5FieldsWithLine(
    data,
    doc,
    pageWidth,
    pageWidthOriginal,
    startY,
    `Inshell`,
    "TotalEdiSplitTotal",
    "TotalEdiSplitTotal%",
    "16/18",
    "Size16/18Pounds",
    "Inbound Grade-Inshell 16/18%"
  );
  startY = print5FieldsWithLine(
    data,
    doc,
    pageWidth,
    pageWidthOriginal,
    startY,
    `Kernels`,
    "SizeKernels",
    "SizeKernels%",
    "18/20",
    "Size18/20Pounds",
    "Inbound Grade-Inshell 18/20%"
  );
  startY = print5FieldsWithLine(
    data,
    doc,
    pageWidth,
    pageWidthOriginal,
    startY,
    `Payable`,
    "TotalEdiWeightTotal",
    "TotalEdiWeightTotal%",
    "21/25",
    "Size21/25Pounds",
    "Inbound Grade-Inshell 21/25%"
  );
  startY = print5FieldsWithLine(
    data,
    doc,
    pageWidth,
    pageWidthOriginal,
    startY,
    `Assessed W`,
    "SizeAssessedWtTotal",
    "SizeAssessedWtTotal%",
    "26/30",
    "Size26/30Pounds",
    "Inbound Grade-Inshell 26/30%"
  );
  //-------------------------------------------------------
  startY += gapLine * 2;
  doc.setDrawColor(50, 24, 168);
  doc.setLineWidth(0.5);
  doc.line(pageWidth - 5, startY, pageWidthOriginal - 10, startY);
  //-------------------------------------------------------
  //Below size test
  startY += gapElement + gapLine * 3;
  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  doc.text(`${"20.00"}`, pageWidth + 95, startY, "right");
  doc.text(`Lbs`, pageWidth + 105, startY, "right");

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth + 95, startY, pageWidth + 65, startY);
  //-------------------------------------------------------
  startY += gapElement;
  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  printDecimalField(doc, data, "WeightAfterHullSample", pageWidth + 95, startY);
  doc.text(`Lbs`, pageWidth + 105, startY, "right");

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth + 95, startY, pageWidth + 65, startY);

  startY -= gapLine;
  doc.text(`Percent`, pageWidth + 125, startY, "center");
  doc.text(
    `${data["WeightAfterDrySample"] ?? ""}`,
    pageWidthOriginal - 55,
    startY,
    "right"
  );
  doc.text(
    `${data["NegMoisture%"] ?? ""}`,
    pageWidthOriginal - 10,
    startY,
    "right"
  );
  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidthOriginal - 110, startY, pageWidthOriginal - 10, startY);
  //-------------------------------------------------------
  startY += gapElement;
  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);

  doc.text(`(Grams)`, pageWidth + 30, startY, "center");
  printDecimalField(doc, data, "WeightAfterDrySample", pageWidth + 95, startY);
  doc.text(`Lbs`, pageWidth + 105, startY, "right");

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth + 95, startY, pageWidth + 65, startY);

  startY -= gapLine;
  doc.text(`Dry Weight`, pageWidth + 125, startY, "center");
  doc.text(
    `${data["MoistureDiffSample"] ?? ""}`,
    pageWidthOriginal - 55,
    startY,
    "right"
  );
  doc.text(`5.00%`, pageWidthOriginal - 10, startY, "right");
  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidthOriginal - 110, startY, pageWidthOriginal - 10, startY);

  //-------------------------------------------------------
  startY += gapElement;
  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  printDecimalField(
    doc,
    data,
    "Inbound Grade-Sample Size",
    pageWidth + 45,
    startY
  );

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth, startY, pageWidth + 45, startY);

  startY -= gapLine;
  doc.text(`100%`, pageWidthOriginal - 110, startY, "right");

  doc.text(
    `${data["WeightAfterAdjSample"] ?? ""}`,
    pageWidthOriginal - 55,
    startY,
    "right"
  );
  doc.text(
    `${data["WeightAfterAdjSample%"] ?? ""}`,
    pageWidthOriginal - 10,
    startY,
    "right"
  );
  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidthOriginal - 110, startY, pageWidthOriginal - 10, startY);
  //-------------------------------------------------------
  startY += gapElement;
  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  printDecimalField(
    doc,
    data,
    "Inbound Grade-Undersized and FM",
    pageWidth + 45,
    startY
  );

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth, startY, pageWidth + 45, startY);

  startY -= gapLine;
  doc.text(
    `${data["UndersizedFM%"] ?? ""}`,
    pageWidthOriginal - 110,
    startY,
    "right"
  );
  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidthOriginal - 110, startY, pageWidthOriginal - 150, startY);
  //-------------------------------------------------------
  startY += gapElement;
  //   doc.setFontType("normal");
  doc.setTextColor(0, 0, 0);
  printDecimalField(doc, data, "NetDryWeightSample", pageWidth + 45, startY);

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidth, startY, pageWidth + 45, startY);

  startY -= gapLine;
  doc.text(
    `${data["NetDryWeightSample%"] ?? ""}`,
    pageWidthOriginal - 110,
    startY,
    "right"
  );

  doc.text(
    `${data["WeightAfterAdjSample%"] ?? ""}`,
    pageWidthOriginal - 15,
    startY,
    "right"
  );

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidthOriginal - 110, startY, pageWidthOriginal - 150, startY);
  doc.line(pageWidthOriginal - 50, startY, pageWidthOriginal - 10, startY);
  //-------------------------------------------------------
  return startY;
}

function composeSplitInShellRight(data, doc, startY, pageWidth) {
  const pageWidthOriginal = pageWidth;
  pageWidth = pageWidth / 2 + 35;
  //-------------------------------------------------------
  let arrayFieldName1 = [
    "Inbound Grade-Split Inshell",
    "Inbound Grade-Unstained",
    "Inbound Grade-Unstained Insect Dam",
    "Inbound Grade-Unstained Defects",
    "EdibleUnstainedSample",
    "Inbound Grade-LightStained",
    "Inbound Grade-Light Stained Insect",
    "Inbound Grade-Light Stained Defect",
    "EdibleLightStainedSample",
    "TotalEdiSplitSample",
  ];
  let arrayFieldName2 = [
    "SplitInshellSample%",
    "UnstainedSample%",
    "UnstainedInsectSample%",
    "UnstainedDefectSample%",
    "EdibleUnstainedSample%",
    "LightStainedSample%",
    "LightStainedInsectSample%",
    "LightStainedDefectSample%",
    "EdibleLightStainedSample%",
    "TotalEdiSplitSample%",
  ];

  startY += gapLine * 2;

  for (let i = 0; i < arrayFieldName1.length; i++) {
    startY = printFieldsOnRight(
      data,
      doc,
      pageWidth,
      pageWidthOriginal,
      startY,
      arrayFieldName1[i],
      arrayFieldName2[i],
      undefined,
      arrayFieldName1[i] === "EdibleUnstainedSample" ||
      arrayFieldName1[i] === "EdibleLightStainedSample" ||
      arrayFieldName1[i] === "TotalEdiSplitSample"
    );
  }
  //-------------------------------------------------------
  return startY;
}

function printFieldsOnRight(
  data,
  doc,
  pageWidth,
  pageWidthOriginal,
  startY,
  fieldName1,
  fieldName2,
  fieldName3,
  isBold = false
) {
  startY += gapElement;
  if (isBold === true) {
    //   doc.setFontType("bold");
  } else {
    //   doc.setFontType("normal");
  }
  doc.setTextColor(0, 0, 0);

  printDecimalField(doc, data, fieldName1, pageWidth + 45, startY);

  if (fieldName3) {
    printDecimalField(doc, data, fieldName3, pageWidth + 95, startY);
  }

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  if (fieldName3) {
    doc.line(pageWidth, startY, pageWidth + 95, startY);
  } else {
    doc.line(pageWidth, startY, pageWidth + 45, startY);
  }

  startY -= gapLine;
  doc.text(
    `${data[fieldName2] ?? ""}`,
    pageWidthOriginal - 110,
    startY,
    "right"
  );

  startY += gapLine;
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.5);
  doc.line(pageWidthOriginal - 110, startY, pageWidthOriginal - 150, startY);

  return startY;
}

function composeShellingStockRight(data, doc, startY, pageWidth) {
  const pageWidthOriginal = pageWidth;
  pageWidth = pageWidth / 2 + 35;
  //-------------------------------------------------------
  let arrayFieldName1 = [
    "Inbound Grade-Shelling Stock Split",
    "Inbound Grade-AdheringHull",
    "Inbound Grade-Ad Hull Insect",
    "Inbound Grade-Ad Hull Defects",
    "AdHullEdibleKern",
    "Inbound Grade-DarkStained",
    "Inbound Grade-Dark Stain Insect",
    "Inbound Grade-Dark Stain Defects",
    "DarkStainEdibleKern",
    "Inbound Grade-ShellDamage",
    "Inbound Grade-Shell Damage Insect",
    "Inbound Grade-Shell Damage Defects",
    "ShellDmgEdibleKern",
    "Inbound Grade-Undersize 30/64",
    "Inbound Grade-Undersize Insect",
    "Inbound Grade-Undersize Defects",
    "UnderEdibleKern",
    "Inbound Grade-Loose Shells & Kern",
    "Inbound Grade-Loose Shells",
    "EdibleLooseKern",
    "TotalEdiKernSample",
  ];
  let arrayFieldName2 = [
    "ShellingStockSample%",
    "AdHullSample%",
    "AdHullInsectSample%",
    "AdHullDefectSample%",
    "AdHullEdibleKern%",
    "DarkStainSample%",
    "DarkStainInsectSample%",
    "DarkStainDefectSample%",
    "DarkStainEdibleKern%",
    "ShellDmgSample%",
    "ShellDmgInsectSample%",
    "ShellDmgDefectSample%",
    "ShellDmgEdibleKern%",
    "UnderSample%",
    "UnderInsectSample%",
    "UnderDefectSample%",
    "UnderEdibleKern%",
    "LooseShellKernSample%",
    "LooseShellSample%",
    "EdibleLooseKern%",
    "TotalEdiKernSample%",
  ];

  startY += 26;

  for (let i = 0; i < arrayFieldName1.length; i++) {
    startY = printFieldsOnRight(
      data,
      doc,
      pageWidth,
      pageWidthOriginal,
      startY,
      arrayFieldName1[i],
      arrayFieldName2[i],
      arrayFieldName1[i] === "AdHullEdibleKern"
        ? "AdHullEdibleKern"
        : arrayFieldName1[i] === "DarkStainEdibleKern"
          ? "DarkStainEdibleKern"
          : arrayFieldName1[i] === "ShellDmgEdibleKern"
            ? "ShellDmgEdibleKern"
            : arrayFieldName1[i] === "UnderEdibleKern"
              ? "UnderEdibleKern"
              : arrayFieldName1[i] === "EdibleLooseKern"
                ? "EdibleLooseKern"
                : undefined
    );
  }
  //-------------------------------------------------------
  return startY;
}

function composeClosedShellRight(data, doc, startY, pageWidth) {
  const pageWidthOriginal = pageWidth;
  pageWidth = pageWidth / 2 + 35;
  //-------------------------------------------------------
  let arrayFieldName1 = [
    "Inbound Grade-Closed Shell",
    "Inbound Grade-Closed Shell Insect",
    "Inbound Grade-Closed Shell Defects",
    "Inbound Grade-Closed Shell Blanks",
    "SubEdiInshelSample",
    "Inbound Grade-Closed Shell Shell",
    "NetDryEdiKernSample",
  ];
  let arrayFieldName2 = [
    "ClosedShellSample%",
    "ClosedInsectSample%",
    "ClosedDefectSample%",
    "ClosedBlankSample%",
    "SubEdiInshelSample%",
    "ClosedLessShellSample%",
    "NetDryEdiKernSample%",
  ];

  startY += 26;

  for (let i = 0; i < arrayFieldName1.length; i++) {
    startY = printFieldsOnRight(
      data,
      doc,
      pageWidth,
      pageWidthOriginal,
      startY,
      arrayFieldName1[i],
      arrayFieldName2[i]
    );
  }
  //-------------------------------------------------------
  return startY;
}

function composeTotalRight(data, doc, startY, pageWidth) {
  const pageWidthOriginal = pageWidth;
  pageWidth = pageWidth / 2 + 35;
  //-------------------------------------------------------
  let arrayFieldName1 = ["TotalEdiWeightSample", "TotalACPWgtSample"];
  let arrayFieldName2 = ["TotalEdiWeightSample%", "TotalACPWgtSample%"];

  startY += gapLine * 2;

  for (let i = 0; i < arrayFieldName1.length; i++) {
    startY = printFieldsOnRight(
      data,
      doc,
      pageWidth,
      pageWidthOriginal,
      startY,
      arrayFieldName1[i],
      arrayFieldName2[i]
    );
  }
  //-------------------------------------------------------
  return startY;
}

function printDecimalField(doc, data, fieldName, x, y, align = "right") {
  const text = CommonUtilities.numberWithCommas(
    (data[fieldName] == null ||
      data[fieldName] === undefined ||
      data[fieldName] === "" ||
      isNaN(data[fieldName])
      ? 0
      : data[fieldName]
    ).toFixed(2)
  );
  doc.text(text, x, y, align);
}
