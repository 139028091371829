import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import { HttpAxios } from "../../Utilities/Http";
import Switch from "react-switch";

const GlobalConfig = new window.globalConfig();
const loggedUser = getLoggedUser();

class App extends EditComponent {
  constructor(props) {
    super();
    this.state = {
      sectionTitle: "Client Settings",
      listtitle: "",
      urllist: "",
      urlapi: GlobalConfig.REACT_APP_API_CONFIG_URL + "setting/",
      urlsubmitapi:
        GlobalConfig.REACT_APP_API_CONFIG_URL +
        "savesetting/" +
        process.env.REACT_APP_ATTRIBUTE_REFLECT_CUSTOMER,
      data: {
        _id: "",
        name: process.env.REACT_APP_ATTRIBUTE_REFLECT_CUSTOMER,
        value: "",
        extraValue: "",
        isUseStoreProcedure: false,
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
      },
      name: process.env.REACT_APP_ATTRIBUTE_REFLECT_CUSTOMER,
      items: [],
    };
    this.queryICProductAttributes = this.queryICProductAttributes.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeSwitchIsUseStoreProcedure =
      this.handleChangeSwitchIsUseStoreProcedure.bind(this);
  }

  async componentDidMount() {
    this.getItemById();
  }

  getItemById = async () => {
    this.setState({
      alertmsg: "Query data...",
      alerttype: "info",
      alertshow: true,
    });
    try {
      const fetchItem = await HttpAxios.get(
        this.state.urlapi + this.state.name
      );
      const item = fetchItem.data;
      if (item && item.result === "OK" && item.data) {
        this.setState(
          {
            data: item.data,
          },
          function () {
            this.doSomethingAfterGetItemById();
          }
        );
        //Turn-off message
        this.setState({
          alertshow: false,
        });
      } else {
        this.setState({
          alertshow: false,
        });
        if (item.result !== "OK") {
          this.setState({
            alertmsg: item.message,
            alerttype: "error",
            alertshow: true,
          });
        }
        this.doSomethingAfterGetItemById();
      }
    } catch (err) {
      this.setState({
        alertshow: false,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  handleChangeSelect(event) {
    var id = event.target.name;
    const value = event.target.value;
    const extraValue =
      event.target[event.target.selectedIndex].getAttribute("data-extravalue");
    this.setState({
      data: Object.assign({}, this.state.data, {
        [id]: value,
        extraValue: extraValue,
      }),
    });
  }

  handleChangeSwitchIsUseStoreProcedure(checked) {
    this.setState({
      data: Object.assign({}, this.state.data, {
        isUseStoreProcedure: checked,
      }),
    });
  }

  queryICProductAttributes = async () => {
    try {
      const response = await HttpAxios.get(
        GlobalConfig.REACT_APP_API_CONFIG_URL + "queryicproductattributes/all"
      );
      const resultObject = response.data;
      if (resultObject && resultObject.result === "OK" && resultObject.data) {
        this.setState({
          items: resultObject.data,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  doSomethingAfterGetItemById = () => {
    this.queryICProductAttributes();
  };

  getTitle = () => {
    return "Settings";
  };

  render() {
    const title = this.getTitle();
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={this.props.navRoute}></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>
                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    {this.state.urllist == "" || this.state.listtitle == "" ? (
                      ""
                    ) : (
                      <li>
                        <a href={this.state.urllist}>{this.state.listtitle}</a>
                      </li>
                    )}
                    {this.extendBreadcrumb()}
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>
                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <div className="row">
                <div className="col-md-12">
                  <form
                    onSubmit={this.handleSubmit}
                    id="form"
                    className="form-horizontal"
                  >
                    <section className="panel">
                      <header className="panel-heading">
                        <h2 className="panel-title">
                          {this.state.sectionTitle == undefined
                            ? "Basic Information"
                            : this.state.sectionTitle}
                        </h2>
                      </header>
                      {this.renderForm()}
                      <footer className="panel-footer">
                        <div className="row">
                          <div className="col-sm-9 col-sm-offset-3">
                            <button
                              type="submit"
                              className="btn btn-success"
                              style={{ marginLeft: "0px" }}
                            >
                              <i className="fa fa-save fa-lg"></i> Save
                            </button>
                            {this.state.urllist === null ||
                            this.state.urllist === undefined ||
                            this.state.urllist === "" ? (
                              ""
                            ) : (
                              <a
                                href={this.state.urllist}
                                title="Back to list"
                                className="btn btn-primary"
                              >
                                <i className="fa fa-backward fa-lg"></i> Back
                              </a>
                            )}
                            {this.extendButtons()}
                          </div>
                        </div>
                      </footer>
                    </section>
                  </form>
                  {this.state.alertshow === true ? (
                    <Alert
                      message={this.state.alertmsg}
                      type={this.state.alerttype}
                      show={this.state.alertshow}
                    ></Alert>
                  ) : (
                    ""
                  )}

                  {this.state.formSummaryTitle ? (
                    <section className="panel">
                      <header className="panel-heading">
                        <h2 className="panel-title">
                          {this.state.formSummaryTitle}
                        </h2>
                      </header>

                      {this.renderFormSummary()}
                    </section>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </section>
          </div>
        </section>
      </>
    );
  }

  renderForm = () => {
    return (
      <div className="panel-body">
        <div className="form-group">
          <label className="col-sm-2 control-label">Use Store Procedure</label>
          <div className="col-sm-4">
            <Switch
              name="isUseStoreProcedure"
              onChange={this.handleChangeSwitchIsUseStoreProcedure}
              checked={this.state.data.isUseStoreProcedure}
              className="react-switch"
            />
          </div>
        </div>
        {this.state.data.isUseStoreProcedure === true ? (
          ""
        ) : (
          <>
            <hr></hr>
            <div className="form-group">
              <label className="col-sm-2 control-label">
                Attribute reflect "Customer"
                <span className="required"> (*)</span>
              </label>
              <div className="col-sm-4">
                <select
                  name="value"
                  className="form-control"
                  required={this.state.data.isUseStoreProcedure !== true}
                  onChange={this.handleChangeSelect}
                  value={this.state.data.value}
                >
                  <option value="" hidden>
                    Choose one
                  </option>
                  {this.state.data
                    ? this.state.items.map((item, key) => {
                        return (
                          <option
                            value={item._id}
                            key={item._id}
                            data-extravalue={item.extraValue}
                          >
                            {item.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };
}
export default App;
