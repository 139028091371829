import React, { useState } from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";

import config from "./config"; //do not remove
import ClientList from "./Components/Clients/route";
import ClientAdd from "./Components/Clients/routeadd";
import ClientEdit from "./Components/Clients/routeedit";

import AdministratorList from "./Components/Administrator/route";
import AdministratorAdd from "./Components/Administrator/routeadd";
import AdministratorEdit from "./Components/Administrator/routeedit";

import ConfigList from "./Components/Config/index";
import ConfigAdd from "./Components/Config/add";
import ConfigEdit from "./Components/Config/edit";
import CustomerSetting from "./Components/Config/route";

import MailTemplateList from "./Components/MailTemplate/route";
import MailTemplateAdd from "./Components/MailTemplate/routeadd";
import MailTemplateEdit from "./Components/MailTemplate/routeedit";

import Page404 from "./Components/Pages/PageNotFound";

import Home from "./Components/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import { AuthContext } from "./context/auth";
import Login from "./Components/Pages/Login";
import TermOfUse from "./Components/Pages/TermsOfUse";
import RecoverPassword from "./Components/Pages/RecoverPassword";
import Profile from "./Components/User/Profile";

import ReportCurrentInventoryBalance from "./Components/Report/CurrentInventoryBalance";
import ReportInventoryRolloverByDate from "./Components/Report/InventoryRolloverByDate";
import ReportInventoryRolloverByMonth from "./Components/Report/InventoryRolloverByMonth";
import MeridianGrowersRtt from "./Components/Report/MeridianGrowersRtt";
import MeridianGroweSummary from "./Components/Report/MeridianGroweSummary";

import CronJobList from "./Components/CronJob/route";
import CronJobAdd from "./Components/CronJob/routeadd";
import CronJobEdit from "./Components/CronJob/routeedit";
import CronJobHistory from "./Components/CronJob/CronJobHistory/route";

import MaintenancePage from "./Components/Pages/Maintenance";

const GlobalConfig = new window.globalConfig();

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
function App(props) {
  const tokens = localStorage.getItem("tokens");
  let existingTokens = null;
  if (tokens) {
    existingTokens = tokens;
  }
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const setTokens = (data) => {
    if (data) localStorage.setItem("tokens", data);
    else localStorage.clear();
    setAuthTokens(data);
  };

  return (
    <Provider store={store}>
      <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
        <Router>
          <div>
            <Switch>
              {GlobalConfig.REACT_APP_IN_MAINTENANCE ? (
                <>
                  <Route path={"/"} component={MaintenancePage} />
                </>
              ) : (
                ""
              )}
              <Route
                path={process.env.REACT_APP_URL_LOGIN}
                exact
                component={Login}
              />
              <Route
                path={process.env.REACT_APP_URL_TERMS_OF_USE}
                exact
                component={TermOfUse}
              />
              <Route
                path={process.env.REACT_APP_URL_RECOVER_PASSWORD}
                exact
                component={RecoverPassword}
              />

              <PrivateRoute
                path="/"
                exact
                component={Home}
                navRoute={["dashboard"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_ADMINISTRATOR_LIST}
                exact
                component={AdministratorList}
                navRoute={["administrators"]}
              ></PrivateRoute>
              <PrivateRoute
                path={process.env.REACT_APP_URL_ADMINISTRATOR_LIST + "-add"}
                exact
                component={AdministratorAdd}
                navRoute={["administrators"]}
              ></PrivateRoute>
              <PrivateRoute
                path={process.env.REACT_APP_URL_ADMINISTRATOR_LIST + "/:id"}
                exact
                component={AdministratorEdit}
                navRoute={["administrators"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_CLIENT_LIST}
                exact
                component={ClientList}
                navRoute={["clients"]}
              ></PrivateRoute>
              <PrivateRoute
                path={process.env.REACT_APP_URL_CLIENT_LIST + "-add"}
                exact
                component={ClientAdd}
                navRoute={["clients"]}
              ></PrivateRoute>
              <PrivateRoute
                path={process.env.REACT_APP_URL_CLIENT_LIST + "/:id"}
                exact
                component={ClientEdit}
                navRoute={["clients"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_SYSTEMCONFIG_LIST}
                exact
                component={ConfigList}
                navRoute={["config"]}
              ></PrivateRoute>
              <PrivateRoute
                path={process.env.REACT_APP_URL_SYSTEMCONFIG_LIST + "-add"}
                exact
                component={ConfigAdd}
                navRoute={["config"]}
              ></PrivateRoute>
              <PrivateRoute
                path={process.env.REACT_APP_URL_SYSTEMCONFIG_LIST + "/:id"}
                exact
                component={ConfigEdit}
                navRoute={["config"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST + "/:id"}
                exact
                component={MailTemplateEdit}
                navRoute={["mail-template"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST}
                exact
                component={MailTemplateList}
                navRoute={["mail-template"]}
              ></PrivateRoute>
              <PrivateRoute
                path={process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST + "-add"}
                exact
                component={MailTemplateAdd}
                navRoute={["mail-template"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_MY_PROFILE}
                exact
                component={Profile}
                navRoute={["my-profile"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_REPORT_INVENTORY_BALANCE_URL}
                exact
                component={ReportCurrentInventoryBalance}
                navRoute={["report", "current-inventory-balance"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_REPORT_INVENTORY_ROLLOVER_URL}
                exact
                component={ReportInventoryRolloverByDate}
                navRoute={["report", "inventory-rollover"]}
              ></PrivateRoute>

              <PrivateRoute
                path={
                  process.env
                    .REACT_APP_URL_REPORT_INVENTORY_ROLLOVER_BY_MONTH_URL
                }
                exact
                component={ReportInventoryRolloverByMonth}
                navRoute={["report", "inventory-rollover-by-month"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_REPORT_MERIDIAN_GROWERS_RTT}
                exact
                component={MeridianGrowersRtt}
                navRoute={["report", "meridian-growers-rtt"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_REPORT_MERIDIAN_GROWER_SUMMARY}
                exact
                component={MeridianGroweSummary}
                navRoute={["report", "meridian-grower-summary"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_CRON_JOB_SETTING}
                exact
                component={CronJobList}
                navRoute={["cronjob-setting"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_CRON_JOB_SETTING + "/add"}
                exact
                component={CronJobAdd}
                navRoute={["cronjob-setting"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_CRON_JOB_SETTING + "/:id"}
                exact
                component={CronJobEdit}
                navRoute={["cronjob-setting"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_CRON_JOB_HISTORY + "/:id"}
                exact
                component={CronJobHistory}
                navRoute={["cronjob-history"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_CRON_JOB_HISTORY}
                exact
                component={CronJobHistory}
                navRoute={["cronjob-history"]}
              ></PrivateRoute>

              <PrivateRoute
                path={process.env.REACT_APP_URL_CUSTOMER_SETTINGS}
                exact
                component={CustomerSetting}
                navRoute={["settings"]}
              ></PrivateRoute>

              <Route component={Page404}></Route>
            </Switch>
          </div>
        </Router>
      </AuthContext.Provider>
    </Provider>
  );
}

export default App;
