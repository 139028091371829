import React from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import MultiSelectComponent from "./MultiSelectComponent";
import { Helmet } from "react-helmet";
import { HttpAxios } from "../../Utilities/Http";

const queryString = require("query-string");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labeladd: "Add",
      searchFormBasic: false,
      urlapi: "",
      urlapidelete: "",
      urllist: "",
      urladd: "",
      urlApiCronJobSetting: "",
      title: "",
      pluraltitle: "",
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      items: [],
      originalItems: [],
      filterParams: {},
      panelAction: true,
      loading: true,
      hidePanelAction: false,
      hidePanelClose: false,
      paginationDisabled: false,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.doSomthingAfterGetItems = this.doSomthingAfterGetItems.bind(this);
  }

  async componentDidMount() {
    await this.doSomthingBeforeGetItems();
    this.getItems();
  }

  getItems = async () => {
    const stateTimeout = setTimeout(() => {
      this.setState({ loading: true });
    }, 200);
    await HttpAxios.get(this.state.urlapi, {
      params: this.state.filterParams ?? {},
    }).then(({ data }) => {
      this.setState(
        {
          items: data.data,
          originalItems: data.data,
        },
        () => {
          this.doSomthingAfterGetItems(data);
        }
      );
      if (this.state.loading) {
        this.setState({ loading: false });
        clearTimeout(stateTimeout);
      } else {
        clearTimeout(stateTimeout);
      }
    });
  };

  multiSelectHandleChange(selected, element) {
    const key = element.name;
    const keyValue = `${element.name}Value`;
    let valueOptions = selected.value;

    if (selected && Array.isArray(selected)) {
      console.log(selected);
      const options = [...selected];
      const hasSelectAll = options.some((item) => {
        return (
          item.value === MultiSelectComponent.defaultProps.allOption.value &&
          item.label === MultiSelectComponent.defaultProps.allOption.label
        );
      });
      if (hasSelectAll) {
        options.shift();
      }
      valueOptions = options.map((item) => item.value).join();
    }

    this.setState((state) => {
      const data = { ...state.filterParams, [key]: valueOptions };
      return { filterParams: data, [keyValue]: selected };
    }, this.doSomthingAfterSelectChange);
  }

  handleDelete = (e) => {
    const removedId = e.currentTarget.dataset.id;
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete selected " + this.state.title + "?",
      buttons: [
        {
          className: "btn btn-warning",
          label: "Yes",
          onClick: async () => {
            window.showAlert("Info", "Processing...", "info");
            try {
              const urlapiDelete = this.state.urlapidelete
                ? this.state.urlapidelete
                : this.state.urlapi;
              const response = await HttpAxios.delete(
                urlapiDelete + "/" + removedId
              );
              const resultObject = response.data;
              if (resultObject && resultObject.result === "OK") {
                //If updated successful
                this.setState({
                  items: this.state.items.filter(function (item) {
                    return item._id !== removedId;
                  }),
                });
                this.setState({
                  originalItems: this.state.originalItems.filter(function (
                    item
                  ) {
                    return item._id !== removedId;
                  }),
                });
                window.showAlert("Success", "Removed successful!", "");
              } else {
                //If failed, show error
                window.showAlert("Error", resultObject.message, "error");
              }
            } catch (err) {
              window.showAlert("Error", err.message, "error");
            }
          },
        },
        {
          label: "No",
          className: "btn btn-default",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  getCronJobSetting = async (name, callback) => {
    await HttpAxios.get(
      `${this.state.urlApiCronJobSetting}tasknames/${name}`
    ).then(({ data }) => {
      callback(data.data);
    });
  };

  setColumns = () => {
    return [];
  };

  extendRender = () => {
    return "";
  };

  extendBreadcrumb = () => {
    return "";
  };

  extendButtons = () => {
    return "";
  };

  handleSearch = (event) => {
    console.log("Search value is " + event.target.value);
    const key = event.target.name;
    const val = event.target.value;
    this.setState(
      (state) => {
        const filter = { ...state.filterParams, [key]: val };
        return { filterParams: filter };
      },
      () => {
        this.getItems();
      }
    );
  };

  renderSearch = () => {
    return "";
    // <div style={{ display: 'flex', alignItems: 'center' }}>
    //   <input id="outlined-basic" label="Search" variant="outlined" size="small" style={{ margin: '5px' }} placeholder="Search" className="form-control" maxLength={100} onChange={this.handleSearch} />
    // </div>
  };

  renderSearchArea() { }

  RenderExpandableRowsComponent = (props) => { };

  rederTableStyle() { }

  doSomthingBeforeGetItems = async () => { };

  doSomthingAfterGetItems = (data) => { };

  doSomthingAfterSelectChange = () => { };

  noDataComponent = () => {
    //default text is "There are no records to display"
  };

  defaultTable = () => {
    const columns = this.setColumns();
    const customStyles = this.rederTableStyle();
    return (
      <DataTable
        id="emp"
        title=""
        noHeader={true}
        columns={columns}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={!this.state.paginationDisabled}
        paginationPerPage={20}
        expandableRows={this.state.tableExpandableRows}
        expandableRowsComponent={<this.RenderExpandableRowsComponent />}
        highlightOnHover={this.state.tableHighlightOnHover}
        customStyles={customStyles}
        progressPending={this.state.loading}
        noDataComponent={this.noDataComponent()}
      />
    );
  };

  defaultItemsLength = () => {
    if (this.state === undefined || this.state.items === undefined) {
      return 0;
    }
    return this.state.items.length;
  };

  render() {
    const itemsLength = this.defaultItemsLength();
    const title = this.state.pageTitle
      ? this.state.pageTitle
      : this.state.pluraltitle !== ""
        ? this.state.pluraltitle
        : this.state.title + "s";
    const lastUpdateLabel = this.state.lastUpdateLabel;
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={this.props.navRoute}></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>
                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    {this.extendBreadcrumb()}
                    <li>
                      <span>{title}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <section className="panel">
                <header className="panel-heading">
                  {this.state.hidePanelAction ? (
                    ""
                  ) : (
                    <div className="panel-actions">
                      <a href="#" className="fa fa-caret-down"></a>
                      {this.state.hidePanelClose ? (
                        ""
                      ) : (
                        <a href="#" className="fa fa-times"></a>
                      )}
                    </div>
                  )}
                  {lastUpdateLabel ? (
                    <div className="panel-actions">
                      <span>{lastUpdateLabel}</span>
                      {this.state.hidePanelAction ? (
                        ""
                      ) : (
                        <>
                          <a href="#" className="fa fa-caret-down"></a>
                          {this.state.hidePanelClose ? (
                            ""
                          ) : (
                            <a href="#" className="fa fa-times"></a>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.urlapi !== undefined && this.state.urlapi !== `` ?
                    <h2 className="panel-title">
                      {this.state === undefined ||
                        this.state.items === undefined ||
                        itemsLength <= 0
                        ? "Not found!"
                        : itemsLength === 1
                          ? itemsLength + " " + this.state.title + " found"
                          : itemsLength +
                          " " +
                          (this.state.pluraltitle === ""
                            ? this.state.title + "s"
                            : this.state.pluraltitle) +
                          " found"}
                    </h2> : <h2 className="panel-title">&nbsp;</h2>}
                </header>
                <div className="panel-body">
                  {this.state.searchFormBasic ? (
                    <div className="form-horizontal">
                      {this.renderSearchArea()}
                    </div>
                  ) : (
                    <div
                      className="row"
                      style={{ paddingBottom: "10px", paddingLeft: "5px" }}
                    >
                      <div className="col-sm-12">
                        <div className="row form-inline">
                          <div className="form-group">
                            {this.state.urladd === null ||
                              this.state.urladd === undefined ||
                              this.state.urladd === "" ? (
                              ""
                            ) : (
                              <a
                                href={this.state.urladd}
                                className="btn btn-primary"
                              >
                                <i className="fa fa-plus"></i>{" "}
                                {this.state.labeladd
                                  ? this.state.labeladd
                                  : "Add"}
                              </a>
                            )}
                            {this.extendButtons()}
                          </div>
                          {this.renderSearch()}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mt-sm"></div>
                  {this.defaultTable()}
                  {this.state.alertshow === true ? (
                    <Alert
                      message={this.state.alertmsg}
                      type={this.state.alerttype}
                      show={this.state.alertshow}
                    ></Alert>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </section>
          </div>
        </section>
        {this.extendRender()}
      </>
    );
  }
}
export default App;
