import React from "react";
import { HttpAxios } from "../../Utilities/Http"

var DateFormat = require("dateformat");
const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
    const currentYear = new Date().getFullYear();
    this.state = {
      currentDate: DateFormat(new Date(), "mm/dd/yyyy"),
      startDate: "08/01/" + currentYear,
      endDate: "07/31/" + (currentYear + 1),
      data: {
        totalClient: 0,
        totalLabUser: 0,
      },
    };
  }

  async componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const response = await HttpAxios.get(GlobalConfig.REACT_APP_API_DASHBOARD_URL + "summaryadmin");
    const result = response.data;
    this.setState({ data: result.data });
  };

  render() {
    return (
      <section className="panel">
        <header className="panel-heading">
          <div className="panel-actions">
            <a href="#" className="fa fa-caret-down"></a>
            {/* <a href="#" className="fa fa-times"></a> */}
          </div>
          <h2 className="panel-title">Summary</h2>
          <p className="panel-subtitle"></p>
        </header>
        <div className="panel-body">
          <div className="col-md-6 col-lg-3 col-xl-3">
            <div className="row">
              <div className="col-md-12">
                <section className="panel panel-featured-left panel-featured-tertiary">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-tertiary">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h4 className="title">Clients</h4>
                          <div className="info">
                            <strong className="amount">
                              {this.state.data.totalClient}
                            </strong>
                          </div>
                        </div>
                        {this.state.data.totalClient <= 0 ? (
                          ""
                        ) : (
                            <div className="summary-footer">
                              <a
                                className="text-muted text-uppercase"
                                href={process.env.REACT_APP_URL_CLIENT_LIST}
                              >
                                <span className="text-primary">View All</span>
                              </a>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  getInitialState() {
    return {
      labels: [
        "Aug 2020",
        "Sep  2020",
        "Oct  2020",
        "Nov  2020",
        "Dec  2020",
        "Jan 2021",
        "Fev 2021",
        "Mar 2021",
        "Apr 2021",
        "May 2021",
        "Jun 2021",
        "Jul 2021",
      ],
      datasets: [
        {
          label: "",
          backgroundColor: "rgb(0, 153, 198)",
          data: [
            500,
            200,
            300,
            400,
            500,
            600,
            700,
            800,
            900,
            1000,
            1100,
            125.55,
          ],
        },
      ],
    };
  }
}
export default App;